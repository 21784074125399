import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import BTGravar from '../components/BTGravar';
import BTNovo from '../components/BTNovo';
import { SelectButton } from 'primereact/selectbutton';
import { InputSwitch } from 'primereact/inputswitch';
import ConsultaGenerica from '../components/consultas/ConsultaGenerica';

const BHPeriodoCompetencia = () => {

    let emptyModel = {
        id: null,
        empresa: { id: 0, fantasia: '' },
        sindicato: null,
        dataInicialS: '',
        dataFinalS: '',
        maxExtrasS: "02:00",
        status: { tipo: 'ATIVO', id: 0 },
        valorHoraS: "01:00",
        valorHoraExtraS: "01:00",
        valorHoraNegativaS: "01:00",
        incluiFolgaFeriado: 0,
        proporcionalBH: 100,
        statusBanco: null,
        compensacaoBH: 1,
    };

    const tipoStatusValores = [
        { tipo: 'ATIVO', id: 0 },
        { tipo: 'INATIVO', id: 1 },
    ];

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [submitted, setSubmitted] = useState(false);
    const message = useRef();
    const [autoFilteredValueEmpresa, setAutoFilteredValueEmpresa] = useState([]);
    const [autoValueEmpresa, setAutoValueEmpresa] = useState(null);
    const [autoFilteredValueSindicatos, setAutoFilteredValueSindicatos] = useState([]);
    const [autoValueSindicatos, setAutoValueSindicatos] = useState(null);
    const [lista, setLista] = useState(null);

    const colunas = [
        { field: 'empresa.fantasia', header: 'Empresa', headerStyle: { minWidth: '15rem' } },
        { field: 'sindicato.nome', header: 'Sindicato', headerStyle: { minWidth: '15rem' } },
        { field: 'dataInicialS', header: 'Data Inicial', headerStyle: { minWidth: '5rem' } },
        { field: 'dataFinalS', header: 'Data Final', headerStyle: { minWidth: '5rem' } },
    ];

    useEffect(() => {
        const buscarEmpresas = () => {
            api
                .get("/empresas")
                .then(response => {
                    setAutoValueEmpresa(response.data);
                    model.empresa = response.data[0];
                    buscarSindicatos(response.data[0]);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
        buscarEmpresas();
    }, []);


    const onInputChange = (e, descricao) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
    }

    const onRadioChange = (e, descricao) => {
        const val = e.value || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
    }

    const selectChange = (e, descricao) => {
        const val = (e.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
    }

    const onSwitchChange = (e, descricao) => {
        const val = e.value || '';
        let valor = 0;
        if (val) {
            valor = 1;
        }
        let _model = { ...model };
        _model[`${descricao}`] = valor;
        setModel(_model);
    }

    const buscarSindicatos = (empresa) => {
        if (empresa !== null) {
            api
                .get("/bancoHoras/sindicatos?empresa=" + empresa.id)
                .then(response => {
                    model.sindicato = response.data[0];
                    setAutoValueSindicatos(response.data);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
    };

    const procurarEmpresas = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueEmpresa([...autoValueEmpresa]);
                }
                else {
                    setAutoFilteredValueEmpresa(autoValueEmpresa.filter((em) => {
                        return em.fantasia.toLowerCase().startsWith(event.query.toLowerCase());

                    }));
                }
            } catch (e) {
            }

        }, 250);
    };

    const procurarSindicatos = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueSindicatos([...autoValueSindicatos]);
                }
                else {
                    setAutoFilteredValueSindicatos(autoValueSindicatos.filter((em) => {
                        return em.nome.toLowerCase().startsWith(event.query.toLowerCase());
                    }));
                }
            } catch (e) {
            }

        }, 250);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <BTGravar setSubmitted={setSubmitted} cadastrar={cadastrar} />
                    <BTNovo setSubmitted={setSubmitted} setModel={setModel} emptyModel={emptyModel} />
                    {/* <BTInativar inativar={inativar} /> */}

                    <ConsultaGenerica
                        lista={lista}
                        model={model}
                        setModel={setModel}
                        onConsultar={consultar}
                        toast={toast}
                        colunas={colunas}
                        titulo="Consultar"
                        mensagemSelecao="Período Competência Selecionado"
                        camposFiltroGlobal={['nome', 'empresa.fantasia', 'cnpj']}
                        onModelSelecionado={onModelSelecionado}
                        life={3000}
                        largura='700px'
                    />

                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const onModelSelecionado = (model) => {
        return `${model.empresa.fantasia}`;
    };

    const cadastrar = () => {
        if (model.dataInicialS.trim() && model.dataFinalS.trim() &&
            model.maxExtrasS.trim() && model.valorHoraS.trim() && model.valorHoraNegativaS.trim()
            && model.proporcionalBH) {
            if (model.id) {
                console.log("entrou aqui")
                api
                    .put("/bancoHoras/periodoCompetencia/" + model.id, {
                        empresa: model.empresa,
                        sindicato: model.sindicato,
                        status: model.status,
                        dataInicialS: model.dataInicialS,
                        dataFinalS: model.dataFinalS,
                        maxExtrasS: model.maxExtrasS,
                        valorHoraS: model.valorHoraS,
                        valorHoraExtraS: model.valorHoraExtraS,
                        valorHoraNegativaS: model.valorHoraNegativaS,
                        compensacaoBH: model.compensacaoBH,
                        proporcionalBH: model.proporcionalBH,
                        incluiFolgaFeriado: model.incluiFolgaFeriado,
                    })
                    .then(response => {
                        console.log(response.data)
                        setModel(response.data);
                        addMessage("success", Mensagens.CADASTRO_ALTERAR);
                    })
                    .catch(() => {
                        addMessage("error", Mensagens.ERRO);
                    })
            }
            else {
                console.log(model)
                api
                    .post("/bancoHoras/periodoCompetencia", {
                        empresa: model.empresa,
                        sindicato: model.sindicato,
                        status: model.status,
                        dataInicialS: model.dataInicialS,
                        dataFinalS: model.dataFinalS,
                        maxExtrasS: model.maxExtrasS,
                        valorHoraS: model.valorHoraS,
                        valorHoraExtraS: model.valorHoraExtraS,
                        valorHoraNegativaS: model.valorHoraNegativaS,
                        compensacaoBH: model.compensacaoBH,
                        proporcionalBH: model.proporcionalBH,
                        incluiFolgaFeriado: model.incluiFolgaFeriado,
                    })
                    .then(response => {
                        setModel(response.data);
                        addMessage("success", Mensagens.CADASTRO_INCLUIR);
                    })
                    .catch(() => {
                        addMessage("error", Mensagens.ERRO);
                    })
            }
        }
    }

    // const inativar = () => {
    //     if (model.id) {
    //         api
    //             .delete("/empresas/" + model.id)
    //             .then(response => {
    //                 addMessage("success", Mensagens.CADASTRO_INATIVAR);
    //                 setSubmitted(false);
    //                 setModel(emptyModel);
    //             })
    //             .catch(() => {
    //                 addMessage("error", Mensagens.ERRO);
    //             })
    //     } else {
    //         addMessage("warn", Mensagens.SELECIONE);
    //     }

    // }

    const consultar = () => {
        api
            .get("/bancoHoras/periodoCompetencia")
            .then(response => setLista(response.data))
            .catch(() => {
                addMessage("error", Mensagens.ERRO);
            })
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="status" className="col-12 md:col-1 md:mt-2">Status*</label
                        >
                        <div className="field col-12 md:col-2 mb-2">
                            <SelectButton value={model.status} onChange={(e) => selectChange(e, 'status')} options={tipoStatusValores} optionLabel="tipo" />
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="empresa" className="col-12 md:col-1 md:mb-0">Empresa*</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="empresa" dropdown value={model.empresa} onChange={(e) => onInputChange(e, 'empresa')} suggestions={autoFilteredValueEmpresa} completeMethod={procurarEmpresas} field="fantasia" required className={classNames({ 'p-invalid': submitted && !model.empresa })} />
                            {submitted && !model.empresa && <small className="p-invalid">Empresa Obrigatória</small>}
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="sindicato" className="col-12 md:col-1 md:mb-0">Sindicato</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="sindicato" dropdown value={model.sindicato} onChange={(e) => onInputChange(e, 'sindicato')} suggestions={autoFilteredValueSindicatos} completeMethod={procurarSindicatos} field="nome" />
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="datainicial" className="col-12 md:col-1 md:mt-2">Data Inicial*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputMask id='datainicial' mask='99/99/9999' value={model.dataInicialS} onChange={e => onInputChange(e, 'dataInicialS')} required className={classNames({ 'p-invalid': submitted && !model.dataInicialS })} />
                            {submitted && !model.dataInicialS && <small className="p-invalid">Data Obrigatória</small>}
                        </div>
                        <label htmlFor="dataFinal" className="col-12 md:col-1 md:mt-2">Data Final*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputMask id='dataFinal' mask='99/99/9999' value={model.dataFinalS} onChange={e => onInputChange(e, 'dataFinalS')} required className={classNames({ 'p-invalid': submitted && !model.dataFinalS })} />
                            {submitted && !model.dataFinalS && <small className="p-invalid">Data Obrigatória</small>}
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="maxExtras" className="col-12 md:col-1 md:mt-0">Max. H. Extras dia*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputMask id="maxExtras" mask="99:99" value={model.maxExtrasS} onChange={(e) => onInputChange(e, 'maxExtrasS')} required className={classNames({ 'p-invalid': submitted && !model.maxExtrasS })} />
                            {submitted && !model.maxExtrasS && <small className="p-invalid">Max. H Extras dia Obrigatória</small>}
                        </div>

                        <label htmlFor="valorHPositiva" className="col-12 md:col-1 md:mt-0">Valor H. Positiva*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputMask id="valorHPositiva" mask="99:99" value={model.valorHoraS} onChange={(e) => onInputChange(e, 'valorHoraS')} required className={classNames({ 'p-invalid': submitted && !model.valorHoraS })} />
                            {submitted && !model.valorHoraS && <small className="p-invalid">Valor H. Positiva Obrigatória</small>}
                        </div>

                        <label htmlFor="valorHNegativa" className="col-12 md:col-1 md:mt-0">Valor H. Negativa*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputMask id="valorHNegativa" mask="99:99" value={model.valorHoraNegativaS} onChange={(e) => onInputChange(e, 'valorHoraNegativaS')} required className={classNames({ 'p-invalid': submitted && !model.valorHoraNegativaS })} />
                            {submitted && !model.valorHoraNegativaS && <small className="p-invalid">Valor H. NEgativa Obrigatória</small>}
                        </div>
                    </div>

                    <div className="field grid p-fluid">
                        <label htmlFor="compensacao" className="col-12 mb-2 md:col-2 md:mb-0">Compensação?</label>
                        <div className="col-12 md:col-10">
                            <InputSwitch checked={model.compensacaoBH === 1} onChange={(e) => onSwitchChange(e, 'compensacaoBH')} />
                        </div>
                    </div>

                    <div className="field grid p-fluid">
                        <label htmlFor="folgas" className="col-12 mb-2 md:col-2 md:mb-0">Incluir Folgas e Feriado Para o Cálculo?</label>
                        <div className="col-12 md:col-10">
                            <InputSwitch checked={model.incluiFolgaFeriado === 1} onChange={(e) => onSwitchChange(e, 'incluiFolgaFeriado')} />
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="proporcionalBH" className="col-12 md:col-1 md:mt-0">Proporcional BH(%)*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputNumber id="proporcionalBH" inputId="minmax-buttons" value={model.proporcionalBH} mode="decimal" showButtons min={0} max={100} onChange={(e) => onRadioChange(e, 'proporcionalBH')} />
                        </div>
                    </div>


                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(BHPeriodoCompetencia, comparisonFn);
