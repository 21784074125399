import React, { useState, useEffect, useRef } from 'react';
import { Redirect, Route } from 'react-router-dom';
import apiAutenticacao from '../service/apiAutenticacao';
import axios from 'axios';

export function PrivateRoute({ component: Component, ...rest }) {

    // const [isAuthenticated, setIsAutenticated] = useState(false);
    const [userData, setUserData] = useState(null);
    const isMounted = useRef(true);
    const signalRef = useRef(null);

    useEffect(() => {
        signalRef.current = axios.CancelToken.source();
        async function fetchData() {
            try {
                const usuario = JSON.parse(localStorage.getItem("usuario"));
                if (!usuario) {
                    return;
                }
                const token = usuario.token;
                const config = { headers: { 'Authorization': `Bearer ${token}` }, CancelToken: signalRef.current.token }
                const { data } = await
                    apiAutenticacao.get("/checar-token", config);
                if (isMounted.current) {
                    setUserData(data);
                }

            } catch (error) {
                setUserData(false);
                if (axios.isCancel(error)) {
                    console.log("Request canceled")
                } else {
                    throw error;
                }
            }
        }
        fetchData();
        return () => {
            setUserData(false);
            signalRef.current.cancel("cancelado após desmontar");
            isMounted.current = false;
        };
    }, []);

    if (!userData) {
        // return <div><i className="pi pi-spin pi-spinner" style={{'fontSize': '2em'}}></i></div>
        return <div></div>
    }

    return (
        <Route
            {...rest}
            render={(props) => userData ? (
                <Component {...props} />
            ) : (<Redirect to={{ pathname: "/login", state: { from: props.location } }} />)
            }
        />
    );
}
