import React, { useState } from 'react';
import { Button } from 'primereact/button';
import Confirmacao from './Confirmacao';
import Botoes from '../globais/Botoes';


export default function BTInativar(props) {

    const [displayConfirmation, setDisplayConfirmation] = useState(false);

    const inativarConfirmado = () => {
        props.inativar();
        setDisplayConfirmation(false);
    }

    return (
        <>
            <Button label={Botoes.INATIVAR} icon="pi pi-trash" onClick={() => setDisplayConfirmation(true)} className="p-button-danger mr-2 mt-1 mb-1" />
            <Confirmacao displayConfirmation = {displayConfirmation} setDisplayConfirmation = {setDisplayConfirmation} inativarConfirmado = {inativarConfirmado}/>
        </>
    )

}
