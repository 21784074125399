import React from 'react';
import { Button } from 'primereact/button';
import Botoes from '../globais/Botoes';


export default function BTLimparBatidas(props) {

    const listar = () => {
        props.setSubmitted(true);
        props.listar();
    }

    return (
        <>
            <Button label={Botoes.LISTARBATIDAS} icon="pi pi-check" onClick={listar} className="p-button-success mr-2" />
        </>
    )

}