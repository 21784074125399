import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import BTInativar from '../components/BTInativar';
import BTNovo from '../components/BTNovo';
import BTGravar from '../components/BTGravar';
import { classNames } from 'primereact/utils';
import { InputMask } from 'primereact/inputmask';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { AutoComplete } from 'primereact/autocomplete';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import ConsultaFixaGenerica from '../components/consultas/ConsultaFixaGenerica';

const Justificativas = () => {

    let emptyModel = {
        id: null,
        dataInicialS: '',
        dataFinalS: '',
        tempoS: '',
        periodo: 1,
        periodoS: '',
        descricao: '',
        empresa: null,
        colaborador: null,
        evento: null,
    };

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [lista, setLista] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [autoFilteredValueEmpresa, setAutoFilteredValueEmpresa] = useState([]);
    const [autoValueEmpresa, setAutoValueEmpresa] = useState(null);
    const [autoFilteredValueColaborador, setAutoFilteredValueColaborador] = useState([]);
    const [autoValueColaborador, setAutoValueColaborador] = useState(null);
    const [autoFilteredValueEvento, setAutoFilteredValueEvento] = useState([]);
    const [autoValueEvento, setAutoValueEvento] = useState(null);
    const [pVisivel, setPVisivel] = useState(true);
    const [tVisivel, setTVisivel] = useState(false);
    const [mpVisivel, setMpVisivel] = useState(false);

    const colunas = [
        { field: 'tipoS', header: 'Tipo', headerStyle: { minWidth: '15rem' } },
        { field: 'evento.descricao', header: 'Evento', headerStyle: { maxWidth: '5rem' } },
        { field: 'descricao', header: 'Descrição', headerStyle: { maxWidth: '5rem' } },
        { field: 'dataInicialS', header: 'Data Inicial', headerStyle: { maxWidth: '5rem' } },
        { field: 'dataFinalS', header: 'Data Final', headerStyle: { maxWidth: '5rem' } },
        { field: 'tempoS', header: 'Tempo', headerStyle: { maxWidth: '5rem' } },
        { field: 'periodoS', header: 'Período', headerStyle: { maxWidth: '5rem' } },
    ];

    const message = useRef();

    useEffect(() => {

        const buscaEmpresas = () => {
            api
                .get("/empresas")
                .then(response => {
                    setAutoValueEmpresa(response.data);
                    model.empresa = response.data[0];
                    buscaColaboradores(response.data[0]);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaEventos = () => {
            api
                .get("/eventos")
                .then(response => setAutoValueEvento(response.data))
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        buscaEmpresas();
        buscaEventos();

    }, []);

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <BTGravar setSubmitted={setSubmitted} cadastrar={cadastrar} />
                    <BTNovo setSubmitted={setSubmitted} setModel={setModel} emptyModel={emptyModel} />
                    <BTInativar inativar={inativar} />
                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    const procurarEmpresas = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValueEmpresa([...autoValueEmpresa]);
            }
            else {
                setAutoFilteredValueEmpresa(autoValueEmpresa.filter((em) => {
                    return em.fantasia.toLowerCase().startsWith(event.query.toLowerCase());
                }));
            }
        }, 250);
    };

    const procurarColaboradores = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueColaborador([...autoValueColaborador]);
                }
                else {
                    setAutoFilteredValueColaborador(autoValueColaborador.filter((em) => {
                        return em.nome.toLowerCase().startsWith(event.query.toLowerCase());
                    }));
                }
            } catch (e) {
            }
        }, 250);
    };

    const procurarEventos = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueEvento([...autoValueEvento]);
                }
                else {
                    setAutoFilteredValueEvento(autoValueEvento.filter((em) => {
                        return em.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                    }));
                }
            } catch (e) {
            }
        }, 250);
    };

    const buscaColaboradores = (empresa) => {
        try {
            api
                .get("colaboradores/?empresa=" + empresa.id)
                .then(response => {
                    setAutoValueColaborador(response.data);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        } catch (e) {
        }
    }

    const buscaJustificativas = (colaborador, empresa) => {
        if(colaborador.id){
        api
            .get("/justificativas?colaborador=" + colaborador.id + "&empresa=" + empresa.id)
            .then(response => {
                setLista(response.data)
            })
            .catch(() => {
                addMessage("error", Mensagens.ERRO);
            })
        }
    }

    const verificarFechamento = async (idEmpresa, idColaborador, data) =>{
        try {
            const response = await api
                .get("/verificarFechamento?empresa=" + idEmpresa + "&colaborador=" + idColaborador + "&data=" + data);
                return response.data;
        } catch {
            return false;
        }
    }

    const selecao = (e) => {
        setModel(e.value);
    }

    const onRadioChange = (e, descricao) => {
        const val = e.value || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
    }

    const onInputChange = (e, descricao) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        if (descricao === 'empresa') {
            _model.colaborador = null;
            buscaColaboradores(_model.empresa);
        }
        if (descricao === 'colaborador') {
            buscaJustificativas(_model.colaborador, _model.empresa);
        }

        if (descricao === 'evento') {
            setPVisivel(false);
            setMpVisivel(false);
            setTVisivel(false);
            if (_model.evento.tipo === 'P') {
                setPVisivel(true);
            }
            if (_model.evento.tipo === 'MP') {
                setMpVisivel(true);
            }
            if (_model.evento.tipo === 'M') {
                setTVisivel(true);
            }
        }
        setModel(_model);

    }

    const cadastrar = async () => {
            if (model.empresa && model.colaborador && model.evento
                && model.dataInicialS.trim() && model.dataFinalS.trim()) {
                    const isFechamentoVerificado = await verificarFechamento(model.empresa.id, model.colaborador.id, model.dataInicialS.trim());
                    if(isFechamentoVerificado){
                api
                    .post("/justificativas", {
                        id: model.id,
                        empresa: model.empresa,
                        evento: model.evento,
                        colaborador: model.colaborador,
                        dataInicialS: model.dataInicialS,
                        dataFinalS: model.dataFinalS,
                        tempoS: model.tempoS,
                        periodoS: model.periodoS,
                        periodo: model.periodo,
                        descricao: model.descricao,
                    })
                    .then(response => {
                        setLista(response.data);
                        addMessage("success", Mensagens.CADASTRO_INCLUIR);
                    })
                    .catch((error) => {
                        if (error.response.status === 406) {
                            addMessage("error", Mensagens.JUSTIFICATIVA_DUPLICADA);
                        } else {
                            addMessage("error", Mensagens.ERRO);
                        }
                    })
                }else{
                }
            }


    }

    const inativar = async () => {
        if (model.id) {
            const isFechamentoVerificado = await verificarFechamento(model.empresa.id, model.colaborador.id, model.dataInicialS);
            if(isFechamentoVerificado){
            api
                .delete("/justificativas/" + model.id)
                .then(response => {
                    addMessage("success", Mensagens.CADASTRO_INATIVAR);
                    setLista(response.data);
                    setSubmitted(false);
                    setModel(emptyModel);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
            }else{
                addMessage("error", Mensagens.MENSAGEM_PONTOFECHADOEMBANCODEHORAS);
            }
        } else {
            addMessage("warn", Mensagens.SELECIONE);
        }

    }

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="empresa" className="col-12 md:col-1 md:mb-0">Empresa*</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="empresa" dropdown value={model.empresa} onChange={(e) => onInputChange(e, 'empresa')} suggestions={autoFilteredValueEmpresa} completeMethod={procurarEmpresas} field="fantasia" required className={classNames({ 'p-invalid': submitted && !model.empresa })} />
                            {submitted && !model.empresa && <small className="p-invalid">Empresa Obrigatória</small>}
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="colaborador" className="col-12 md:col-1 md:mb-0">Colaborador*</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="colaborador" dropdown value={model.colaborador} onChange={(e) => onInputChange(e, 'colaborador')} suggestions={autoFilteredValueColaborador} completeMethod={procurarColaboradores} field="nome" required className={classNames({ 'p-invalid': submitted && !model.colaborador })} />
                            {submitted && !model.colaborador && <small className="p-invalid">colaborador Obrigatório</small>}
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="evento" className="col-12 md:col-1 md:mb-0">Evento*</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="evento" dropdown value={model.evento} onChange={(e) => onInputChange(e, 'evento')} suggestions={autoFilteredValueEvento} completeMethod={procurarEventos} field="descricao" required className={classNames({ 'p-invalid': submitted && !model.evento })} />
                            {submitted && !model.evento && <small className="p-invalid">Evento Obrigatório</small>}
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="descricao" className="col-12 mb-2 md:col-1 md:mb-0">Descrição</label>
                        <div className="col-12 md:col-11">
                            <InputText id="descricao" maxLength={200} placeholder="Máximo 200 caracteres" value={model.descricao} onChange={(e) => onInputChange(e, 'descricao')} />
                        </div>
                    </div>


                    <div className="p-fluid formgrid grid" style={{ display: pVisivel || mpVisivel ? ' ' : 'none' }}>
                        <label htmlFor="datainicial" className="col-12 md:col-1 md:mt-2">Data Inicial*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputMask id='datainicial' mask='99/99/9999' value={model.dataInicialS} onChange={e => onInputChange(e, 'dataInicialS')} required className={classNames({ 'p-invalid': submitted && !model.dataInicialS })} />
                            {submitted && !model.dataInicialS && <small className="p-invalid">Data Inicial Obrigatória</small>}
                        </div>

                        <label htmlFor="dataFinal" className="col-12 md:col-1 md:mt-2">Data Final*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputMask id='dataFinal' mask='99/99/9999' value={model.dataFinalS} onChange={e => onInputChange(e, 'dataFinalS')} required className={classNames({ 'p-invalid': submitted && !model.dataFinalS })} />
                            {submitted && !model.dataFinalS && <small className="p-invalid">Data Final Obrigatória</small>}
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid" style={{ display: tVisivel ? ' ' : 'none' }}>
                        <label htmlFor="datainicial" className="col-12 md:col-1 md:mt-2">Data*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputMask id='datainicial' mask='99/99/9999' value={model.dataInicialS} onChange={e => onInputChange(e, 'dataInicialS')} required className={classNames({ 'p-invalid': submitted && !model.dataInicialS })} />
                            {submitted && !model.dataInicialS && <small className="p-invalid">Data Obrigatória</small>}
                        </div>

                        <label htmlFor="tempo" className="col-12 md:col-1 md:mt-2">Tempo*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputMask id='tempo' mask='99:99' value={model.tempoS} onChange={e => onInputChange(e, 'tempoS')} required className={classNames({ 'p-invalid': submitted && !model.tempoS })} />
                            {submitted && !model.tempoS && <small className="p-invalid">Tempo Obrigatório</small>}
                        </div>
                    </div>

                    <div className="grid mt-2" style={{ display: mpVisivel ? ' ' : 'none' }}>
                        <div className="col-6 md:col-2">
                            <div className="field-radiobutton">
                                <RadioButton inputId="option1" name="option" value={1} checked={model.periodo === 1} onChange={(e) => onRadioChange(e, 'periodo')} />
                                <label htmlFor="option1">1° Período</label>
                            </div>
                        </div>
                        <div className="col-6 md:col-2">
                            <div className="field-radiobutton">
                                <RadioButton inputId="option2" name="option" value={2} checked={model.periodo === 2} onChange={(e) => onRadioChange(e, 'periodo')} />
                                <label htmlFor="option2">2° Período</label>
                            </div>
                        </div>
                        <div className="col-6 md:col-2">
                            <div className="field-radiobutton">
                                <RadioButton inputId="option3" name="option" value={3} checked={model.periodo === 3} onChange={(e) => onRadioChange(e, 'periodo')} />
                                <label htmlFor="option3">3° Período</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                    <ConsultaFixaGenerica
                        lista={lista}
                        model={model}
                        selecao={selecao}
                        camposFiltroGlobal={['tipoS','descricao', 'evento.descricao', 'dataInicialS', 'dataFinalS', 'tempoS', 'periodoS']}
                        colunas={colunas}
                    />

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Justificativas, comparisonFn);
