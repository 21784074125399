import React, { useState, useRef, useCallback } from 'react';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';

    const ConsultaGenerica = (props) => {

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(true);
    const op = useRef(null);

    const initFilters = () => {
        setFilters({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        });
    };

    const clearFilter = () => {
        initFilters();
        setGlobalFilterValue("");
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Limpar" className="p-button-outlined" onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Procurar" />
                </span>
            </div>
        );
    };

    const toggleDataTable = useCallback((event) => {
        props.onConsultar();
        setLoading(false);
        initFilters();
        op.current.toggle(event);
    }, [props]);

    const onItemSelect = (event) => {
        if(props.setCampoDesativado){
            props.setCampoDesativado(true);
        }
        const mensagem = props.onModelSelecionado(event.data);
        op.current.hide();
        props.toast.current.show({ severity: 'info', summary: props.mensagemSelecao, detail: mensagem, life: props.life });
    };

    return (
        <>
            <Button type="button" label={props.titulo} onClick={toggleDataTable} icon="pi pi-search" className="p-button-info mr-2 mt-1 mb-1" />
            <OverlayPanel ref={op} appendTo={document.body} showCloseIcon id="overlay_panel" style={{ width: props.largura }}>
                <DataTable size="small" value={props.lista} selection={props.model} onSelectionChange={(e) => props.setModel(e.value)} selectionMode="single" onRowSelect={onItemSelect}
                    paginator className="p-datatable-gridlines" showGridlines rows={10}
                    dataKey="id" filters={filters} filterDisplay="menu" loading={loading} responsiveLayout="scroll"
                    globalFilterFields={props.camposFiltroGlobal} header={renderHeader} emptyMessage="Nada encontrado.">
                    {props.colunas.map((col, index) => (
                        <Column key={index} field={col.field} header={col.header} sortable headerStyle={col.headerStyle} />
                    ))}
                </DataTable>
            </OverlayPanel>
        </>
    );
};

export default ConsultaGenerica;
