export default class Botoes {
    static GRAVAR = "Gravar";
    static NOVO = "Novo";
    static INATIVAR = "Inativar";
    static CONSULTAR = "Consultar";
    static ATIVAR = "Ativar";
    static LISTARBATIDAS = "Listar Batidas";
    static VISUALIZAR = "Visualizar";
    static PERMITIR = "Permitir";
    static AUTORIZAR_FECHAMENTO = "Autorizar Fechamento";
    static REABRIR_ULTIMO = "Reabrir Último Fechado";
    static autenticadao = false;
}
