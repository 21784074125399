import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import Botoes from '../globais/Botoes';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';
import { InputMask } from 'primereact/inputmask';

const ArquivoMteAfdt = () => {

    let emptyModel = {
        empresa: { id: 0, fantasia: '' },
        dataInicialS: '',
        dataFinalS: '',
    };


    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [submitted, setSubmitted] = useState(false);
    const message = useRef();
    const [value, setValue] = useState(0);
    const interval = useRef(null);
    const [autoFilteredValueEmpresa, setAutoFilteredValueEmpresa] = useState([]);
    const [autoValueEmpresa, setAutoValueEmpresa] = useState(null);
    const [dataAntiga, setDataAntiga] = useState('');


    useEffect(() => {
        const buscaEmpresas = () => {
            api
                .get("/empresas")
                .then(response => {
                    setAutoValueEmpresa(response.data)
                    let _model = { ...model };
                    _model[`${"empresa"}`] = response.data[0];
                    setModel(_model);
                    buscarDataAntiga(_model.empresa);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
        buscaEmpresas();
    }, []);

    useEffect(() => {
        if (submitted) {
            let val = value;
            interval.current = setInterval(() => {
                val += Math.floor(Math.random() * 10) + 1;

                if (val >= 100) {
                    val = 97;
                    clearInterval(interval.current);
                }
                setValue(val);
            }, 2000);

            return () => {
                if (interval.current) {
                    clearInterval(interval.current);
                    interval.current = null;
                }
            }
        }
    }, [value]);



    const onInputChange = (e, descricao) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
        if (descricao === "empresa") {
            buscarDataAntiga(_model.empresa);
        }
    }

    const procurarEmpresas = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueEmpresa([...autoValueEmpresa]);
                }
                else {
                    setAutoFilteredValueEmpresa(autoValueEmpresa.filter((em) => {
                        return em.fantasia.toLowerCase().startsWith(event.query.toLowerCase());

                    }));
                }
            } catch (e) {
            }

        }, 250);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={Botoes.VISUALIZAR} icon="pi pi-check" onClick={visualizar} className="p-button-success mr-2" />
                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const buscarDataAntiga = (empresa) => {
        api
            .get("/areamte/arquivos/afdt?empresa=" + empresa.id)
            .then(response => {
                setDataAntiga(response.data);
            })
            .catch(() => {
                addMessage("error", Mensagens.ERRO);
            })
    }



    const visualizar = () => {
        setSubmitted(true);
        if (model.empresa && model.dataInicialS.trim() && model.dataFinalS.trim()) {
            setValue(1);
            api
                .post("/areamte/arquivos/afdt", {
                    empresa: model.empresa,
                    dataInicialS: model.dataInicialS,
                    dataFinalS: model.dataFinalS,
                }, { responseType: 'arraybuffer' })
                .then(response => {

                    const file = new Blob(
                        [response.data],
                        { type: 'text/plain' });

                    const fileURL = URL.createObjectURL(file);

                    const a = document.createElement('a');
                    a.setAttribute('download', "AFDT.txt")
                    a.setAttribute('href', fileURL);
                    a.click();

                    URL.revokeObjectURL(fileURL);
                    // window.open(fileURL);
                    setSubmitted(false);
                    setValue(100);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                    setSubmitted(false);
                    setValue(0);
                })
        }
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="empresa" className="col-12 md:col-1 md:mb-0">Empresa*</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="empresa" dropdown value={model.empresa} onChange={(e) => onInputChange(e, 'empresa')} suggestions={autoFilteredValueEmpresa} completeMethod={procurarEmpresas} field="fantasia" required className={classNames({ 'p-invalid': submitted && !model.empresa })} />
                            {submitted && !model.empresa && <small className="p-invalid">Empresa Obrigatória</small>}
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="datainicial" className="col-12 md:col-1 md:mt-2">Data Inicial*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputMask id='datainicial' mask='99/99/9999' value={model.dataInicialS} onChange={e => onInputChange(e, 'dataInicialS')} required className={classNames({ 'p-invalid': submitted && !model.dataInicialS })} />
                            {submitted && !model.dataInicialS && <small className="p-invalid">Data Inicial Obrigatória</small>}
                        </div>

                        <label htmlFor="dataFinal" className="col-12 md:col-1 md:mt-2">Data Final*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputMask id='dataFinal' mask='99/99/9999' value={model.dataFinalS} onChange={e => onInputChange(e, 'dataFinalS')} required className={classNames({ 'p-invalid': submitted && !model.dataFinalS })} />
                            {submitted && !model.dataFinalS && <small className="p-invalid">Data Final Obrigatória</small>}
                        </div>

                        <label htmlFor="feriados" className="col-12 md:col-1 md:mt-2">Data Antiga:</label>
                        <div className="field col-12 md:col-2 mt-2 mb-2">
                            <label htmlFor="feriados">{dataAntiga}</label>
                        </div>
                    </div>

                    <div className="col-12 ">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>


                    <div className="col-12">
                        <div className="col">
                            <ProgressBar value={value} />
                        </div>
                    </div>

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ArquivoMteAfdt, comparisonFn);
