import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import BTGravar from '../components/BTGravar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import '../assets/datatables/datatable.css';

const BatidaViaLinkManutencao = () => {

    let emptyModel = {
        id: null,
    };

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [lista, setLista] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const message = useRef();
    const [globalFilterValue, setGlobalFIlterValue] = useState('');
    const [filters, setFilters] = useState(null);
    const [loading, setloading] = useState(true);
    const op = useRef(null);

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <BTGravar setSubmitted={setSubmitted} cadastrar={cadastrar} />
                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    useEffect(() => {
        initFilters();
        const buscarBatidasEnviadas = () => {
            api
                .get("/batidaEnviada")
                .then(response => {
                    setLista(response.data);
                    setloading(false);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
        buscarBatidasEnviadas();
    }, []);

    const cadastrar = () => {
        api
            .put("/batidaEnviada", lista)
            .then(response => {
                setLista(response.data);
                addMessage("success", Mensagens.OPERACAO_SUCESSO);
            })
            .catch(() => {
                addMessage("error", Mensagens.ERRO);
            })
    }

    const consultar = () => {
        api
            .get("/batidaEnviada")
            .then(response => {
                setLista(response.data);
                setloading(false);
            })
            .catch(() => {
                addMessage("error", Mensagens.ERRO);
            })
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    const renderHeader = () => {
        return (
                <div className="flex justify-content-between">
                    <span className="p-input-icon-right">
                        <Button icon="pi pi-refresh" className="p-button-info ml-2" onClick={consultar} />
                        <Button icon="pi pi-check-square" className="p-button-success ml-2" onClick={marcarTodos} />
                        <Button icon="pi pi-ban" className="p-button-danger ml-2" onClick={desmarcarTodos} />
                    </span>

                    <span className="p-input-icon-left">
                        <Button type="button" icon="pi pi-filter-slash" label="Limpar" className="p-button-outlined ml-2" onClick={clearFilter} />
                    </span>

                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText className='md-2' value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Procurar" />
                    </span>
            </div>

        )
    };

    const clearFilter = () => {
        initFilters();
        setGlobalFIlterValue("");
        limparTodos();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters };
        _filters1['global'].value = value;
        setFilters(_filters1);
        setGlobalFIlterValue(value);
    };

    const initFilters = () => {
        setFilters({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        });
    };

    const aceitarBatida = (rowData) => {
        const updatedList = lista.map((item) => {
            if (item.id === rowData.id) {
                item.aceito = true;
                item.negado = false;
                return {
                    ...item,
                };
            }
            return item;
        });
        setLista(updatedList);
    }

    const desconsiderarBatida = (rowData) => {
        const updatedList = lista.map((item) => {
            if (item.id === rowData.id) {
                item.aceito = false;
                item.negado = true;
                return {
                    ...item,
                };
            }
            return item;
        });
        setLista(updatedList);
    }

    const marcarTodos = () => {
        const updatedList = lista.map((item) => {
            item.aceito = true;
            item.negado = false;
            return {
                ...item,
            };
        });
        setLista(updatedList);
    }

    const desmarcarTodos = () => {
        const updatedList = lista.map((item) => {
            item.aceito = false;
            item.negado = true;
            return {
                ...item,
            };
        });
        setLista(updatedList);
    }

    const limparTodos = () => {
        const updatedList = lista.map((item) => {
            item.aceito = false;
            item.negado = false;
            return {
                ...item,
            };
        });
        setLista(updatedList);
    }

    const limparBatida = (rowData) => {
        const updatedList = lista.map((item) => {
            if (item.id === rowData.id) {
                item.aceito = false;
                item.negado = false;
                return {
                    ...item,
                    // atualize os campos desejados
                };
            }
            return item;
        });
        setLista(updatedList); // Atualize o estado com os dados atualizados
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-thumbs-up" className="p-button-rounded p-button-success p-mr-2" onClick={() => aceitarBatida(rowData)} />
                <Button icon="pi pi-thumbs-down" className="p-button-rounded p-button-danger ml-1" onClick={() => desconsiderarBatida(rowData)} />
                <Button icon="pi pi-times" className="p-button-rounded p-button-warning ml-1" onClick={() => limparBatida(rowData)} />
            </React.Fragment>
        );
    }

    const rowClass = (data) => {
        return {
            'row-dsr': data.aceito === true,
            'row-alerta': data.negado === true
        }
    }

    const mapColumnTemplate = (rowData) => {
        const link = 'https://www.openstreetmap.org/?mlat=' + rowData.latitude +'&mlon='+ rowData.longitude +'&zoom=19';
        // const link = 'https://www.openstreetmap.org/#map=19/' + rowData.latitude + '/' + rowData.longitude + '&layers=N';

        return (
            <div className="text-center" style={{ height: '100%' }}>
                <>
                    <Button icon="pi pi-map-marker" onClick={() => window.open(link, '_blank')} />
                </>
            </div>

        );
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <div className="datatable-style">
                        <div className="col-12">
                            <DataTable size="small" value={lista} rowClassName={rowClass} paginator className="p-datatable-gridlines" showGridlines rows={30}
                                dataKey="id" filters={filters} filterDisplay="menu" loading={loading} responsiveLayout="scroll"
                                globalFilterFields={['dataS', 'colaborador.nome', 'visaoLocalizacao', 'latitude', 'longitude']} header={renderHeader} emptyMessage="Nada encontrado.">
                                <Column field="colaborador.nome" header="Colaborador" sortable headerStyle={{ minWidth: '15rem' }} />
                                <Column field="dataS" header="Data" sortable headerStyle={{ minWidth: '3rem', maxWidth: '3rem' }} />
                                <Column field="horarioS" header="Horário" sortable headerStyle={{ minWidth: '2rem', maxWidth: '2rem' }} />
                                {/* <Column field="latitude" header="Latitude" sortable headerStyle={{ minWidth: '5rem' }} /> */}
                                {/* <Column field="longitude" header="Longitude" sortable headerStyle={{ minWidth: '5rem' }} /> */}
                                <Column field="visaoLocalizacao" header="Local Definido" sortable headerStyle={{ minWidth: '5rem' }} />
                                <Column header="Local Batida" headerStyle={{ minWidth: '3rem', maxWidth: '4rem' }} body={mapColumnTemplate} />
                                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
                            </DataTable>
                        </div>
                    </div>

                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                </div>
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(BatidaViaLinkManutencao, comparisonFn);
