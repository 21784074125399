import React from 'react';
import { Button } from 'primereact/button';
import Botoes from '../globais/Botoes';


export default function BTGravar(props) {

    const realizaCadastro = () => {
        props.setSubmitted(true);
        props.cadastrar();
    }

    return (
        <>
            <Button label={Botoes.GRAVAR} icon="pi pi-plus" onClick={realizaCadastro} className="p-button-success mr-2 mt-1 mb-1" />
        </>
    )

}