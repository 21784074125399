import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Messages } from 'primereact/messages';
import { AutoComplete } from 'primereact/autocomplete';
import Mensagens from '../globais/Mensagens';
import BTInativar from '../components/BTInativar';
import BTNovo from '../components/BTNovo';
import BTGravar from '../components/BTGravar';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import ConsultaGenerica from '../components/consultas/ConsultaGenerica';

const BHSindicatos = () => {

    let emptyModel = {
        empresa: null,
        id: null,
        nome: '',
        cnpj: '',
        endereco: '',
        numero: '',
        bairro: '',
        municipio: null,
        fone: '',
    };

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [lista, setLista] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const message = useRef();
    const [autoFilteredValueMunicipio, setAutoFilteredValueMunicipio] = useState([]);
    const [autoValueMunicipio, setAutoValueMunicipio] = useState(null);
    const [autoFilteredValueEmpresa, setAutoFilteredValueEmpresa] = useState([]);
    const [autoValueEmpresa, setAutoValueEmpresa] = useState(null);
    const [url, setUrl] = useState('http://18.231.118.113:8080/api/v1/empresas/1');
    const [cnpjValido, setCnpjValido] = useState(false);

    const colunas = [
        { field: 'nome', header: 'Nome', headerStyle: { minWidth: '15rem' } },
        { field: 'empresa.fantasia', header: 'Empresa', headerStyle: { minWidth: '15rem' } },
        { field: 'cnpj', header: 'CNPJ', headerStyle: { minWidth: '15rem' } },
    ];

    useEffect(() => {
        const buscaEmpresas = () => {
            api
                .get("/empresas")
                .then(response => {
                    setAutoValueEmpresa(response.data)
                    model.empresa = response.data[0];
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaMunicipios = () => {
            api
                .get("/municipios")
                .then(response => setAutoValueMunicipio(response.data))
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
        buscaEmpresas();
        buscaMunicipios();
    }, []);


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <BTGravar setSubmitted={setSubmitted} cadastrar={cadastrar} />
                    <BTNovo setSubmitted={setSubmitted} setModel={setModel} emptyModel={emptyModel} />
                    <BTInativar inativar={inativar} />

                    <ConsultaGenerica
                        lista={lista}
                        model={model}
                        setModel={setModel}
                        onConsultar={consultar}
                        toast={toast}
                        colunas={colunas}
                        titulo="Consultar"
                        mensagemSelecao="Sindicato Selecionado"
                        camposFiltroGlobal={['nome','empresa.fantasia', 'cnpj']}
                        onModelSelecionado = {onModelSelecionado}
                        life={3000}
                        largura='700px'
                    />

                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const onModelSelecionado = (model) => {
        return `${model.nome}`;
    };

    const onInputChange = (e, campo) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...model };
        _model[`${campo}`] = val;
        setModel(_model);
        if (campo === "empresa") {
            setUrl('http://18.231.118.113:8080/api/v1/empresas/' + _model.empresa.id);
        }
    }

    const procurarEmpresas = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueEmpresa([...autoValueEmpresa]);
                }
                else {
                    setAutoFilteredValueEmpresa(autoValueEmpresa.filter((em) => {
                        return em.fantasia.toLowerCase().startsWith(event.query.toLowerCase());

                    }));
                }
            } catch (e) {
            }

        }, 250);
    };

    const validateCnpj = (num) => {
        let cpfValido = cpf.isValid(num);
        let cnpjValido = cnpj.isValid(num);
        if (cpfValido || cnpjValido) {
            setCnpjValido(true);
        } else {
            setCnpjValido(false);
        }
    }

    const cadastrar = () => {
        if (model.nome.trim() && model.cnpj.trim() &&
            model.endereco.trim() && model.bairro.trim() && model.fone.trim() && cnpjValido) {
            if (model.id) {
                api
                    .put("/bancoHoras/sindicatos/" + model.id, {
                        empresa: model.empresa,
                        nome: model.nome,
                        cnpj: model.cnpj,
                        municipio: model.municipio,
                        bairro: model.bairro,
                        endereco: model.endereco,
                        numero: model.numero,
                        fone: model.fone,
                    })
                    .then(response => {
                        setModel(response.data);
                        addMessage("success", Mensagens.CADASTRO_ALTERAR);
                    })
                    .catch(erro => {
                        addMessage("error", Mensagens.ERRO);
                    })
            }
            else {
                api
                    .post("/bancoHoras/sindicatos", {
                        empresa: model.empresa,
                        nome: model.nome,
                        cnpj: model.cnpj,
                        municipio: model.municipio,
                        bairro: model.bairro,
                        endereco: model.endereco,
                        numero: model.numero,
                        fone: model.fone,
                    })
                    .then(response => {
                        setModel(response.data);
                        addMessage("success", Mensagens.CADASTRO_INCLUIR);
                    })
                    .catch(erro => {
                        addMessage("error", Mensagens.ERRO);
                    })
            }
        }
    }

    const inativar = () => {
        if (model.id) {
            api
                .delete("/bancoHoras/sindicatos/" + model.id)
                .then(response => {
                    addMessage("success", Mensagens.CADASTRO_INATIVAR);
                    setSubmitted(false);
                    setModel(emptyModel);
                })
                .catch(erro => {
                    addMessage("error", Mensagens.ERRO);
                })
        } else {
            addMessage("warn", Mensagens.SELECIONE);
        }

    }

    const consultar = () => {
        api
            .get("/bancoHoras/sindicatos")
            .then(response => setLista(response.data))
            .catch(erro => {
                addMessage("error", Mensagens.ERRO);
            })
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    const procurarMunicipios = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValueMunicipio([...autoValueMunicipio]);
            }
            else {
                setAutoFilteredValueMunicipio(autoValueMunicipio.filter((em) => {
                    return em.nome.toLowerCase().startsWith(event.query.toLowerCase());
                }));
            }
        }, 250);
    };

    const onUpload = () => {
        toast.current.show({ severity: 'info', summary: 'Sucesso', detail: 'Enviado para API...falta lógica para salvar.', life: 3000 });
    }



    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="empresa" className="col-12 md:col-1 md:mb-0">Empresa*</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="empresa" dropdown value={model.empresa} onChange={(e) => onInputChange(e, 'empresa')} suggestions={autoFilteredValueEmpresa} completeMethod={procurarEmpresas} field="fantasia" required className={classNames({ 'p-invalid': submitted && !model.empresa })} />
                            {submitted && !model.empresa && <small className="p-invalid">Empresa Obrigatória</small>}
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="nome" className="col-12 md:col-1 md:mb-0">Nome*</label>
                        <div className="col-12 md:col-11">
                            <InputText id="nome" maxLength={100} placeholder="Máximo 100 caracteres" value={model.nome} onChange={(e) => onInputChange(e, 'nome')} required autoFocus className={classNames({ 'p-invalid': submitted && !model.nome })} />
                            {submitted && !model.nome && <small className="p-invalid">Nome Obrigatório</small>}
                        </div>
                    </div>


                    <div className="p-fluid formgrid grid">

                        <label htmlFor="cnpj" className="col-12 md:col-1 md:mt-2">CNPJ/CPF*</label>
                        <div className="field col-12 md:col-5 mb-2">
                            <InputText id="cnpj" maxLength={45} placeholder="Máximo 45 caracteres" value={cnpj.format(model.cnpj)} onBlur={(e) => validateCnpj(e.target.value)} onChange={(e) => onInputChange(e, 'cnpj')} required className={classNames({ 'p-invalid': submitted && !cnpjValido })} />
                            {submitted && !cnpjValido && <small className="p-invalid">CNPJ ou CPF inválido.</small>}
                        </div>

                        {/* <label htmlFor="cnpj" className="col-12 md:col-1 md:mt-2">CNPJ/CPF*</label>
                        <div className="field col-12 md:col-5 mb-2">
                            <InputText id="cnpj" value={model.cnpj} onChange={(e) => onInputChange(e, 'cnpj')} required className={classNames({ 'p-invalid': submitted && !model.cnpj })} />
                            {submitted && !model.cnpj && <small className="p-invalid">CNPJ/CPF Obrigatório</small>}
                        </div> */}
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="endereco" className="col-1 md:col-1 md:mt-2">Endereço*</label>
                        <div className="field col-12 md:col-8 mb-2">
                            <InputText id="endereco" maxLength={200} placeholder="Máximo 200 caracteres" value={model.endereco} onChange={(e) => onInputChange(e, 'endereco')} required className={classNames({ 'p-invalid': submitted && !model.endereco })} />
                            {submitted && !model.endereco && <small className="p-invalid">Endereço Obrigatório</small>}
                        </div>

                        {/* <label htmlFor="numero" className="col-1 md:col-1 md:mt-2">Número*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputText id="numero" value={model.numero} onChange={(e) => onInputChange(e, 'numero')} required className={classNames({ 'p-invalid': submitted && !model.numero })} />
                            {submitted && !model.numero && <small className="p-invalid">Número Obrigatório</small>}
                        </div> */}

                        <label htmlFor="bairro" className="col-1 md:col-1 md:mt-2">Bairro*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputText id="bairro" maxLength={100} placeholder="Máximo 100 caracteres" value={model.bairro} onChange={(e) => onInputChange(e, 'bairro')} required className={classNames({ 'p-invalid': submitted && !model.bairro })} />
                            {submitted && !model.bairro && <small className="p-invalid">Bairro Obrigatório</small>}
                        </div>
                    </div>

                    {/* <div className="field grid p-fluid mb-2">
                        <label htmlFor="bairro" className="col-12 md:col-1 md:mb-0">Bairro*</label>
                        <div className="col-12 md:col-11">
                            <InputText id="bairro" value={model.bairro} onChange={(e) => onInputChange(e, 'bairro')} required className={classNames({ 'p-invalid': submitted && !model.bairro })} />
                            {submitted && !model.bairro && <small className="p-invalid">Bairro Obrigatório</small>}
                        </div>
                    </div> */}

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="municipio" className="col-12 md:col-1 md:mb-0">Cidade</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="municipio" dropdown value={model.municipio} onChange={(e) => onInputChange(e, 'municipio')} suggestions={autoFilteredValueMunicipio} completeMethod={procurarMunicipios} field="nome" />
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="fone" className="col-12 md:col-1 md:mt-2">Fone*</label>
                        <div className="field col-12 md:col-5 mb-2">
                            <InputText id="fone" maxLength={100} placeholder="Máximo 100 caracteres" value={model.fone} onChange={(e) => onInputChange(e, 'fone')} required className={classNames({ 'p-invalid': submitted && !model.fone })} />
                            {submitted && !model.fone && <small className="p-invalid">Fone Obrigatório</small>}
                        </div>

                    </div>

                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                </div >
            </div >
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(BHSindicatos, comparisonFn);
