export default class Formatacao {
    formatarTelefone(num) {
        num = num.replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1');
        return num;
    }

    formatarCep(num) {
        num = num.replace(/\D/g, '')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{3})\d+?$/, '$1');
        return num;
    }

    formatarTempo(num) {
        num = num.replace(/\D/g, '')
        .replace(/(\d+)(\d{2})$/, '$1:$2');
    return num;
    }
}

