import axios from "axios";
import DadosApi from "../globais/DadosApi";

const usuario = JSON.parse(localStorage.getItem('usuario'));

// const cliente = { "id": 0, "nomeCliente": "Teste" };

const cliente = JSON.parse(localStorage.getItem("cliente"));

const api = axios.create({

  // baseURL: "http://18.231.118.113:8080/api/v1",
  // baseURL: "http://192.168.100.56:8080/api/v1", 

  // baseURL: "http://191.96.78.164:8080/api/v1", 

  //baseURL: "https://www.heup.com.br/api/v1",

  // baseURL: "http://localhost:8080/api/v1", 

  baseURL: DadosApi.CAMINHO_API,



  // headers: {
  //   "Content-type": "application/json"
  // }

  headers: {
    
    "Content-type": "application/json",
    "Cliente-id": cliente && cliente.id ? "" + cliente.id : "0",
    "Authorization": usuario && usuario.token ? 'Bearer ' + usuario.token : {},
    "Usuario-id": usuario && usuario.id ? usuario.id : {}
  }

});

export default api;