import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Messages } from 'primereact/messages';
import { AutoComplete } from 'primereact/autocomplete';
import { InputMask } from 'primereact/inputmask';
import { SelectButton } from 'primereact/selectbutton';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Divider } from 'primereact/divider';
import { Fieldset } from 'primereact/fieldset';
import { InputSwitch } from 'primereact/inputswitch';
import { RadioButton } from 'primereact/radiobutton';
import { Image } from "primereact/image";
import { FileUpload } from 'primereact/fileupload';
import BTAtivar from '../components/BTAtivar';
import Mensagens from '../globais/Mensagens';
import BTInativar from '../components/BTInativar';
import BTNovo from '../components/BTNovo';
import BTGravar from '../components/BTGravar';
import { cpf } from 'cpf-cnpj-validator';
import ConsultaGenerica from '../components/consultas/ConsultaGenerica';
import { PositionCache } from '@fullcalendar/core';

const Colaboradores = () => {

    let emptyModel = {
        id: null,
        empresa: null,
        nome: '',
        pis: '',
        rg: '',
        cpf: '',
        carteira: '',
        tipoRegistro: { tipo: 'MENSALISTA', id: 1 },
        cargo: null,
        departamento: null,
        jornada: null,
        centrodecusto: null,
        dataadmissaoS: '',
        nrocnh: '',
        cracha: '',
        celular: '',
        cartaoProximidade: '',
        cartaoBarras: '',
        senha: '',
        verificarBiometria: 0,
        email: '',
        endereco: '',
        bairro: '',
        municipio: null,
        datanascS: '',
        dataCrachaS: '',
        cep: '',
        sexo: '',
        estadocivil: null,
        unidade: null,
        naoBatePonto: 0,
        utilizarREPsEmpresa: 0,
        enderecoFoto: '',
        dataDesligamentoS: '',
        motivoDesligamento: '',
    };

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [lista, setLista] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [autoFilteredValueEmpresa, setAutoFilteredValueEmpresa] = useState([]);
    const [autoFilteredValueCargo, setAutoFilteredValueCargo] = useState([]);
    const [autoFilteredValueDepartamento, setAutoFilteredValueDepartamento] = useState([]);
    const [autoFilteredValueJornada, setAutoFilteredValueJornada] = useState([]);
    const [autoFilteredValueCentrodecusto, setAutoFilteredValueCentrodeCusto] = useState([]);
    const [autoFilteredValueUnidade, setAutoFilteredValueUnidade] = useState([]);
    const [autoFilteredValueMunicipio, setAutoFilteredValueMunicipio] = useState([]);
    const [autoFilteredValueEstadoCivil, setAutoFilteredValueEstadoCivil] = useState([]);
    const [autoValueEmpresa, setAutoValueEmpresa] = useState(null);
    const [autoValueCargo, setAutoValueCargo] = useState(null);
    const [autoValueDepartamento, setAutoValueDepartamento] = useState(null);
    const [autoValueJornada, setAutoValueJornada] = useState(null);
    const [autoValueCentrodecusto, setAutoValueCentrodecusto] = useState(null);
    const [autoValueUnidade, setAutoValueUnidade] = useState(null);
    const [autoValueMunicipio, setAutoValueMunicipio] = useState(null);
    const [autoValueEstadoCivil, setAutoValueEstadoCivil] = useState(null);
    const [campoDesativado, setCampoDesativado] = useState(false);
    const [url] = useState('http://18.231.118.113:8080/api/v1/colaboradores/1');
    const [cpfValido, setCpfValido] = useState(false);

    const colunas = [
        { field: 'id', header: 'Código', headerStyle: { minWidth: '5rem' } },
        { field: "cracha", header: "Crachá", headerStyle: { maxWidth: '5rem' } },
        { field: "nome", header: "Nome", headerStyle: { maxWidth: '15rem' } },
        { field: "empresa.fantasia", header: "Empresa", headerStyle: { maxWidth: '15rem' } },
        { field: "pis", header: "PIS", headerStyle: { maxWidth: '9rem' } },
        { field: "cargo.descricao", header: "Cargo", headerStyle: { maxWidth: '15rem' } },
    ];

    const message = useRef();

    useEffect(() => {
        const buscaEmpresas = () => {
            api
                .get("/empresas")
                .then(response => {
                    setAutoValueEmpresa(response.data);
                    let _model = { ...model };
                    _model[`${"empresa"}`] = response.data[0];
                    setModel(_model);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaCargos = () => {
            api
                .get("/cargos")
                .then(response => setAutoValueCargo(response.data))
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaDepartamentos = () => {
            api
                .get("/departamentos")
                .then(response => setAutoValueDepartamento(response.data))
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaJornadas = () => {
            api
                .get("/jornadas")
                .then(response => setAutoValueJornada(response.data))
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaCentrodeCustos = () => {
            api
                .get("/centrodecusto")
                .then(response => setAutoValueCentrodecusto(response.data))
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaUnidades = () => {
            api
                .get("/unidades")
                .then(response => setAutoValueUnidade(response.data))
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaMunicipios = () => {
            api
                .get("/municipios")
                .then(response => setAutoValueMunicipio(response.data))
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaEstadoCivil = () => {
            api
                .get("/estadoscivis")
                .then(response => setAutoValueEstadoCivil(response.data))
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        buscaEmpresas();
        buscaCargos();
        buscaDepartamentos();
        buscaJornadas();
        buscaCentrodeCustos();
        buscaUnidades();
        buscaMunicipios();
        buscaEstadoCivil();
    }, []);

    const tipoRegistroValores = [
        { tipo: 'MENSALISTA', id: 1 },
        { tipo: 'HORISTA', id: 2 },
    ];

    const procurarEmpresas = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValueEmpresa([...autoValueEmpresa]);
            }
            else {
                setAutoFilteredValueEmpresa(autoValueEmpresa.filter((em) => {
                    return em.fantasia.toLowerCase().startsWith(event.query.toLowerCase());
                }));
            }
        }, 250);
    };

    const procurarCargos = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValueCargo([...autoValueCargo]);
            }
            else {
                setAutoFilteredValueCargo(autoValueCargo.filter((em) => {
                    return em.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                }));
            }
        }, 250);
    };

    const procurarDepartamentos = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValueDepartamento([...autoValueDepartamento]);
            }
            else {
                setAutoFilteredValueDepartamento(autoValueDepartamento.filter((em) => {
                    return em.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                }));
            }
        }, 250);
    };

    const procurarJornadas = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValueJornada([...autoValueJornada]);
            }
            else {
                setAutoFilteredValueJornada(autoValueJornada.filter((em) => {
                    return em.nome.toLowerCase().startsWith(event.query.toLowerCase());
                }));
            }
        }, 250);
    };

    const procurarCentrodeCustos = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValueCentrodeCusto([...autoValueCentrodecusto]);
            }
            else {
                setAutoFilteredValueCentrodeCusto(autoValueCentrodecusto.filter((em) => {
                    return em.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                }));
            }
        }, 250);
    };

    const procurarUnidades = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValueUnidade([...autoValueUnidade]);
            }
            else {
                setAutoFilteredValueUnidade(autoValueUnidade.filter((em) => {
                    return em.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                }));
            }
        }, 250);
    };

    const procurarMunicipios = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValueMunicipio([...autoValueMunicipio]);
            }
            else {
                setAutoFilteredValueMunicipio(autoValueMunicipio.filter((em) => {
                    return em.nome.toLowerCase().startsWith(event.query.toLowerCase());
                }));
            }
        }, 250);
    };

    const procurarEstadosCivis = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValueEstadoCivil([...autoValueEstadoCivil]);
            }
            else {
                setAutoFilteredValueEstadoCivil(autoValueEstadoCivil.filter((em) => {
                    return em.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                }));
            }
        }, 250);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <BTGravar setSubmitted={setSubmitted} cadastrar={cadastrar} />
                    <BTNovo setSubmitted={setSubmitted} setModel={setModel} emptyModel={emptyModel} setCampoDesativado={setCampoDesativado} />
                    <BTInativar inativar={inativar} />
                    <BTAtivar ativar={ativar} />

                    {/* <ConsultaColaboradores
                        lista={lista}
                        model={model}
                        setModel={setModel}
                        consultar={consultar}
                        toast={toast}
                        setCampoDesativado={setCampoDesativado}
                        textoLabel={"Consultar Ativos"}
                    /> */}

                    {/* <ConsultaColaboradores
                        lista={lista}
                        model={model}
                        setModel={setModel}
                        consultar={consultarDesligados}
                        toast={toast}
                        setCampoDesativado={setCampoDesativado}
                        textoLabel={"Consultar Inativos"}
                    /> */}

                    <ConsultaGenerica
                        lista={lista}
                        model={model}
                        setModel={setModel}
                        onConsultar={consultar}
                        toast={toast}
                        colunas={colunas}
                        titulo="Consultar Ativos"
                        mensagemSelecao="Colaborador Selecionado"
                        camposFiltroGlobal={['nome', 'empresa.fantasia', 'cargo.descricao', 'pis', 'id', 'cracha']}
                        onModelSelecionado={onModelSelecionado}
                        setCampoDesativado={setCampoDesativado}
                        life={3000}
                        largura='950px'
                    />

                    <ConsultaGenerica
                        lista={lista}
                        model={model}
                        setModel={setModel}
                        onConsultar={consultarDesligados}
                        toast={toast}
                        colunas={colunas}
                        titulo="Consultar Inativos"
                        mensagemSelecao="Colaborador Selecionado"
                        camposFiltroGlobal={['nome', 'empresa.fantasia', 'cargo.descricao', 'pis', 'id', 'cracha']}
                        onModelSelecionado={onModelSelecionado}
                        setCampoDesativado={setCampoDesativado}
                        life={3000}
                        largura='950px'
                    />

                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const onModelSelecionado = (model) => {
        return `${model.nome}`;
    };

    const onUpload = () => {
        toast.current.show({ severity: 'info', summary: 'Sucesso', detail: 'Enviado para API...falta lógica para salvar.', life: 3000 });
    }

    const onInputChange = (e, descricao) => {
        let val = (e.target && e.target.value) || '';
        let _model = { ...model };

        if(descricao === "cpf"){
            let numerico = val.replace(/[^0-9]/g, '');
            _model.cpf = numerico;
            _model.pis = numerico;
        }else if(descricao === "pis"){
            let numerico = val.replace(/[^0-9]/g, '');
            _model.pis = numerico;
        }else{
            _model[`${descricao}`] = val;
        }

        setModel(_model);
    }

    const onSwitchChange = (e, descricao) => {
        const val = e.value || '';
        let valor = 0
        if (val) {
            valor = 1;
        }
        let _model = { ...model };
        _model[`${descricao}`] = valor;
        setModel(_model);
    }

    const onRadioChange = (e, descricao) => {
        const val = e.value || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
    }

    const selectChange = (e, descricao) => {
        const val = (e.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
    }

    const validateCpf = (num) => {
        setCpfValido(cpf.isValid(num));
    }

    const cadastrar = () => {
        if (model.nome.trim() && model.pis && model.dataadmissaoS
            && model.cargo && model.departamento && model.jornada && model.centrodecusto && cpfValido) {
                const payload = {
                    id: model.id,
                    nome: model.nome,
                    pis: model.pis,
                    rg: model.rg,
                    cpf: model.cpf,
                    tipoRegistro: model.tipoRegistro,
                    carteira: model.carteira,
                    cargo: model.cargo,
                    departamento: model.departamento,
                    jornada: model.jornada,
                    centrodecusto: model.centrodecusto,
                    dataadmissaoS: model.dataadmissaoS,
                    nrocnh: model.nrocnh,
                    cracha: model.cracha,
                    cartaoProximidade: model.cartaoProximidade,
                    cartaoBarras: model.cartaoBarras,
                    celular: model.celular,
                    senha: model.senha,
                    verificarBiometria: model.verificarBiometria,
                    email: model.email,
                    endereco: model.endereco,
                    bairro: model.bairro,
                    municipio: model.municipio,
                    datanascS: model.datanascS,
                    dataCrachaS: model.dataCrachaS,
                    cep: model.cep,
                    sexo: model.sexo,
                    estadocivil: model.estadocivil,
                    unidade: model.unidade,
                    naoBatePonto: model.naoBatePonto,
                    utilizarREPsEmpresa: model.utilizarREPsEmpresa,
                    enderecoFoto: model.enderecoFoto,
                    empresa: model.empresa,
                    dataDesligamentoS: model.dataDesligamentoS,
                    motivoDesligamento: model.motivoDesligamento,
                };

            if (model.id) {
                api
                    .put("/colaboradores/" + model.id, payload)
                    .then(response => {
                        setModel(response.data);
                        addMessage("success", Mensagens.CADASTRO_ALTERAR);
                    })
                    .catch((error) => {
                        if (error.response.data.message) {
                            addMessage("error", error.response.data.message);
                        } else {
                            addMessage("error", Mensagens.ERRO);
                        }
                    })
            }
            else {
                api
                    .post("/colaboradores", payload)
                    .then(response => {
                        setModel(response.data);
                        addMessage("success", Mensagens.CADASTRO_INCLUIR);
                    })
                    .catch((error) => {
                        if (error.response.data.message) {
                            addMessage("error", error.response.data.message);
                        } else {
                            addMessage("error", Mensagens.ERRO);
                        }
                    })
            }
        }
    }

    const inativar = () => {
        if (model.id && model.empresa.id && model.dataDesligamentoS) {
            api
                .post("empresas/" + model.empresa.id + "/colaboradores/" + model.id + "/ativar", {
                    id: model.id,
                    empresa: model.empresa,
                    dataDesligamentoS: model.dataDesligamentoS,
                    motivoDesligamento: model.motivoDesligamento,
                })
                .then(() => {
                    addMessage("success", Mensagens.CADASTRO_INATIVAR);
                    setSubmitted(false);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        } else {
            addMessage("warn", Mensagens.SELECIONE_COLABORADOR_INATIVAR);
        }
    }

    const ativar = () => {
        if (model.id && model.empresa.id) {
            api
                .put("empresas/" + model.empresa.id + "/colaboradores/" + model.id + "/ativar")
                .then(response => {
                    addMessage("success", Mensagens.CADASTRO_ATIVAR);
                    setSubmitted(false);
                })
                .catch(error => {
                    if (error.response.data.message) {
                        addMessage("error", error.response.data.message);
                    } else {
                        addMessage("error", Mensagens.ERRO);
                    }
                })
        } else {
            addMessage("warn", Mensagens.SELECIONE);
        }

    }

    const consultar = () => {
        api
            .get("/colaboradores")
            .then(response => setLista(response.data))
            .catch(erro => {
                addMessage("error", Mensagens.ERRO);
            })
    }

    const consultarDesligados = () => {
        api
            .get("/colaboradores/inativos")
            .then(response => setLista(response.data))
            .catch(erro => {
                addMessage("error", Mensagens.ERRO);
            })
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <Accordion activeIndex={0}>
                        <AccordionTab header="Obrigatórios">

                            <div className="p-fluid formgrid grid">
                                <label htmlFor="tipoRegistro" className="col-12 md:col-1 md:mt-2">Tipo de Registro</label>
                                <div className="field col-12 md:col-2 mb-2">
                                    <SelectButton value={model.tipoRegistro} onChange={(e) => selectChange(e, 'tipoRegistro')} options={tipoRegistroValores} optionLabel="tipo" />
                                </div>
                            </div>

                            <div className="p-fluid formgrid grid">
                                <label htmlFor="codigo" className="col-12 md:col-1 md:mt-2">Código*</label>
                                <div className="field col-12 md:col-2 mb-2">
                                    <InputText id="codigo" value={model.id || ''} disabled />
                                </div>
                            </div>

                            <div className="field grid p-fluid mb-2">
                                <label htmlFor="empresa" className="col-12 md:col-1 md:mb-0">Empresa*</label>
                                <div className="col-12 md:col-11">
                                    <AutoComplete placeholder="Procure" id="empresa" dropdown value={model.empresa} onChange={(e) => onInputChange(e, 'empresa')} suggestions={autoFilteredValueEmpresa} completeMethod={procurarEmpresas} field="fantasia" disabled={campoDesativado} required className={classNames({ 'p-invalid': submitted && !model.empresa })} />
                                    {submitted && !model.empresa && <small className="p-invalid">Empresa Obrigatória</small>}
                                </div>
                            </div>

                            <div className="field grid p-fluid mb-2">
                                <label htmlFor="nome" className="col-12 mb-0 md:col-1 md:mb-0">Nome*</label>
                                <div className="col-12 md:col-11">
                                    <InputText id="nome" maxLength={50} placeholder="Máximo 50 caracteres" value={model.nome} onChange={(e) => onInputChange(e, 'nome')} required autoFocus className={classNames({ 'p-invalid': submitted && !model.nome })} />
                                    {submitted && !model.nome && <small className="p-invalid">Nome Obrigatório</small>}
                                </div>
                            </div>

                            <div className="p-fluid formgrid grid">
                                <label htmlFor="cpf" className="col-12 md:col-1 md:mt-2">CPF*</label>
                                <div className="field col-12 md:col-2 mb-2">
                                    <InputText id="cpf" maxLength={18} placeholder="Máximo 18 caracteres" value={model.cpf} onBlur={(e) => validateCpf(e.target.value)} onChange={(e) => onInputChange(e, 'cpf')} required className={classNames({ 'p-invalid': submitted && !cpfValido })} />
                                    {submitted && !cpfValido && <small className="p-invalid">CPF inválido.</small>}
                                </div>

                                <label htmlFor="pis" className="col-12 md:col-1 md:mt-2">ID REP*</label>
                                <div className="field col-12 md:col-2 mb-2">
                                    <InputText id="pis" maxLength={20} placeholder="Máximo 20 caracteres" value={model.pis} onChange={(e) => onInputChange(e, 'pis')} tooltip='Atenção ao preencher! Pode ser CPF, 0+PIS ou 9+CPF. Verifique o tipo de REP ao preencher.' tooltipOptions={{position: 'top'}} required className={classNames({ 'p-invalid': submitted && !model.pis })} />
                                    {submitted && !model.pis && <small className="p-invalid">PIS Obrigatório</small>}
                                </div>

                                <label htmlFor="dataadmissao" className="col-12 md:col-1 md:mt-0">Data de Admissão*</label>
                                <div className="field col-12 md:col-2 mb-2">
                                    <InputMask id="dataadmissao" mask="99/99/9999" value={model.dataadmissaoS} onChange={(e) => onInputChange(e, 'dataadmissaoS')} required className={classNames({ 'p-invalid': submitted && !model.dataadmissaoS })} />
                                    {submitted && !model.dataadmissaoS && <small className="p-invalid">Data de Admissão Obrigatória</small>}
                                </div>
                            </div>

                            <div className="field grid p-fluid mb-2">
                                <label htmlFor="cargo" className="col-12 md:col-1 md:mb-0">Cargo*</label>
                                <div className="col-12 md:col-11">
                                    <AutoComplete placeholder="Procure" id="cargo" dropdown value={model.cargo} onChange={(e) => onInputChange(e, 'cargo')} suggestions={autoFilteredValueCargo} completeMethod={procurarCargos} field="descricao" required className={classNames({ 'p-invalid': submitted && !model.cargo })} />
                                    {submitted && !model.cargo && <small className="p-invalid">Cargo Obrigatório</small>}
                                </div>
                            </div>

                            <div className="field grid p-fluid mb-2">
                                <label htmlFor="departamento" className="col-12 md:col-1 md:mb-0">Departamento*</label>
                                <div className="col-12 md:col-11">
                                    <AutoComplete placeholder="Procure" id="departamento" dropdown value={model.departamento} onChange={(e) => onInputChange(e, 'departamento')} suggestions={autoFilteredValueDepartamento} completeMethod={procurarDepartamentos} field="descricao" required className={classNames({ 'p-invalid': submitted && !model.departamento })} />
                                    {submitted && !model.departamento && <small className="p-invalid">Departamento Obrigatório</small>}
                                </div>
                            </div>

                            <div className="field grid p-fluid mb-2">
                                <label htmlFor="jornada" className="col-12 md:col-1 md:mb-0">Jornada*</label>
                                <div className="col-12 md:col-11">
                                    <AutoComplete placeholder="Procure" id="jornada" dropdown value={model.jornada} onChange={(e) => onInputChange(e, 'jornada')} suggestions={autoFilteredValueJornada} completeMethod={procurarJornadas} field="nome" disabled={campoDesativado} required className={classNames({ 'p-invalid': submitted && !model.jornada })} />
                                    {submitted && !model.jornada && <small className="p-invalid">Jornada Obrigatória</small>}
                                </div>
                            </div>

                            <div className="field grid p-fluid mb-2">
                                <label htmlFor="centrodecusto" className="col-12 md:col-1 md:mb-0">Centro de Custo*</label>
                                <div className="col-12 md:col-11">
                                    <AutoComplete placeholder="Procure" id="centrodecusto" dropdown value={model.centrodecusto} onChange={(e) => onInputChange(e, 'centrodecusto')} suggestions={autoFilteredValueCentrodecusto} completeMethod={procurarCentrodeCustos} field="descricao" required className={classNames({ 'p-invalid': submitted && !model.centrodecusto })} />
                                    {submitted && !model.centrodecusto && <small className="p-invalid">Centro de Custo Obrigatório</small>}
                                </div>
                            </div>

                        </AccordionTab>
                        <AccordionTab header="Opcionais">
                            <div className="grid">
                                <div className="col-5 flex align-items-center justify-content-center">
                                    <div className="p-fluid">

                                        <div className="field grid mb-0">
                                            <label htmlFor="cracha" className="md:col-2 md:mb-0 mb-0">Crachá</label>
                                            <div className="md:col-10">
                                                <InputText id="cracha" maxLength={15} placeholder="Máximo 15 caracteres" value={model.cracha} onChange={(e) => onInputChange(e, 'cracha')} />
                                            </div>
                                        </div>

                                        <div className="field grid mb-0">
                                            <label htmlFor="rg" className="md:col-2 md:mb-0 mb-0">RG</label>
                                            <div className="md:col-10">
                                                <InputText id="rg" maxLength={15} placeholder="Máximo 15 caracteres" value={model.rg} onChange={(e) => onInputChange(e, 'rg')} />
                                            </div>
                                        </div>

                                        <div className="field grid mb-0">
                                            <label htmlFor="cnh"  className=" md:col-2 md:mb-0 mb-0">CNH</label>
                                            <div className="md:col-10">
                                                <InputText id="cnh" maxLength={30} placeholder="Máximo 30 caracteres" value={model.nrocnh} onChange={(e) => onInputChange(e, 'nrocnh')} />
                                            </div>
                                        </div>

                                        <div className="field grid mb-0">
                                            <label htmlFor="carteira" className="md:col-2 md:mb-0 mb-0">Registro</label>
                                            <div className="md:col-10">
                                                <InputText id="carteira" maxLength={14} placeholder="Máximo 14 caracteres" value={model.carteira} onChange={(e) => onInputChange(e, 'carteira')} />
                                            </div>
                                        </div>

                                        <div className="field grid mb-0">
                                            <label htmlFor="celular" className="md:col-2 md:mb-0 mb-0">Celular</label>
                                            <div className="md:col-10">
                                                <InputMask id="celular" maxLength={20} mask="(99) 9.9999-9999" value={model.celular} onChange={(e) => onInputChange(e, 'celular')} />
                                            </div>
                                        </div>

                                        <Fieldset legend="REP" toggleable collapsed className="mb-2 mt-2">
                                            <div className="field grid mb-2">
                                                <label htmlFor="codigoProximidade" className="md:col-3 md:mb-0 mb-0">Código de Proximidade</label>
                                                <div className="md:col-9">
                                                    <InputText id="codigoProximidade" maxLength={45} placeholder="Máximo 45 caracteres" value={model.cartaoProximidade} onChange={(e) => onInputChange(e, 'cartaoProximidade')} />
                                                </div>
                                            </div>

                                            <div className="field grid mb-0">
                                                <label htmlFor="codigoBarras" className="md:col-3 md:mb-0 mb-0">Código de Barras</label>
                                                <div className="md:col-9">
                                                    <InputText id="codigoBarras" maxLength={45} placeholder="Máximo 45 caracteres" value={model.cartaoBarras} onChange={(e) => onInputChange(e, 'cartaoBarras')} />
                                                </div>
                                            </div>

                                            <div className="field grid mb-0">
                                                <label htmlFor="senha" className="md:col-3 md:mb-0 mb-0">Senha</label>
                                                <div className="md:col-9">
                                                    <InputText id="senha" maxLength={4} placeholder="Máximo 4 caracteres" value={model.senha} onChange={(e) => onInputChange(e, 'senha')} />
                                                </div>
                                            </div>

                                            <div className="field grid mb-0">
                                                <label htmlFor="verificabiometria" className="md:col-3 md:mb-0 mb-0">Verifica Biometria?</label>
                                                <div className="md:col-9">
                                                    <InputSwitch checked={model.verificarBiometria === 1} onChange={(e) => onSwitchChange(e, 'verificarBiometria')} />
                                                </div>
                                            </div>

                                        </Fieldset>

                                        <div className="grid mt-2">
                                            <label htmlFor="sexo" className="md:col-2 md:mb-0">Sexo</label>
                                            <div className="md:col-3">
                                                <div className="field-radiobutton">
                                                    <RadioButton inputId="option1" name="option" value={'M'} checked={model.sexo === 'M'} onChange={(e) => onRadioChange(e, 'sexo')} />
                                                    <label htmlFor="option1">Masculino</label>
                                                </div>
                                            </div>
                                            <div className="md:col-5">
                                                <div className="field-radiobutton">
                                                    <RadioButton inputId="option2" name="option" value={'F'} checked={model.sexo === 'F'} onChange={(e) => onRadioChange(e, 'sexo')} />
                                                    <label htmlFor="option2">Feminino</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 grid">

                                            <div className="field grid">
                                                <label htmlFor="naoBatePonto" className="md:col-6 md:mb-0 mb-0">Não Bate Ponto?</label>
                                                <div className="md:col-6">
                                                    <InputSwitch checked={model.naoBatePonto === 1} onChange={(e) => onSwitchChange(e, 'naoBatePonto')} />
                                                </div>
                                            </div>

                                            <div className="field grid">
                                                <label htmlFor="utilizarepempresas" className="md:col-5 md:mb-0 mb-0">Utiliza REPs Empresa?</label>
                                                <div className="md:col-3">
                                                    <InputSwitch checked={model.utilizarREPsEmpresa === 1} onChange={(e) => onSwitchChange(e, 'utilizarREPsEmpresa')} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-1">
                                    <Divider layout="vertical">
                                        {/* <b>OU</b> */}
                                    </Divider>
                                </div>

                                <div className="col-5 align-items-center justify-content-center">
                                    <div className="p-fluid">
                                        <div className="field grid mb-0">
                                            <label htmlFor="email" className="md:col-2 md:mb-0 mb-0">Email</label>
                                            <div className="md:col-10">
                                                <InputText id="email"  value={model.email} maxLength={50} placeholder="Máximo 50 caracteres" onChange={(e) => onInputChange(e, 'email')} />
                                            </div>
                                        </div>

                                        <div className="field grid mb-0">
                                            <label htmlFor="endereco" className="md:col-2 md:mb-0 mb-0">Endereço</label>
                                            <div className="md:col-10">
                                                <InputText id="endereco" maxLength={50} placeholder="Máximo 50 caracteres" value={model.endereco} onChange={(e) => onInputChange(e, 'endereco')} />
                                            </div>
                                        </div>

                                        <div className="field grid mb-0">
                                            <label htmlFor="bairro" className="md:col-2 md:mb-0 mb-0">Bairro</label>
                                            <div className="md:col-10">
                                                <InputText id="bairro" maxLength={30} placeholder="Máximo 30 caracteres" value={model.bairro} onChange={(e) => onInputChange(e, 'bairro')} />
                                            </div>
                                        </div>

                                        <div className="field grid mb-0">
                                            <label htmlFor="municipio" className="md:col-2 md:mb-0">Cidade</label>
                                            <div className="md:col-10">
                                                <AutoComplete placeholder="Procure" id="municipio" dropdown value={model.municipio} onChange={(e) => onInputChange(e, 'municipio')} suggestions={autoFilteredValueMunicipio} completeMethod={procurarMunicipios} field="nome" />
                                            </div>
                                        </div>

                                        <div className="field grid mb-0">
                                            <label htmlFor="cep" className="md:col-2 md:mb-0 mb-0">CEP</label>
                                            <div className="md:col-10">
                                                <InputText id="cep" maxLength={9} placeholder="Máximo 9 caracteres" value={model.cep} onChange={(e) => onInputChange(e, 'cep')} />
                                            </div>
                                        </div>

                                        <div className="field grid mb-0">
                                            <label htmlFor="datanascimento" className="md:col-2 md:mb-0 mb-0">Data de Nascimento</label>
                                            <div className="md:col-5">
                                                <InputMask id="datanascimento" mask="99/99/9999" value={model.datanascS} onChange={(e) => onInputChange(e, 'datanascS')} />
                                            </div>
                                        </div>

                                        <div className="field grid mb-0">
                                            <label htmlFor="datacracha" className="md:col-2 md:mb-0 mb-0">Data do Crachá</label>
                                            <div className="md:col-5">
                                                <InputMask id="datacracha" mask="99/99/9999" value={model.dataCrachaS} onChange={(e) => onInputChange(e, 'dataCrachaS')} />
                                            </div>
                                        </div>

                                        <div className="field grid mb-2">
                                            <label htmlFor="unidade" className="md:col-2 md:mb-0">Unidade</label>
                                            <div className="md:col-10">
                                                <AutoComplete placeholder="Procure" id="unidade" dropdown value={model.unidade} onChange={(e) => onInputChange(e, 'unidade')} suggestions={autoFilteredValueUnidade} completeMethod={procurarUnidades} field="descricao" />
                                            </div>
                                        </div>

                                        <div className="field grid mb-2">
                                            <label htmlFor="estadocivil" className="md:col-2 md:mb-0">Estado Civil</label>
                                            <div className="md:col-10">
                                                <AutoComplete placeholder="Procure" id="estadocivil" dropdown value={model.estadocivil} onChange={(e) => onInputChange(e, 'estadocivil')} suggestions={autoFilteredValueEstadoCivil} completeMethod={procurarEstadosCivis} field="descricao" />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="flex justify-content-center">
                                <Image src="assets/demo/images/galleria/semfoto.jpg" alt="galleria" width={250} preview />
                            </div>

                            <div className="flex justify-content-center">
                                <FileUpload name="files" mode='basic' url={url} onUpload={onUpload} multiple accept="image/*" maxFileSize={1000000} auto chooseLabel="Escolher" />
                            </div>

                        </AccordionTab>
                        <AccordionTab header="Inativação">

                            <div className="p-fluid formgrid grid">
                                <label htmlFor="datadesligamento" className="col-12 md:col-1 md:mt-2">Data do Desligamento</label>
                                <div className="field col-12 md:col-2 mb-2">
                                    <InputMask id="datadesligamento" mask="99/99/9999" value={model.dataDesligamentoS || ''} onChange={(e) => onInputChange(e, 'dataDesligamentoS')} />
                                </div>
                            </div>

                            <div className="p-fluid formgrid grid">
                                <label htmlFor="motivodesligamento" className="col-12 md:col-1 md:mt-2">Motivo</label>
                                <div className="field col-12 md:col-11 mb-2">
                                    <InputText id="motivodesligamento" maxLength={500} placeholder="Máximo 500 caracteres" value={model.motivoDesligamento || ''} onChange={(e) => onInputChange(e, 'motivoDesligamento')} />
                                </div>
                            </div>


                        </AccordionTab>
                    </Accordion>

                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Colaboradores, comparisonFn);
