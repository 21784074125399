import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';
import { Messages } from 'primereact/messages';
import { AutoComplete } from 'primereact/autocomplete';
import { InputMask } from 'primereact/inputmask';
import { Divider } from 'primereact/divider';
import Mensagens from '../globais/Mensagens';
import BTListarBatidas from '../components/BTListarBatidas';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ListBox } from 'primereact/listbox';
import { Dropdown } from 'primereact/dropdown';
import '../assets/datatables/datatable.css';

const Tratamentos = () => {

    let emptyModel = {
        id: null,
        empresa: null,
        departamento: null,
        centrodecusto: null,
        colaborador: null,
        dataInicialS: '',
        dataFinalS: '',
    };

    let emptyBatida = {
        id: null,
        horario: '',
        dataS: '',
        motivo: '',
        pis: '',
        ocorrencia: '',
    };

    let emptyModelAjustes = {
        operacao: 'I',
        motivo: null,
        batidasIncluidas: [],
        batidasDesconsideradas: [],
        novoHorario: '',
        novaIdBatida: 1,
    }

    let emptyModelRedirecionamento = {
        operacao: 0,
        batida: null,
    }

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [modelAjustes, setModelAjustes] = useState(emptyModelAjustes);
    const [modelRedirecionamento, setModelRedirecionamento] = useState(emptyModelRedirecionamento);
    const [submitted, setSubmitted] = useState(false);
    const [autoFilteredValueEmpresa, setAutoFilteredValueEmpresa] = useState([]);
    const [setAutoFilteredValueDepartamento] = useState([]);
    const [setAutoFilteredValueCentrodeCusto] = useState([]);
    const [autoFilteredValueColaborador, setAutoFilteredValueColaborador] = useState([]);
    const [autoValueEmpresa, setAutoValueEmpresa] = useState(null);
    const [autoValueDepartamento, setAutoValueDepartamento] = useState(null);
    const [autoValueCentrodecusto, setAutoValueCentrodecusto] = useState(null);
    const [autoValueColaborador, setAutoValueColaborador] = useState(null);
    const [autoValueMotivos, setAutoValueMotivos] = useState(null);
    const [dadosTratamento, setDadosTratamento] = useState([]);
    const [loading1, setLoading1] = useState(false);
    const [horasEsperadas, setHorasEsperadas] = useState("00:00");
    const [horasTrabalhadas, setHorasTrabalhadas] = useState("00:00");
    const [horasNaoTrabalhadas, setHorasNaoTrabalhadas] = useState("00:00");
    const [justificativas, setJustificativas] = useState('0');
    const [faltas, setFaltas] = useState('0');
    const [escalasPeriodo, setEscalasPeriodo] = useState('0');
    const [feriados, setFeriados] = useState('0');
    const [descontoDSR, setDescontoDSR] = useState('0');
    const [bhCredito, setBhCredito] = useState("00:00");
    const [bhDebito, setBhDebito] = useState("00:00");
    const [bhSaldo, setBhSaldo] = useState("00:00");
    const [adicionalNoturno, setAdicionalNoturno] = useState([]);
    const [horasExtrasDiurnas, setHorasExtrasDiurnas] = useState([]);
    const [horasExtrasNoturnas, setHorasExtrasNoturnas] = useState([]);
    const [quantBatidas, setQuantBatidas] = useState(6);
    const [linhaSelecionada, setLinhaSelecionada] = useState('');
    const [listaBatidas, setListaBatidas] = useState([]);
    const [batidaSelecionada, setBatidaSelecionada] = useState(null);
    const [ajustesDialog, setAjustesDialog] = useState(false);
    const [redirecionamentoDialog, setRedirecionamentoDialog] = useState(false);
    const [saldoHorasDialog, setSaldoHorasDialog] = useState(false);
    const [adicionalNoturnoDialog, setAdicionalNoturnoDialog] = useState(false);
    const [submittedAjustes, setSubmittedAjustes] = useState(false);
    const [visibilidade, setVisibilidade] = useState(true);
    const [detalhesHorasExtrasCabecalhoDiurnas, setDetalhesHorasExtrasCabecalhoDiurnas] = useState([]);
    const [detalhesHorasExtrasLinhasDiurnas, setDetalhesHorasExtrasLinhasDiurnas] = useState([]);
    const [detalhesHorasExtrasCabecalhoNoturnas, setDetalhesHorasExtrasCabecalhoNoturnas] = useState([]);
    const [detalhesHorasExtrasLinhasNoturnas, setDetalhesHorasExtrasLinhasNoturnas] = useState([]);
    const [detalhesAdicionalNoturnoCabecalho, setDetalhesAdicionalNoturnoCabecalho] = useState([]);
    const [detalhesAdicionalNoturnoLinhas, setDetalhesAdicionalNoturnoLinhas] = useState([]);

    const message = useRef();
    const messagePrincipal = useRef();

    const operacoes = [
        { label: 'I- INCLUIR', value: 'I' },
        { label: 'D - DESCONSIDERAR', value: 'D' },
        { label: 'P - PRÉ-ASSINALAR', value: 'P' }
    ];

    const operacoesRedirecionamento = [
        { label: 'Mover para dia anterior', value: 0 },
        { label: 'Mover para próximo dia', value: 1 },
        { label: 'Desfazer redirecionamento', value: 2 }
    ]

    useEffect(() => {
        const buscaEmpresas = () => {
            api
                .get("/empresas")
                .then(response => {
                    setAutoValueEmpresa(response.data)
                    model.empresa = response.data[0];
                    buscaColaboradores(response.data[0]);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaDepartamentos = () => {
            api
                .get("/departamentos")
                .then(response => setAutoValueDepartamento(response.data))
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaCentrodeCustos = () => {
            api
                .get("/centrodecusto")
                .then(response => setAutoValueCentrodecusto(response.data))
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
        buscaEmpresas();
        buscaDepartamentos();
        buscaCentrodeCustos();
    }, [buscaColaboradores]);

    const procurarEmpresas = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueEmpresa([...autoValueEmpresa]);
                }
                else {
                    setAutoFilteredValueEmpresa(autoValueEmpresa.filter((em) => {
                        return em.fantasia.toLowerCase().startsWith(event.query.toLowerCase());

                    }));
                }
            } catch (e) {
            }

        }, 250);
    };

    const buscaColaboradores = (empresa) => {
        // .get("empresas/" + empresa.id + "/colaboradores")

        let dep = 0;
        let cen = 0;
        if (model.departamento) {
            dep = model.departamento.id;
        }

        if (model.centrodecusto) {
            cen = model.centrodecusto.id;
        }
        try {
            api
                .get("colaboradores/?empresa=" + empresa.id + "&departamento=" + dep + "&centrodecusto=" + cen)
                .then(response => {
                    setAutoValueColaborador(response.data);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        } catch (e) {
        }

    }

    // const procurarDepartamentos = (event) => {
    //     setTimeout(() => {
    //         if (!event.query.trim().length) {
    //             setAutoFilteredValueDepartamento([...autoValueDepartamento]);
    //         }
    //         else {
    //             setAutoFilteredValueDepartamento(autoValueDepartamento.filter((em) => {
    //                 return em.descricao.toLowerCase().startsWith(event.query.toLowerCase());
    //             }));
    //         }
    //     }, 250);
    // };

    // const procurarCentrodeCustos = (event) => {
    //     setTimeout(() => {
    //         if (!event.query.trim().length) {
    //             setAutoFilteredValueCentrodeCusto([...autoValueCentrodecusto]);
    //         }
    //         else {
    //             setAutoFilteredValueCentrodeCusto(autoValueCentrodecusto.filter((em) => {
    //                 return em.descricao.toLowerCase().startsWith(event.query.toLowerCase());
    //             }));
    //         }
    //     }, 250);
    // };

    const procurarColaboradores = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueColaborador([...autoValueColaborador]);
                }
                else {
                    setAutoFilteredValueColaborador(autoValueColaborador.filter((em) => {
                        return em.nome.toLowerCase().startsWith(event.query.toLowerCase());
                    }));
                }
            } catch (e) {
            }
        }, 250);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <BTListarBatidas setSubmitted={setSubmitted} listar={listar} emptyModel={emptyModel} />
                    {/* <BTNovo setSubmitted={setSubmitted} setModel={setModel} emptyModel={emptyModel} /> */}
                    {/* <Messages ref={message} /> */}
                </div>
            </React.Fragment>
        )
    }

    const onInputChange = (e, descricao) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
        if (descricao === 'empresa') {
            buscaColaboradores(_model.empresa);
        }
    }

    const onInputChangeAjustes = (e, descricao) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...modelAjustes };
        _model[`${descricao}`] = val;
        setModelAjustes(_model);
        if (descricao === "operacao") {
            if (_model.operacao === "I" || _model.operacao === "P") {
                setVisibilidade(true);
            } else {
                addMessage("warn", Mensagens.SELECIONEBATIDASDESCONSIDERAR);
                setVisibilidade(false);
            }
        }
    }

    const onInputChangeRedirecionamento = (e, descricao) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...modelRedirecionamento };
        _model[`${descricao}`] = val;
        setModelRedirecionamento(_model);
    }

    const listar = () => {
        if (model.dataInicialS.trim() && model.dataFinalS.trim() && model.colaborador && model.empresa) {
            setLoading1(true);
            api
                .post("/tratamentos/empresas/" + model.empresa.id + "/colaboradores/" + model.colaborador.id, {
                    empresa: model.empresa,
                    colaborador: model.colaborador,
                    dataInicialS: model.dataInicialS,
                    dataFinalS: model.dataFinalS,
                })
                .then(response => {
                    setDadosTratamento(response.data.linhastable);
                    // setDadosTratamento(response.data);
                    setHorasEsperadas(response.data.apontamento.horasNormaisS);
                    setHorasTrabalhadas(response.data.apontamento.horasTrabalhadasS);
                    setHorasNaoTrabalhadas(response.data.apontamento.horasNaoTrabalhadasS);
                    setFaltas(response.data.apontamento.faltas);
                    setDescontoDSR(response.data.apontamento.descontodsr);
                    setEscalasPeriodo(response.data.apontamento.mudancadeescalas);
                    setJustificativas(response.data.apontamento.justificativasfaltas);
                    setFeriados(response.data.apontamento.quantidadeFeriados);
                    setBhCredito(response.data.apontamento.bh_creditoS);
                    setBhDebito(response.data.apontamento.bh_debitoS);
                    setBhSaldo(response.data.apontamento.bh_saldo);
                    setAdicionalNoturno(response.data.apontamento.listaAdicionalNoturno);
                    setHorasExtrasDiurnas(response.data.apontamento.listaHorasExtrasDiurnas);
                    setHorasExtrasNoturnas(response.data.apontamento.listaHorasExtrasNoturnas);
                    // setBatidas(response.data.linhastable[1].batidas);
                    setQuantBatidas(response.data.apontamento.quantidadeBatidas);

                    setLoading1(false);

                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    const addMessagePrincipal = (sv, cn) => {
        messagePrincipal.current.show({ severity: sv, content: cn });
    };

    const HEDdinamico = horasExtrasDiurnas.map((ob) => {
        return <div key={ob.id} className="col-12 mb-0">
            <label htmlFor="he" className="mb-2 md:col-1 md:mb-0">{
                ob.rotulo + " (" + ob.valor + "%): "
            }</label>
            <label htmlFor="he" className="mb-2 md:col-1 md:mb-0">{
                ob.valorHorasExtrasTrab
            }</label>
        </div>;
    });

    const HENdinamico = horasExtrasNoturnas.map((ob) => {
        return <div key={ob.id} className="col-12 mb-0">
            <label htmlFor="he" className="mb-2 md:col-1 md:mb-0">{
                ob.rotulo + " (" + ob.valor + "%): "
            }</label>
            <label htmlFor="he" className="mb-2 md:col-1 md:mb-0">{
                ob.valorHorasExtrasTrab
            }</label>
        </div>;
    });

    const ADdinamico = adicionalNoturno.map((ob) => {
        return <div key={ob.id} className="col-12 mb-0">
            <label htmlFor="ad" className="mb-2 md:col-1 md:mb-0">{
                ob.rotulo + " (" + ob.valor + "%): "
            }</label>
            <label htmlFor="ad" className="mb-2 md:col-1 md:mb-0">{
                ob.horasTotaisTrabAdicionalS
            }</label>
        </div>;
    });

    const onCellEditComplete = (e) => {
        let { rowData, newValue, field, originalEvent: event } = e;
        if (newValue.trim().length > 0)
            rowData[field] = newValue;
        else
            event.preventDefault();
    }

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const cellEditor = (options) => {
        return textEditor(options);
    }

    const hideAjustesDialog = () => {
        setSubmittedAjustes(false);
        setAjustesDialog(false);
    }

    const hideRedirecionamentoDialog = () => {
        setRedirecionamentoDialog(false);
    }

    const hideSaldoHorasDialog = () => {
        setSaldoHorasDialog(false);
    }

    const hideAdicionalNoturnoDialog = () => {
        setAdicionalNoturnoDialog(false);
    }

    const addListaOrdenado = (batida, lista) => {
        let adicionado = false;

        for (let i = 0; i < lista.length; i++) {
            if (comparaBatidas(batida.horario, lista[i].horario)) {
                lista.splice(i, 0, batida);
                adicionado = true;
                break;
            }
        }

        if (!adicionado) {
            lista.push(batida);
        }
    }

    const comparaBatidas = (batida1, batida2) => {
        let ret = false;
        let b1 = batida1.split(":");
        let b2 = batida2.split(":");

        if (parseInt(b1[0]) < parseInt(b2[0])) {
            ret = true;
        } else if (parseInt(b1[0]) === parseInt(b2[0])) {
            if (parseInt(b1[1]) < parseInt(b2[1])) {
                ret = true;
            }
        }
        return ret;
    }

    const posicaoBatida = (batida, lista) => {
        let ret = 0;
        lista.forEach((batidaAtual, indice) => {
            if (batidaAtual.id === batida.id) {
                ret = indice;
            }
        });

        return ret;

    }

    const removeBatida = (e) => {
        if (modelAjustes.motivo !== null) {
            if (modelAjustes.operacao === "D") {

                if (!verificaIdBatida(modelAjustes.batidasIncluidas, e.value.id)) {
                    modelAjustes.batidasDesconsideradas.push(e.value);
                } else {
                    modelAjustes.batidasIncluidas.splice(posicaoBatida(e.value, modelAjustes.batidasIncluidas), 1);
                }

                let lista = listaBatidas.slice();

                lista.splice(posicaoBatida(e.value, lista), 1);

                setListaBatidas(lista);

                // console.log(lista);
            } else {
                addMessage("error", Mensagens.SELECIONEOPERACAODESCONSIDERAR);
            }
        } else {
            addMessage("error", Mensagens.INCLUAMOTIVO);
        }

    }
    const verificaIdBatida = (lista, idBatida) => {
        let existe = false;
        for (let index = 0; index < lista.length; index++) {
            const element = lista[index];
            if (element.id === idBatida) {
                existe = true;
                break;
            }
        }
        return existe;
    }


    const geraIdBatida = () => {
        let existe = verificaIdBatida(listaBatidas, modelAjustes.novaIdBatida);
        while (existe) {
            modelAjustes.novaIdBatida++;
            existe = verificaIdBatida(listaBatidas, modelAjustes.novaIdBatida);
        }
        return modelAjustes.novaIdBatida;
    }

    function checkObject(obj) {
        return typeof obj === 'object' && obj !== null;
    }

    const addBatida = () => {
        if (modelAjustes.motivo !== null) {
            if (modelAjustes.novoHorario.trim()) {
                if (modelAjustes.operacao === "I" || modelAjustes.operacao === "P") {
                    let _batida = { ...emptyBatida };
                    _batida.horario = modelAjustes.novoHorario;
                    _batida.dataS = linhaSelecionada.data;
                    _batida.ocorrencia = modelAjustes.operacao;

                    if (checkObject(modelAjustes.motivo)) {
                        _batida.motivo = modelAjustes.motivo.descricao;
                    } else {
                        _batida.motivo = modelAjustes.motivo;
                    }

                    _batida.pis = linhaSelecionada.pis;

                    _batida.id = geraIdBatida();

                    let lista = listaBatidas.slice();

                    addListaOrdenado(_batida, lista);

                    setListaBatidas(lista);

                    modelAjustes.batidasIncluidas.push(_batida);

                    modelAjustes.novoHorario = '';

                } else {
                    addMessage("error", Mensagens.SELECIONEOPERACAO);
                }
            } else {
                addMessage("error", Mensagens.INCLUAHORARIO);
            }
        } else {
            addMessage("error", Mensagens.INCLUAMOTIVO);
        }
    };

    const aplicarAjustes = () => {
        if (modelAjustes.batidasIncluidas != 0 || modelAjustes.batidasDesconsideradas != 0) {
            ajustar();
        } else {
            setSubmittedAjustes(true);
            setAjustesDialog(false);
        }
    }

    const aplicarRedirecionamento = () => {
        // if (modelAjustes.batidasIncluidas != 0 || modelAjustes.batidasDesconsideradas != 0) {
        //     ajustar();
        // } else {
        redirecionar();
        // setRedirecionamentoDialog(false);
        // }
    }

    const ajustesDialogFooter = (
        <React.Fragment>
            <Button label="Aplicar" icon="pi pi-check" className="p-button-success mr-2" onClick={aplicarAjustes} />
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideAjustesDialog} />
        </React.Fragment>
    );

    const redirecionamentoDialogFooter = (
        <React.Fragment>
            <Button label="Aplicar" icon="pi pi-check" className="p-button-success mr-2" onClick={aplicarRedirecionamento} />
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideRedirecionamentoDialog} />
        </React.Fragment>
    );

    const buscarMotivos = () => {
        api
            .get("/motivos")
            .then(response => {
                setAutoValueMotivos(response.data)
            })
            .catch(() => {
                addMessage("error", Mensagens.ERRO);
            })
    }

    const ajustar = () => {
        // console.log("ajustar...");
        api
            .put("/tratamentos/ajustes", {
                listaBatidasIncluidas: modelAjustes.batidasIncluidas,
                listaBatidasDesconsideradas: modelAjustes.batidasDesconsideradas
            })
            .then(() => {
                listar();
                // addMessage("success", Mensagens.OPERACAO_SUCESSO);
                setSubmittedAjustes(true);
                setAjustesDialog(false);
            })
            .catch(error => {
                // addMessage("error", Mensagens.ERRO);
            })
    }

    // const dynamicColumns = columns.map((col, i) => {
    //     return <Column key={col.field} field={col.field} header={col.header} />;
    // });


    const redirecionar = () => {
        api
            .put("/tratamentos/redirecionamento", {
                operacao: modelRedirecionamento.operacao,
                batida: batidaSelecionada
            })
            .then(() => {
                listar();
                setBatidaSelecionada(null);
                setRedirecionamentoDialog(false);
                // addMessage("success", Mensagens.OPERACAO_SUCESSO);
            })
            .catch(() => {
                // addMessage("error", Mensagens.ERRO);
            })
    }

    const verificarFechamento = async (idEmpresa, idColaborador, data) =>{
        try {
            const response = await api
                .get("/verificarFechamento?empresa=" + idEmpresa + "&colaborador=" + idColaborador + "&data=" + data);
                return response.data;
        } catch {
            return false;
        }
    }

    const openAjustes = async (e) => {
      const isFechamentoVerificado = await verificarFechamento(model.empresa.id, model.colaborador.id, e.rowData.data);
        if(isFechamentoVerificado){
            buscarMotivos();
            setSubmittedAjustes(false);
            setAjustesDialog(true);
            setModelAjustes(emptyModelAjustes);
            // setLinhaSelecionada(e.value);
            setLinhaSelecionada(e.rowData);
            setListaBatidas(e.value.batidas);
            setListaBatidas(e.rowData.batidas);
            setVisibilidade(true);
        }else{
            // alert("Mensagens.MENSAGEM_PONTOFECHADOEMBANCODEHORAS")
            addMessagePrincipal("error", Mensagens.MENSAGEM_PONTOFECHADOEMBANCODEHORAS);
        }
    }

    const openRedirecionamento = (e) => {
        // if (e.rowData.tipoJornada !== 4) { //se nao for jornada regular
        if (e.value) {
            setRedirecionamentoDialog(true);
            setLinhaSelecionada(e.rowData);
            setBatidaSelecionada(e.rowData.batidas[e.cellIndex - 2]);
        }
        // }
    }

    const openSaldoHoras = (e) => {
        if (e.value) {
            setSaldoHorasDialog(true);
            setLinhaSelecionada(e.rowData);
            setDetalhesHorasExtrasLinhasDiurnas(e.rowData.detalhesHorasExtrasLinhasDiurnas);
            setDetalhesHorasExtrasCabecalhoDiurnas(e.rowData.detalhesHorasExtrasCabecalhoDiurnas);
            setDetalhesHorasExtrasLinhasNoturnas(e.rowData.detalhesHorasExtrasLinhasNoturnas);
            setDetalhesHorasExtrasCabecalhoNoturnas(e.rowData.detalhesHorasExtrasCabecalhoNoturnas);
        }
    }

    const openAdicionalNoturno = (e) => {
        if (e.value) {
            setAdicionalNoturnoDialog(true);
            setLinhaSelecionada(e.rowData);
            setDetalhesAdicionalNoturnoLinhas(e.rowData.detalhesAdicionalNoturnoLinhas);
            setDetalhesAdicionalNoturnoCabecalho(e.rowData.detalhesAdicionalNoturnoCabecalho);
        }
    }

    const handleCell = (e) => {
        switch (e.field) {
            case 'data':
                openAjustes(e);
                break;
            case 'diasemana':
                openAjustes(e);
                break;
            case 'bat1':
                openRedirecionamento(e);
                break;
            case 'bat2':
                openRedirecionamento(e);
                break;
            case 'bat3':
                openRedirecionamento(e);
                break;
            case 'bat4':
                openRedirecionamento(e);
                break;
            case 'bat5':
                openRedirecionamento(e);
                break;
            case 'bat6':
                openRedirecionamento(e);
                break;
            case 'horasextras':
                openSaldoHoras(e);
                break;
            case 'adicionalnoturno':
                openAdicionalNoturno(e);
                break;
            default:
                break;
        }


    };

    const rowClass = (data) => {
        return {
            'row-alerta': data.erro === 1,
            'row-movimentacaobh': data.movimentobh === 1,
            'row-justificativa': data.justificativa === 1,
            'row-dsr': data.dsr === 1,
            'row-feriado': data.feriado === 1,
            'row-escala': data.escala === 1
        }
    }

    const columnsDiurna = detalhesHorasExtrasCabecalhoDiurnas.map((ob, i) => {
        return <Column key={ob.descricao} field={"faixa" + (i + 1)} header={ob.descricao} />;
    });

    const columnsNoturna = detalhesHorasExtrasCabecalhoNoturnas.map((ob, i) => {
        return <Column key={ob.descricao} field={"faixa" + (i + 1)} header={ob.descricao} />;
    });

    const columnsAdicionalNoturno = detalhesAdicionalNoturnoCabecalho.map((ob, i) => {
        return <Column key={ob.descricao} field={"faixa" + (i + 1)} header={ob.descricao} />;
    });

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="empresa" className="col-12 md:col-1 md:mb-0">Empresa*</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="empresa" dropdown value={model.empresa} onChange={(e) => onInputChange(e, 'empresa')} suggestions={autoFilteredValueEmpresa} completeMethod={procurarEmpresas} field="fantasia" required className={classNames({ 'p-invalid': submitted && !model.empresa })} />
                            {submitted && !model.empresa && <small className="p-invalid">Empresa Obrigatória</small>}
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2" >
                        <label htmlFor="colaborador" className="col-12 md:col-1 md:mb-0">Colaborador*</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete id="colaborador" dropdown value={model.colaborador} onChange={(e) => onInputChange(e, 'colaborador')} suggestions={autoFilteredValueColaborador} completeMethod={procurarColaboradores} field="nome" required className={classNames({ 'p-invalid': submitted && !model.colaborador })} />
                            {submitted && !model.colaborador && <small className="p-invalid">Colaborador Obrigatório</small>}
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="datainicial" className="col-12 md:col-1 md:mt-2">Data Inicial*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputMask id='datainicial' mask='99/99/9999' value={model.dataInicialS} onChange={e => onInputChange(e, 'dataInicialS')} required className={classNames({ 'p-invalid': submitted && !model.dataInicialS })} />
                            {submitted && !model.dataInicialS && <small className="p-invalid">Data Inicial Obrigatória</small>}
                        </div>

                        <label htmlFor="dataFinal" className="col-12 md:col-1 md:mt-2">Data Final*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputMask id='dataFinal' mask='99/99/9999' value={model.dataFinalS} onChange={e => onInputChange(e, 'dataFinalS')} required className={classNames({ 'p-invalid': submitted && !model.dataFinalS })} />
                            {submitted && !model.dataFinalS && <small className="p-invalid">Data Final Obrigatória</small>}
                        </div>
                    </div>

                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                    <Messages className="mb-0 md-col-12" ref={messagePrincipal} />

                    <div className="datatable-style">
                        <div className="col-12">
                            {/* onSelectionChange={e => openNew(e)} */}
                            <DataTable value={dadosTratamento} rowClassName={rowClass} selection={linhaSelecionada} cellSelection={true} onCellSelect={handleCell} size="small" selectionMode="single" responsiveLayout='scroll'
                                paginator rows={35} loading={loading1} emptyMessage=" ">

                                <Column field="data" header="Data"></Column>
                                <Column field="diasemana" header="Dia"></Column>

                                <Column field="bat1" header="B1" style={{ display: quantBatidas >= 1 ? ' ' : 'none' }}></Column>
                                <Column field="bat2" header="B2" style={{ display: quantBatidas >= 2 ? ' ' : 'none' }}></Column>
                                <Column field="bat3" header="B3" style={{ display: quantBatidas >= 3 ? ' ' : 'none' }}></Column>
                                <Column field="bat4" header="B4" style={{ display: quantBatidas >= 4 ? ' ' : 'none' }}></Column>
                                <Column field="bat5" header="B5" style={{ display: quantBatidas >= 5 ? ' ' : 'none' }}></Column>
                                <Column field="bat6" header="B6" style={{ display: quantBatidas >= 6 ? ' ' : 'none' }}></Column>
                                <Column field="bat7" header="B7" style={{ display: quantBatidas >= 7 ? ' ' : 'none' }}></Column>
                                <Column field="bat8" header="B8" style={{ display: quantBatidas >= 8 ? ' ' : 'none' }}></Column>
                                <Column field="horastrab" header="Horas Trabalhadas"></Column>
                                <Column field="horasextras" header="Saldo de Horas"></Column>
                                <Column field="adicionalnoturno" header="Adicional Noturno"></Column>

                            </DataTable>
                        </div>
                    </div>

                    <div className="col-12">
                        <Panel header="Informações do Período" toggleable>

                            <div className="grid">
                                <div className="col-5 flex align-items-center justify-content-center">
                                    <div className="p-fluid">
                                        <div className="col-12">
                                            <label htmlFor="horasesperadasL" className="mb-2 md:col-1 md:mb-0">Horas Esperadas:</label>
                                            <label htmlFor="horasesperadas" className="mb-2 md:col-1 md:mb-0">{horasEsperadas}</label>
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="horastrabalhadasL" className="mb-2 md:col-1 md:mb-0">Horas Trabalhadas:</label>
                                            <label htmlFor="horastrabalhadas" className="mb-2 md:col-1 md:mb-0">{horasTrabalhadas}</label>
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="horasnaotrabalhadasL" className="mb-2 md:col-1 md:mb-0">Horas Não Trabalhadas:</label>
                                            <label htmlFor="horasnaotrabalhadas" className="mb-2 md:col-1 md:mb-0">{horasNaoTrabalhadas}</label>
                                        </div>

                                        <div className="col-12">
                                            <label htmlFor="justificativas" className="mb-2 md:col-1 md:mb-0">Justificativas:</label>
                                            <label htmlFor="justificativas" className="mb-2 md:col-1 md:mb-0">{justificativas}</label>
                                        </div>

                                        <div className="col-12">
                                            <label htmlFor="faltas" className="mb-2 md:col-1 md:mb-0">Faltas:</label>
                                            <label htmlFor="faltas" className="mb-2 md:col-1 md:mb-0">{faltas}</label>
                                        </div>

                                        <div className="col-12">
                                            <label htmlFor="horasnaotrabalhadasL" className="mb-2 md:col-1 md:mb-0">Desconto de DSR:</label>
                                            <label htmlFor="horasnaotrabalhadas" className="mb-2 md:col-1 md:mb-0">{descontoDSR}</label>
                                        </div>

                                        <div className="col-12">
                                            <label htmlFor="feriados" className="mb-2 md:col-1 md:mb-0">Feriados:</label>
                                            <label htmlFor="feriados" className="mb-2 md:col-1 md:mb-0">{feriados}</label>
                                        </div>

                                        <div className="col-12">
                                            <label htmlFor="escalas" className="mb-2 md:col-1 md:mb-0">Escalas no Período:</label>
                                            <label htmlFor="escalas" className="mb-2 md:col-1 md:mb-0">{escalasPeriodo}</label>
                                        </div>

                                        <Divider layout="horizontal" align="center">
                                            <span className="p-tag">Banco de Horas</span>
                                        </Divider>
                                        <div className="col-12">
                                            <label htmlFor="bhcredito" className="mb-2 md:col-1 md:mb-0">BH Crédito:</label>
                                            <label htmlFor="bhcredito" className="mb-2 md:col-1 md:mb-0">{bhCredito}</label>
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="bhdebito" className="mb-2 md:col-1 md:mb-0">BH Débito:</label>
                                            <label htmlFor="bhdebito" className="mb-2 md:col-1 md:mb-0">{bhDebito}</label>
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="bhsaldo" className="mb-2 md:col-1 md:mb-0">BH Saldo:</label>
                                            <label htmlFor="bhsaldo" className="mb-2 md:col-1 md:mb-0">{bhSaldo}</label>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-1">
                                    <Divider layout="vertical">
                                        <b>E</b>
                                    </Divider>
                                </div>
                                <div className="col-5 flex align-items-center justify-content-center">
                                    <div className="p-fluid">
                                        <Divider layout="horizontal" align="center">
                                            <span className="p-tag">Adicional Noturno</span>
                                        </Divider>
                                        {ADdinamico}
                                        <Divider layout="horizontal" align="center">
                                            <span className="p-tag">Horas Extras Diurnas</span>
                                        </Divider>
                                        {HEDdinamico}
                                        <Divider layout="horizontal" align="center">
                                            <span className="p-tag">Horas Extras Noturnas</span>
                                        </Divider>
                                        {HENdinamico}
                                    </div>
                                </div>

                            </div>
                        </Panel>
                    </div>
                </div>
            </div>

            <Dialog visible={ajustesDialog} style={{ width: '450px' }} header="Ajustes" modal className="p-fluid" footer={ajustesDialogFooter} onHide={hideAjustesDialog}>
                <div className="field grid mb-2">
                    <div className="col-12">
                        <label htmlFor="data" className="md-0">{linhaSelecionada.data + " " + linhaSelecionada.diasemana?.toUpperCase()} </label>
                    </div>
                    <Panel className="md:col-10" header="Informações">

                        <div className="field grid p-fluid mb-2">
                            <label htmlFor="operacao" className="col-12 md:col-3 md:mb-0">Operação*</label>
                            <div className="col-12 md:col-9">
                                <Dropdown value={modelAjustes.operacao} options={operacoes} onChange={(e) => onInputChangeAjustes(e, "operacao")} placeholder="Selecione a Operação" />
                            </div>
                        </div>

                        <div className="field grid p-fluid mb-2">
                            <label htmlFor="motivo" className="col-12 md:col-3 md:mb-0">Motivo*</label>
                            <div className="col-12 md:col-9">
                                <Dropdown optionLabel="descricao" value={modelAjustes.motivo} options={autoValueMotivos} onChange={(e) => onInputChangeAjustes(e, "motivo")} editable />
                            </div>
                        </div>

                        <div className="p-fluid formgrid grid">
                            <label htmlFor="horario" style={{ display: visibilidade ? ' ' : 'none' }} className="col-12 md:col-3 md:mt-2">Novo*</label>
                            <div className="field col-12 md:col-3 mb-2">
                                <InputMask id="horario" mask="99:99" value={modelAjustes.novoHorario} style={{ display: visibilidade ? ' ' : 'none' }} onChange={(e) => onInputChangeAjustes(e, "novoHorario")} />
                            </div>
                            <Button icon="pi pi-plus" style={{ display: visibilidade ? ' ' : 'none' }} className="p-button-text" onClick={addBatida} />
                        </div>

                    </Panel>
                    <ListBox optionLabel="horario" className="md:col-2" value={listaBatidas} options={listaBatidas} onChange={(e) => removeBatida(e)} />
                    <div className="col-12 md:col-12 mt-2">
                        <Panel header="Observações" toggleable >
                            <label htmlFor="batidasdes" className="md-0" style={{ color: 'red' }}>{linhaSelecionada.erro === 1 ? 'Faltam batidas para completar pares' : ''} </label>
                            <br />
                            <label htmlFor="justificativa" className="md-0">{linhaSelecionada.descricaoJustificativa} </label>
                            <br />
                            <label htmlFor="feriado" className="md-0">{linhaSelecionada.descricaoFeriado} </label>
                            <br />
                            <label htmlFor="movimentobh" className="md-0">{linhaSelecionada.descricaoMovimentoBH} </label>
                            <br />
                            <label htmlFor="evetno" className="md-0">{linhaSelecionada.descricaoEvento} </label>
                            <br />
                            <label htmlFor="compensacaosemanal" className="md-0">{linhaSelecionada.descricaoCompensacaoSemanal} </label>
                        </Panel>
                    </div>
                    {/* </div> */}

                </div>

                <Messages className="mb-0 md-col-12" ref={message} />

            </Dialog>

            <Dialog visible={redirecionamentoDialog} style={{ width: '450px' }} header="Redirecionamento" modal className="p-fluid" onHide={hideRedirecionamentoDialog}>
                <div className="field grid mb-2">
                    <div className="col-12 md:col-12">
                        <label htmlFor="data" className="md-0">{'Data: ' + linhaSelecionada.data} </label>
                        <label htmlFor="horario" className="md-0">{'Horário: ' + batidaSelecionada?.horario} </label>
                    </div>
                    <Panel className="md:col-12" header="Detalhes">
                        <div className="field grid p-fluid mb-2">
                            <label htmlFor="operacao" className="col-12 md:col-3 md:mb-0">Operação*</label>
                            <div className="col-12 md:col-9">
                                <Dropdown value={modelRedirecionamento.operacao} options={operacoesRedirecionamento} onChange={(e) => onInputChangeRedirecionamento(e, "operacao")} placeholder="Selecione a Operação" />
                            </div>
                        </div>
                    </Panel>
                </div>
            </Dialog>

            <Dialog visible={saldoHorasDialog} style={{ width: '450px' }} header="Detalhes saldo de horas do dia" modal className="p-fluid" onHide={hideSaldoHorasDialog}>
                <div className="field grid mb-2">
                    <div className="col-12 md:col-12">
                        <label htmlFor="data" className="md-0">{linhaSelecionada.data + " " + linhaSelecionada.diasemana?.toUpperCase()} </label>
                    </div>
                    <Panel className="md:col-12" header="Resumo de horas">
                        <div className="col-12 md:col-12">
                            <label htmlFor="negativas" className="col-12 md:col-3">Horas Negativas: </label>
                            <label htmlFor="extras" className="col-12 md:col-3">{linhaSelecionada.horasnegativas}</label>
                            <label htmlFor="negativas" className="col-12 md:col-3">Horas Positivas: </label>
                            <label htmlFor="extras" className="col-12 md:col-3">{linhaSelecionada.horaspositivas}</label>
                        </div>
                    </Panel>
                    <Panel className="md:col-12" header="Detalhes horas extras diurnas">
                        <DataTable value={detalhesHorasExtrasLinhasDiurnas} responsiveLayout="scroll" emptyMessage="Sem informações">
                            {columnsDiurna}
                        </DataTable>
                    </Panel>
                    <Panel className="md:col-12" header="Detalhes horas extras noturnas">
                        <DataTable value={detalhesHorasExtrasLinhasNoturnas} responsiveLayout="scroll" emptyMessage="Sem informações">
                            {columnsNoturna}
                        </DataTable>
                    </Panel>
                    <div className="col-12 md:col-12">
                        <label htmlFor="data" className="md-0" style={{ color: "red" }}>OBS: As horas extras noturnas estão convertidas com base no fator de redução noturno</label>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={adicionalNoturnoDialog} style={{ width: '450px' }} header="Detalhes adicional noturno do dia" modal className="p-fluid" onHide={hideAdicionalNoturnoDialog}>
                <div className="field grid mb-2">
                    <div className="col-12 md:col-12">
                        <label htmlFor="data" className="md-0">{linhaSelecionada.data + " " + linhaSelecionada.diasemana?.toUpperCase()} </label>
                    </div>
                    <Panel className="md:col-12" header="Resumo adicional noturno">
                        <div className="col-12 md:col-12">
                            <label htmlFor="negativas" className="col-12 md:col-3">Total: </label>
                            <label htmlFor="extras" className="col-12 md:col-3">{linhaSelecionada.adicionalnoturno}</label>
                        </div>
                    </Panel>
                    <Panel className="md:col-12" header="Detalhes adicional noturno">
                        <DataTable value={detalhesAdicionalNoturnoLinhas} responsiveLayout="scroll" emptyMessage="Sem informações">
                            {columnsAdicionalNoturno}
                        </DataTable>
                    </Panel>
                    <div className="col-12 md:col-12">
                        <label htmlFor="data" className="md-0" style={{ color: "red" }}>OBS: O adicional noturno está convertido com base no fator de redução noturno</label>
                    </div>
                </div>
            </Dialog>

        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Tratamentos, comparisonFn);
