import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Messages } from 'primereact/messages';
import { MultiSelect } from 'primereact/multiselect';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { RadioButton } from 'primereact/radiobutton';
import { Fieldset } from 'primereact/fieldset';
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import Mensagens from '../globais/Mensagens';
import BTInativar from '../components/BTInativar';
import BTNovo from '../components/BTNovo';
import BTGravar from '../components/BTGravar';
import ConsultaGenerica from '../components/consultas/ConsultaGenerica';


const Jornadas = () => {

    let emptyModel = {
        id: null,
        empresas: null,
        tipoJornada: 0,
        toleranciaMinutos: '00:00',
        horassemanais: '00:00',
        diasTrab: 0,
        diasDSR: 0,
        nome: '',
        listaDiaSemana: [],
        valoresTabela: [{ fixo: 'Entrada 1', domingo: '', segunda: '', terca: '', quarta: '', quinta: '', sexta: '', sabado: '' },
        { fixo: 'Saída 1', domingo: '', segunda: '', terca: '', quarta: '', quinta: '', sexta: '', sabado: '' },
        { fixo: 'Entrada 2', domingo: '', segunda: '', terca: '', quarta: '', quinta: '', sexta: '', sabado: '' },
        { fixo: 'Saída 2', domingo: '', segunda: '', terca: '', quarta: '', quinta: '', sexta: '', sabado: '' },
        { fixo: 'Entrada 3', domingo: '', segunda: '', terca: '', quarta: '', quinta: '', sexta: '', sabado: '' },
        { fixo: 'Saída 3', domingo: '', segunda: '', terca: '', quarta: '', quinta: '', sexta: '', sabado: '' },],
    };

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [lista, setLista] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [multiselectValues, setMultiSelectValues] = useState(null);
    const [valoresDomingo] = useState([]);
    const [valoresSegunda] = useState([]);
    const [valoresTerca] = useState([]);
    const [valoresQuarta] = useState([]);
    const [valoresQuinta] = useState([]);
    const [valoresSexta] = useState([]);
    const [valoresSabado] = useState([]);
    const message = useRef();

    const colunas = [
        { field: 'nome', header: 'Nome', headerStyle: { minWidth: '15rem' } },
    ];

    const organizaLista = () => {
        valoresDomingo.splice(0, valoresDomingo.length)
        valoresSegunda.splice(0, valoresSegunda.length)
        valoresTerca.splice(0, valoresTerca.length)
        valoresQuarta.splice(0, valoresQuarta.length)
        valoresQuinta.splice(0, valoresQuinta.length)
        valoresSexta.splice(0, valoresSexta.length)
        valoresSabado.splice(0, valoresSabado.length)
        model.listaDiaSemana.splice(0, model.listaDiaSemana.length)

        model.valoresTabela.forEach(coluna => {
            valoresDomingo.push(coluna.domingo);
            valoresSegunda.push(coluna.segunda);
            valoresTerca.push(coluna.terca);
            valoresQuarta.push(coluna.quarta);
            valoresQuinta.push(coluna.quinta);
            valoresSexta.push(coluna.sexta);
            valoresSabado.push(coluna.sabado);
        })

        model.listaDiaSemana.push(valoresDomingo);
        model.listaDiaSemana.push(valoresSegunda);
        model.listaDiaSemana.push(valoresTerca);
        model.listaDiaSemana.push(valoresQuarta);
        model.listaDiaSemana.push(valoresQuinta);
        model.listaDiaSemana.push(valoresSexta);
        model.listaDiaSemana.push(valoresSabado);

    }

    const columns = [
        { field: 'fixo', header: '' },
        { field: 'domingo', header: 'Domingo' },
        { field: 'segunda', header: 'Segunda' },
        { field: 'terca', header: 'Terça' },
        { field: 'quarta', header: 'Quarta' },
        { field: 'quinta', header: 'Quinta' },
        { field: 'sexta', header: 'Sexta' },
        { field: 'sabado', header: 'Sábado' },
    ];


    useEffect(() => {
        const buscaEmpresas = () => {
            api
                .get("/empresas")
                .then(response => {
                    setMultiSelectValues(response.data);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
        buscaEmpresas();
    }, []);

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <BTGravar setSubmitted={setSubmitted} cadastrar={cadastrar} />
                    <BTNovo setSubmitted={setSubmitted} setModel={setModel} emptyModel={emptyModel} />
                    <BTInativar inativar={inativar} />

                    <ConsultaGenerica
                        lista={lista}
                        model={model}
                        setModel={setModel}
                        onConsultar={consultar}
                        toast={toast}
                        colunas={colunas}
                        titulo="Consultar"
                        mensagemSelecao="Jornada Selecionada"
                        camposFiltroGlobal={['nome']}
                        onModelSelecionado={onModelSelecionado}
                        life={3000}
                        largura='450px'
                    />

                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const onModelSelecionado = (model) => {
        return `${model.nome}`;
    };

    const onInputChange = (e, descricao) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
    }

    const onRadioChange = (e, descricao) => {
        const val = e.value || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
    }

    const cadastrar = () => {
        if (model.nome.trim() && model.empresas && model.toleranciaMinutos.trim()
            && model.horassemanais.trim()) {
            if (model.id) {
                addMessage("error", Mensagens.ERRO);
            }
            else {
                organizaLista();
                api
                    .post("/jornadas", {
                        id: model.id,
                        nome: model.nome,
                        toleranciaMinutos: model.toleranciaMinutos,
                        horassemanais: model.horassemanais,
                        tipoJornada: model.tipoJornada,
                        diasTrab: model.diasTrab,
                        diasDSR: model.diasDSR,
                        empresas: model.empresas,
                        listaDiaSemana: model.listaDiaSemana,
                    })
                    .then(response => {
                        setModel(response.data);
                        addMessage("success", Mensagens.CADASTRO_INCLUIR);
                    })
                    .catch((error) => {
                        if (error.response.data.message) {
                            addMessage("error", error.response.data.message);
                        } else {
                            addMessage("error", Mensagens.ERRO);
                        }
                    })
            }
        }
    }

    const inativar = () => {
        if (model.id) {
            api
                .delete("/jornadas/" + model.id)
                .then(response => {
                    addMessage("success", Mensagens.CADASTRO_INATIVAR);
                    setSubmitted(false);
                    setModel(emptyModel);
                })
                .catch(erro => {
                    addMessage("error", Mensagens.ERRO);
                })
        } else {
            addMessage("warn", Mensagens.SELECIONE);
        }

    }

    const consultar = () => {
        api
            .get("/jornadas")
            .then(response => setLista(response.data))
            .catch(() => {
                addMessage("error", Mensagens.ERRO);
            })
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    const itemTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <span>{option.fantasia}</span>
            </div>
        );
    };

    const selectedItemTemplate = (option) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    <span>{option.fantasia}</span>
                </div>
            );
        }
        return 'Selecione Empresas';
    };

    const onSelecao = (e, descricao) => {
        const val = e.value || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
    }

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const cellEditor = (options) => {
        return textEditor(options);
    }

    const onCellEditComplete = (e) => {
        let { rowData, newValue, field, originalEvent: event } = e;
        try {
            let valor = newValue.trim()
            if (valor.length > 0) {
                if (valor.substring(0, 1) === '=') {

                    valor = valor.substring(1); //retira o sinal de =

                    valor = valor.replace(':', ''); //retira a pontuacao :, ficando somente número ou string possiveis

                    if (valor.length > 0) {
                        if (isNaN(valor)) { //se for string significa que pode ser um comando de limpar ou DSR
                            //comandos: =DSR, =LL, =LC
                            verificaComandos(rowData, field, valor);
                        } else {//senao for string é um número..um horário informado pelo usuário
                            newValue = formataHorario(valor);
                            preencheLinha(rowData, newValue);
                        }
                    }
                } else {
                    if (isNaN(valor)) { //se for string significa que pode ser um comando de limpar ou DSR
                        //comandos: =DSR, =LL, =LC
                        verificaComandos(rowData, field, valor);
                    } else {//senao for string é um número..um horário informado pelo usuário
                        newValue = formataHorario(newValue);
                        rowData[field] = newValue;
                    }
                }

            } else
                event.preventDefault();
        } catch (error) {
            event.preventDefault();
        }
    }

    const verificaComandos = (rowData, field, valor) => {
        //comandos: =DSR, =LL, =LC
        if (valor === 'DSR' || valor === 'dsr') {//se for DSR, preencho toda coluna como DSR
            preencheColuna(field, 'DSR');
        }
        if (valor === 'LC' || valor === 'lc') {
            preencheColuna(field, '');
        }
        if (valor === 'LL' || valor === 'll') {
            preencheLinha(rowData, '');
        }
    }

    const preencheColuna = (field, valor) => {
        model.valoresTabela.forEach(coluna => {
            coluna[field] = valor;
        })
    }

    const preencheLinha = (rowData, newValue) => {
        rowData['domingo'] = newValue;
        rowData['segunda'] = newValue;
        rowData['terca'] = newValue;
        rowData['quarta'] = newValue;
        rowData['quinta'] = newValue;
        rowData['sexta'] = newValue;
        rowData['sabado'] = newValue;
    }

    const formataHorario = (horario) => {
        let ret = '';
        try {

            horario = horario.replace(':', '');

            if (isNaN(horario)) { //verifica se é uma string, caso for retorne 00:00
                return ret;
            }

            if (parseInt(horario) < 10) {
                if (horario.length === 1) {
                    horario = '0' + horario;
                }

            }

            if (parseInt(horario) > 23 && horario.length !== 4) {
                return ret;
            }

            if (horario.length > 4) {
                return ret;
            }

            if (parseInt(horario) >= 60 && parseInt(horario) <= 99) {
                return ret;
            }

            if (parseInt(horario) > 2359) {
                return ret;
            }

            for (let i = horario.length; i < 4; i++) {
                horario = horario + '0';
            }

            let parte1 = horario.substring(0, 2);
            let parte2 = horario.substring(2);
            ret = parte1 + ':' + parte2;

        } catch (error) {

        }
        return ret;
    }

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">

                    <div className="field grid p-fluid">
                        <label htmlFor="empresas" className="col-12 mb-2 md:col-1 md:mb-0">Empresas*</label>
                        <div className="col-12 md:col-11">
                            <MultiSelect value={model.empresas} onChange={(e) => onSelecao(e, "empresas")} options={multiselectValues} optionLabel="name" placeholder="Selecione Empresas" filter
                                itemTemplate={itemTemplate} selectedItemTemplate={selectedItemTemplate} required className={classNames({ 'p-invalid': submitted && !model.empresas })} />
                            {submitted && !model.empresas && <small className="p-invalid">Obrigatório</small>}
                        </div>
                    </div>

                    <div className="field grid p-fluid">
                        <label htmlFor="nome" className="col-12 mb-2 md:col-1 md:mb-0">Nome*</label>
                        <div className="col-12 md:col-11">
                            <InputText id="nome" maxLength={200} placeholder="Máximo 200 caracteres" value={model.nome} onChange={(e) => onInputChange(e, 'nome')} required className={classNames({ 'p-invalid': submitted && !model.nome })} />
                            {submitted && !model.nome && <small className="p-invalid">Nome Obrigatório</small>}
                        </div>
                    </div>

                    <div className="mb-3">
                        <DataTable value={model.valoresTabela} editMode="cell" size='small' className="p-fluid" responsiveLayout="scroll">
                            {
                                columns.map(({ field, header }) => {
                                    if (field === 'fixo') {
                                        return <Column key={field} field={field} header={header} className="col-12 md:col-1" headerStyle={{ textAlign: 'center' }} bodyStyle={{ textAlign: 'right', fontWeight: 'bold', maxWidth: '1rem' }} />
                                    }
                                    return <Column key={field} field={field} header={header} className="col-12 md:col-1" headerStyle={{ textAlign: 'right' }} bodyStyle={{ textAlign: 'left', maxWidth: '6rem' }}
                                        editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} />
                                })
                            }
                        </DataTable>
                    </div>

                    <Fieldset legend="Tipo" className='mb-3'>
                        <div className="grid">
                            <div className="col-6 md:col-2">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option1" name="option" value={0} checked={model.tipoJornada === 0} onChange={(e) => onRadioChange(e, 'tipoJornada')} />
                                    <label htmlFor="option1">Regular</label>
                                </div>
                            </div>
                            <div className="col-6 md:col-2">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option2" name="option" value={1} checked={model.tipoJornada === 1} onChange={(e) => onRadioChange(e, 'tipoJornada')} />
                                    <label htmlFor="option3">12X36</label>
                                </div>
                            </div>
                            <div className="col-6 md:col-2">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option2" name="option" value={3} checked={model.tipoJornada === 3} onChange={(e) => onRadioChange(e, 'tipoJornada')} />
                                    <label htmlFor="option3">12X60</label>
                                </div>
                            </div>
                            <div className="col-6 md:col-2">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option4" name="option" value={2} checked={model.tipoJornada === 2} onChange={(e) => onRadioChange(e, 'tipoJornada')} />
                                    <label htmlFor="option4">Vira Dia</label>
                                </div>
                            </div>
                            <div className="col-6 md:col-2">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option5" name="option" value={4} checked={model.tipoJornada === 4} onChange={(e) => onRadioChange(e, 'tipoJornada')} />
                                    <label htmlFor="option5">Intercalada</label>
                                </div>
                            </div>
                        </div>
                    </Fieldset>


                    <div className="p-fluid formgrid grid">
                        <label htmlFor="tolerancia" className="col-12 md:col-1 md:mt-0">Tolerância Entrada*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputMask id="tolerancia" mask="99:99" value={model.toleranciaMinutos} onChange={(e) => onInputChange(e, 'toleranciaMinutos')} required className={classNames({ 'p-invalid': submitted && !model.toleranciaMinutos })} />
                            {submitted && !model.toleranciaMinutos && <small className="p-invalid">Tolerância Entrada Obrigatória</small>}
                        </div>

                        <label htmlFor="horassemanais" className="col-12 md:col-1 md:mt-0">Horas Semanais*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputMask id="horassemanais" mask="99:99" value={model.horassemanais} onChange={(e) => onInputChange(e, 'horassemanais')} required className={classNames({ 'p-invalid': submitted && !model.horassemanais })} />
                            {submitted && !model.horassemanais && <small className="p-invalid">Horas Semanais Obrigatória</small>}
                        </div>

                        <label htmlFor="diasTrab" className="col-12 md:col-1 md:mt-2">Dias Trabalho*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputNumber id="diasTrab" inputId="minmax-buttons" value={model.diasTrab} mode="decimal" showButtons min={0} max={100} onChange={(e) => onRadioChange(e, 'diasTrab')} />
                        </div>

                        <label htmlFor="diasDSR" className="col-12 md:col-1 md:mt-2">Dias Descanso*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputNumber id="diasDSR" inputId="minmax-buttons" value={model.diasDSR} mode="decimal" showButtons min={0} max={100} onChange={(e) => onRadioChange(e, 'diasDSR')} />
                        </div>
                    </div>

                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Jornadas, comparisonFn);
