import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import BTInativar from '../components/BTInativar';
import BTNovo from '../components/BTNovo';
import BTGravar from '../components/BTGravar';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { InputMask } from 'primereact/inputmask';
import { SelectButton } from 'primereact/selectbutton';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { AutoComplete } from 'primereact/autocomplete';
import { Checkbox } from 'primereact/checkbox';
import ConsultaFixaGenerica from '../components/consultas/ConsultaFixaGenerica';

const ConfiguracaoHorasExtras = () => {

    let emptyModel = {
        id: null,
        valor: '',
        rotulo: 'Horas Extras',
        horaInicialS: '',
        horaFinalS: '',
        dsrGerais: 0,
        feriadoGerais: 0,
        empresa: null,
    };

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [lista, setLista] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [tipo, setTipo] = useState('Diurna');
    const options = ['Diurna', 'Noturna'];
    const [vigencia, setVigencia] = useState([]);
    const optionsVigencia = ['DSR', 'Feriado'];
    const [autoFilteredValueEmpresa, setAutoFilteredValueEmpresa] = useState([]);
    const [autoValueEmpresa, setAutoValueEmpresa] = useState(null);
    const [todasEmpresas, setTodasEmpresas] = useState(false);

    const colunas = [
        { field: 'empresa.fantasia', header: 'Empresa', headerStyle: { minWidth: '15rem' } },
        { field: 'valor', header: 'Valor (%)', headerStyle: { minWidth: '5rem' } },
        { field: 'horaInicialS', header: 'Hora Inicial', headerStyle: { minWidth: '5rem' } },
        { field: 'horaFinalS', header: 'Hora Final', headerStyle: { minWidth: '5rem' } },
        { field: 'dsrGeraisS', header: 'DSR', headerStyle: { minWidth: '5rem' } },
        { field: 'feriadoGeraisS', header: 'Feriado', headerStyle: { minWidth: '5rem' } },
        { field: 'tipoS', header: 'Tipo', headerStyle: { minWidth: '5rem' } },
        { field: 'rotulo', header: 'Rótulo', headerStyle: { minWidth: '5rem' } },
        { field: 'dataS', header: 'Data Histórica', headerStyle: { minWidth: '5rem' } },
    ];

    const message = useRef();

    useEffect(() => {

        const buscaEmpresas = () => {
            api
                .get("/empresas")
                .then(response => {
                    model.empresa = response.data[0];
                    setAutoValueEmpresa(response.data);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaHorasExtras = () => {
            api
                .get("/regras/configuracoes/horasextras")
                .then(response => {
                    setLista(response.data)
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        buscaEmpresas();
        buscaHorasExtras();

    }, []);

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <BTGravar setSubmitted={setSubmitted} cadastrar={cadastrar} />
                    <BTNovo setSubmitted={setSubmitted} setModel={setModel} emptyModel={emptyModel} />
                    <BTInativar inativar={inativar} />
                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    const procurarEmpresas = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValueEmpresa([...autoValueEmpresa]);
            }
            else {
                setAutoFilteredValueEmpresa(autoValueEmpresa.filter((em) => {
                    return em.fantasia.toLowerCase().startsWith(event.query.toLowerCase());
                }));
            }
        }, 250);
    };


    const onInputChange = (e, descricao) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
    }

    const selecao = (e) => {
        let vig = [];

        if (e.value.dsrGeraisS === "SIM") {
            vig.push('DSR')
        }
        if (e.value.feriadoGeraisS === "SIM") {
            vig.push('Feriado')
        }

        setVigencia(vig);

        if (e.value.tipoS === "DIURNA") {
            setTipo('Diurna');
        } else {
            setTipo('Noturna');
        }
        setModel(e.value);
    }

    const cadastrar = () => {
        if ((model.empresa || todasEmpresas) && model.valor.trim() && model.horaInicialS.trim() && model.horaFinalS.trim() && model.rotulo.trim())  {
            let _model = { ...model };
            const val = 1;
            let descricao = "dsrGerais";
            let descricao1 = "feriadoGerais";
            vigencia.forEach(element => {
                if (element === 'DSR') {
                    _model[`${descricao}`] = val;
                } else if (element === 'Feriado') {
                    _model[`${descricao1}`] = val;
                }
            });
            setModel(_model);
            api
                .post("/regras/configuracoes/horasextras", {
                    id: model.id,
                    valor: model.valor,
                    rotulo: model.rotulo,
                    horaInicialS: model.horaInicialS,
                    horaFinalS: model.horaFinalS,
                    tipoS: tipo,
                    dsrGerais: _model.dsrGerais,
                    feriadoGerais: _model.feriadoGerais,
                    empresa: model.empresa,
                    todasEmpresas: todasEmpresas,
                })
                .then(response => {
                    console.log(response.data)
                    setLista(response.data);
                    addMessage("success", Mensagens.CADASTRO_INCLUIR);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
    }

    const inativar = () => {
        if (model.id) {
            api
                .delete("/regras/configuracoes/horasextras/" + model.id)
                .then(response => {
                    addMessage("success", Mensagens.CADASTRO_INATIVAR);
                    setLista(response.data);
                    setSubmitted(false);
                    setModel(emptyModel);
                    setVigencia([]);
                    setTipo("Diurna");
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        } else {
            addMessage("warn", Mensagens.SELECIONE);
        }

    }

    // const vig = (e) => {
    //     setVigencia(e.value);
    // }

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="empresa" className="col-12 md:col-1 md:mb-2">Empresa*</label>
                        <div className="col-12 md:col-9 mb-2">
                            <AutoComplete placeholder="Procure" id="empresa" dropdown value={model.empresa} onChange={(e) => onInputChange(e, 'empresa')} suggestions={autoFilteredValueEmpresa} completeMethod={procurarEmpresas} field="fantasia" required className={classNames({ 'p-invalid': submitted && !model.empresa && !todasEmpresas })} />
                            {submitted && !model.empresa && !todasEmpresas && <small className="p-invalid">Empresa Obrigatória</small>}
                        </div>
                        <div className="field-checkbox p-fluid mt-2 col-2">
                            <Checkbox inputId="checkOption1" name="todasempresas" value="Todas Empresas" checked={todasEmpresas} onChange={e => setTodasEmpresas(e.checked)} />
                            <label htmlFor="checkOption1">Todas Empresas</label>
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="valor" className="col-12 md:col-1 md:mt-2">Valor (%)*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputText id="valor" value={model.valor} onChange={(e) => onInputChange(e, 'valor')} required className={classNames({ 'p-invalid': submitted && !model.valor })} />
                            {submitted && !model.valor && <small className="p-invalid">Obrigatório</small>}
                        </div>

                        <label htmlFor="rotulo" className="col-12 md:col-1 md:mt-2">Rótulo*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputText id="rotulo" maxLength={15} placeholder="Máximo 15 caracteres" value={model.rotulo} onChange={(e) => onInputChange(e, 'rotulo')} />
                        </div>
                        <div className="field col-12 md:col-2 mb-2">
                            <SelectButton multiple value={vigencia} options={optionsVigencia} onChange={(e) => setVigencia(e.value)} />
                        </div>

                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="horainicial" className="col-12 md:col-1 md:mt-2">Hora Inicial*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputMask id="horainicial" mask='99:99' value={model.horaInicialS} onChange={(e) => onInputChange(e, 'horaInicialS')} required className={classNames({ 'p-invalid': submitted && !model.horaInicialS })} />
                            {submitted && !model.horaInicialS && <small className="p-invalid">Obrigatória</small>}
                        </div>

                        <label htmlFor="horafinal" className="col-12 md:col-1 md:mt-2">Hora Final*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputMask id="horafinal" mask='99:99' value={model.horaFinalS} onChange={(e) => onInputChange(e, 'horaFinalS')} required className={classNames({ 'p-invalid': submitted && !model.horaFinalS })} />
                            {submitted && !model.horaFinalS && <small className="p-invalid">Obrigatória</small>}
                        </div>

                        <div className="field col-12 md:col-2 mb-2">
                            <SelectButton value={tipo} options={options} onChange={(e) => setTipo(e.value)} />
                        </div>

                    </div>

                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                    <ConsultaFixaGenerica
                        lista={lista}
                        model={model}
                        selecao={selecao}
                        camposFiltroGlobal={['empresa.fantasia', 'valor', 'horaInicialS', 'horaFinalS', 'tipoS']}
                        colunas={colunas}
                    />

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ConfiguracaoHorasExtras, comparisonFn);
