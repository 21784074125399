import React, { useState, useRef, useEffect } from "react";
import api from "../service/api";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Messages } from "primereact/messages";
import Mensagens from "../globais/Mensagens";
import { MultiSelect } from "primereact/multiselect";
import Botoes from "../globais/Botoes";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { AutoComplete } from "primereact/autocomplete";
import { classNames } from "primereact/utils";
import { Calendar } from "primereact/calendar";
import { addLocale, locale } from "primereact/api";

const RelatorioMteEspelhoPonto = () => {
    let emptyModel = {
        empresa: { id: 0, fantasia: "" },
        departamento: { id: 0, descricao: "TODOS" },
        cargo: { id: 0, descricao: "TODOS" },
    };

    addLocale("br", {
        firstDayOfWeek: 1,
        dayNames: ["domingo", "segunda", "terça", "quarta", "quinta", "sexta", "sábado"],
        dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
        dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "SA"],
        monthNames: ["janeiro", "fevereiro", "março", "abril", "´maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"],
        monthNamesShort: ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"],
        today: "Hoje",
        clear: "Limpar",
    });

    locale("br");

    let hoje = new Date();

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [listaColaboradores, setListaColaboradores] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [multiselectValue, setMultiselectValue] = useState([]);
    const message = useRef();
    const [value, setValue] = useState(0);
    const interval = useRef(null);
    const [autoFilteredValueEmpresa, setAutoFilteredValueEmpresa] = useState([]);
    const [autoValueEmpresa, setAutoValueEmpresa] = useState(null);
    const [autoFilteredValueDepartamento, setAutoFilteredValueDepartamento] = useState([]);
    const [autoValueDepartamento, setAutoValueDepartamento] = useState(null);
    const [autoFilteredValueCargo, setAutoFilteredValueCargo] = useState([]);
    const [autoValueCargo, setAutoValueCargo] = useState(null);
    const [data, setData] = useState(hoje);

    useEffect(() => {
        const buscaEmpresas = () => {
            api.get("/empresas")
                .then((response) => {
                    setAutoValueEmpresa(response.data);
                    model.empresa = response.data[0];
                    buscaColaboradores(response.data[0], model.departamento, model.cargo);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                });
        };

        const buscaDepartamentos = () => {
            api.get("/departamentos")
                .then((response) => setAutoValueDepartamento(response.data))
                .catch((erro) => {
                    addMessage("error", Mensagens.ERRO);
                });
        };

        const buscaCargos = () => {
            api.get("/cargos")
                .then((response) => setAutoValueCargo(response.data))
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                });
        };
        buscaEmpresas();
        buscaDepartamentos();
        buscaCargos();
    }, []);

    useEffect(() => {
        if (submitted) {
            let val = value;
            interval.current = setInterval(() => {
                val += Math.floor(Math.random() * 5) + 1;

                if (val >= 100) {
                    val = 97;
                    clearInterval(interval.current);
                }
                setValue(val);
            }, 6000);

            return () => {
                if (interval.current) {
                    clearInterval(interval.current);
                    interval.current = null;
                }
            };
        }
    }, [value, submitted]);

    const itemTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                {/* <span className={`mr-2 flag flag-${option.descricao.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                <span>{option.nome}</span>
            </div>
        );
    };

    const selectedItemTemplate = (option) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    {/* <span className={`mr-2 flag flag-${option.descricao.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                    <span>{option.nome}</span>
                </div>
            );
        }

        return "Selecione Colaboradores";
    };

    const buscaColaboradores = (empresa, departamento, cargo) => {
        if (empresa !== null) {
            api.get("/colaboradores?empresa=" + empresa.id + "&departamento=" + departamento.id + "&cargo=" + cargo.id)
                .then((response) => {
                    setListaColaboradores(response.data);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                });
        }
    };

    const onInputChange = (e, descricao) => {
        const val = (e.target && e.target.value) || "";
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
        if (descricao === "empresa" || descricao === "departamento" || descricao === "cargo") {
            setMultiselectValue([]);
            buscaColaboradores(_model.empresa, _model.departamento, _model.cargo);
        }
    };

    const procurarEmpresas = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueEmpresa([...autoValueEmpresa]);
                } else {
                    setAutoFilteredValueEmpresa(
                        autoValueEmpresa.filter((em) => {
                            return em.fantasia.toLowerCase().startsWith(event.query.toLowerCase());
                        })
                    );
                }
            } catch (e) {}
        }, 250);
    };

    const procurarDepartamentos = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueDepartamento([...autoValueDepartamento]);
                } else {
                    setAutoFilteredValueDepartamento(
                        autoValueDepartamento.filter((em) => {
                            return em.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                        })
                    );
                }
            } catch (e) {}
        }, 250);
    };

    const procurarCargos = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueCargo([...autoValueCargo]);
                } else {
                    setAutoFilteredValueCargo(
                        autoValueCargo.filter((em) => {
                            return em.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                        })
                    );
                }
            } catch (e) {}
        }, 250);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={Botoes.VISUALIZAR} icon="pi pi-check" onClick={visualizar} className="p-button-success mr-2" />
                    <Messages ref={message} />
                </div>
            </React.Fragment>
        );
    };

    const visualizar = () => {
        if (multiselectValue.length > 0) {
            setValue(2);
            setSubmitted(true);
            api.post(
                "areamte/espelho/relatorio",
                {
                    colaboradores: multiselectValue,
                    empresa: model.empresa,
                    mes: data.getMonth() + 1,
                    ano: data.getFullYear(),
                    funcionarioDesligado: false,
                },
                { responseType: "arraybuffer" }
            )
                .then((response) => {
                    const file = new Blob([response.data], { type: "application/pdf" });

                    const fileURL = URL.createObjectURL(file);

                    window.open(fileURL);
                    setSubmitted(false);
                    setValue(100);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                    setSubmitted(false);
                    setValue(0);
                });
        } else {
            setSubmitted(false);
            setValue(0);
            addMessage("warn", Mensagens.SELECIONE);
        }
    };

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    // const panelFooterTemplate = () => {
    //     const selectedItems = multiselectValue;
    //     const length = selectedItems ? selectedItems.length : 0;
    //     return (
    //         <div className="py-2 px-3">
    //             <b>{length}</b> iten{length > 1 ? 's' : ''} selecionados.
    //         </div>
    //     );
    // }

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="empresa" className="col-12 md:col-1 md:mb-0">
                            Empresa*
                        </label>
                        <div className="col-12 md:col-11">
                            <AutoComplete
                                placeholder="Procure"
                                id="empresa"
                                dropdown
                                value={model.empresa}
                                onChange={(e) => onInputChange(e, "empresa")}
                                suggestions={autoFilteredValueEmpresa}
                                completeMethod={procurarEmpresas}
                                field="fantasia"
                                required
                                className={classNames({ "p-invalid": submitted && !model.empresa })}
                            />
                            {submitted && !model.empresa && <small className="p-invalid">Empresa Obrigatória</small>}
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="departamento" className="col-12 md:col-1 md:mb-0">
                            Departamento
                        </label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="departamento" dropdown value={model.departamento} onChange={(e) => onInputChange(e, "departamento")} suggestions={autoFilteredValueDepartamento} completeMethod={procurarDepartamentos} field="descricao" />
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="cargo" className="col-12 md:col-1 md:mb-0">
                            Cargo
                        </label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="cargo" dropdown value={model.cargo} onChange={(e) => onInputChange(e, "cargo")} suggestions={autoFilteredValueCargo} completeMethod={procurarCargos} field="descricao" />
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="periodo" className="col-12 md:col-1 md:mt-2">
                            Período*
                        </label>
                        <div className="field col-12 md:col-2 mb-2">
                            <Calendar dateFormat="mm/yy" value={data} onChange={(e) => setData(e.value)} view="month" showIcon></Calendar>
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-12 mb-2">
                            <MultiSelect value={multiselectValue} onChange={(e) => setMultiselectValue(e.value)} options={listaColaboradores} optionLabel="nome" filter itemTemplate={itemTemplate} selectedItemTemplate={selectedItemTemplate} maxSelectedLabels={5} />
                        </div>
                    </div>

                    <div className="col-12 ">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                    <div className="col-12">
                        <div className="col">
                            <ProgressBar value={value} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(RelatorioMteEspelhoPonto, comparisonFn);
