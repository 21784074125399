import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';

export default function ConsultaFixaGenerica(props) {

    const [globalFilterValue, setGlobalFIlterValue] = useState('');
    const [filters, setFilters] = useState(null);
    const [loading, setloading] = useState(true);

    useEffect(() => {
        setloading(false);
        initFilters();
    }, []);

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Limpar" className="p-button-outlined" onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />

                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Procurar" />
                </span>
            </div>
        )
    };

    const clearFilter = () => {
        initFilters();
        setGlobalFIlterValue("")
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters };
        _filters1['global'].value = value;
        setFilters(_filters1);
        setGlobalFIlterValue(value);
    };

    const initFilters = () => {
        setFilters({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        });
    };

    return (
        <>
            <DataTable value={props.lista} size="small" selectionMode="single"
                paginator className="p-datatable-gridlines" showGridlines rows={10}
                dataKey="id" filters={filters} filterDisplay="menu" loading={loading} responsiveLayout='scroll'
                globalFilterFields={props.camposFiltroGlobal} header={renderHeader} selection={props.model} onSelectionChange={(e) => props.selecao(e)} emptyMessage="Nada encontrado.">
                {props.colunas.map((col, index) => (
                    <Column key={index} field={col.field} header={col.header} sortable headerStyle={col.headerStyle} />
                ))}
            </DataTable>
        </>
    )

}

