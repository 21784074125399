import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import BTInativar from '../components/BTInativar';
import BTNovo from '../components/BTNovo';
import BTGravar from '../components/BTGravar';
import { classNames } from 'primereact/utils';
import { SelectButton } from 'primereact/selectbutton';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { AutoComplete } from 'primereact/autocomplete';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import ConsultaFixaGenerica from '../components/consultas/ConsultaFixaGenerica';

const ConfiguracaoCodigoEventos = () => {

    let emptyModel = {
        id: null,
        empresa: null,
        evento: null,
        codigoExterno: '',
        identificadorRegistro: 1,
        todasEmpresas: false,
    };

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [lista, setLista] = useState(null);
    const [listaEventos, setListaEventos] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [autoFilteredValueEmpresa, setAutoFilteredValueEmpresa] = useState([]);
    const [autoValueEmpresa, setAutoValueEmpresa] = useState(null);
    const optionsTipo = ['Mensalista', 'Horista'];
    const [todasEmpresas, setTodasEmpresas] = useState(false);

    const colunas = [
        { field: 'empresa.fantasia', header: 'Empresa', headerStyle: { minWidth: '15rem' } },
        { field: 'evento.descricao', header: 'Evento', headerStyle: { maxWidth: '5rem' } },
        { field: 'codigoExterno', header: 'Código', headerStyle: { maxWidth: '5rem' } },
        { field: 'identificadorRegistroS', header: 'Tipo', headerStyle: { maxWidth: '5rem' } },
    ];

    const message = useRef();

    useEffect(() => {

        const buscaJornadas = () => {
            api
                .get("/empresas")
                .then(response => {
                    model.empresa = response.data[0];
                    setAutoValueEmpresa(response.data);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaHorasCodigoEventos = () => {
            api
                .get("/regras/configuracoes/codigoeventos")
                .then(response => {
                    setLista(response.data)
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaDiasSemana = () => {
            api
                .get("/eventosinternos")
                .then(response => {
                    setListaEventos(response.data)
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }


        buscaJornadas();
        buscaHorasCodigoEventos();
        buscaDiasSemana();

    }, []);

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <BTGravar setSubmitted={setSubmitted} cadastrar={cadastrar} />
                    <BTNovo setSubmitted={setSubmitted} setModel={setModel} emptyModel={emptyModel} />
                    <BTInativar inativar={inativar} />
                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    const procurarEmpresas = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValueEmpresa([...autoValueEmpresa]);
            }
            else {
                setAutoFilteredValueEmpresa(autoValueEmpresa.filter((em) => {
                    return em.fantasia.toLowerCase().startsWith(event.query.toLowerCase());
                }));
            }
        }, 250);


    };


    const onInputChange = (e, descricao) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
    }

    const definirTipo = (e, descricao) => {
        const val = (e.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val === "Mensalista" ? 1 : 2;
        setModel(_model);
    }

    const selecao = (e) => {
        setModel(e.value);
    }

    const cadastrar = () => {
        if ((model.empresa || todasEmpresas) && model.evento && model.codigoExterno.trim()) {
            api
                .post("/regras/configuracoes/codigoeventos", {
                    id: model.id,
                    codigoExterno: model.codigoExterno,
                    evento: model.evento,
                    empresa: model.empresa,
                    identificadorRegistro: model.identificadorRegistro,
                    todasEmpresas: todasEmpresas,
                })
                .then(response => {
                    setLista(response.data);
                    addMessage("success", Mensagens.CADASTRO_INCLUIR);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
    }

    const inativar = () => {
        if (model.id) {
            api
                .delete("/regras/configuracoes/codigoeventos/" + model.id)
                .then(response => {
                    addMessage("success", Mensagens.CADASTRO_INATIVAR);
                    setLista(response.data);
                    setSubmitted(false);
                    setModel(emptyModel);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        } else {
            addMessage("warn", Mensagens.SELECIONE);
        }

    }


    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="empresa" className="col-12 md:col-1 md:mb-2">Empresa*</label>
                        <div className="col-12 md:col-9 mb-2">
                            <AutoComplete placeholder="Procure" id="empresa" dropdown value={model.empresa} onChange={(e) => onInputChange(e, 'empresa')} suggestions={autoFilteredValueEmpresa} completeMethod={procurarEmpresas} field="fantasia" required className={classNames({ 'p-invalid': submitted && !model.empresa && !todasEmpresas })} />
                            {submitted && !model.empresa && !todasEmpresas && <small className="p-invalid">Empresa Obrigatória</small>}
                        </div>
                        <div className="field-checkbox p-fluid mt-2 col-2">
                            <Checkbox inputId="checkOption1" name="todasempresas" value="Todas Empresas" checked={todasEmpresas} onChange={e => setTodasEmpresas(e.checked)} />
                            <label htmlFor="checkOption1">Todas Empresas</label>
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="diasemana" className="col-12 md:col-1 md:mt-2">Evento*</label>
                        <div className="field col-12 md:col-4 mb-2">
                            <Dropdown value={model.evento} onChange={(e) => onInputChange(e, 'evento')} options={listaEventos} optionLabel="descricao" placeholder="Selecione" emptyMessage="Nada encontrado." required className={classNames({ 'p-invalid': submitted && !model.evento })}  />
                            {submitted && !model.evento && <small className="p-invalid">Evento Obrigatório</small>}
                        </div>
                        <label htmlFor="codigo" className="col-12 md:col-1 md:mt-2">Código*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputText id="codigo" maxLength={30} placeholder="Máximo 30 caracteres" value={model.codigoExterno} onChange={(e) => onInputChange(e, 'codigoExterno')} required className={classNames({ 'p-invalid': submitted && !model.codigoExterno })} />
                            {submitted && !model.codigoExterno && <small className="p-invalid">Código Obrigatório</small>}
                        </div>
                        <div className="field col-12 md:col-2 mb-2">
                            <SelectButton value={model.identificadorRegistro === 1 ? "Mensalista" : "Horista"} options={optionsTipo} onChange={(e) => definirTipo(e, "identificadorRegistro")} />
                        </div>
                    </div>

                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                    <ConsultaFixaGenerica
                        lista={lista}
                        model={model}
                        selecao={selecao}
                        camposFiltroGlobal={['empresa.fantasia', 'evento.descricao', 'codigoExterno','identificadorRegistroS']}
                        colunas={colunas}
                    />

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ConfiguracaoCodigoEventos, comparisonFn);
