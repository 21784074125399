import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import { MultiSelect } from 'primereact/multiselect';
import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';
import BTGravar from '../components/BTGravar';
import { PickList } from 'primereact/picklist';

const BHAtivarColaboradores = () => {

    let emptyModel = {
        empresa: { id: 0, fantasia: '' },
        departamento: { id: 0, descricao: 'TODOS' },
        cargo: { id: 0, descricao: 'TODOS' },
    };

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [listaColaboradores, setListaColaboradores] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [multiselectValue, setMultiselectValue] = useState([]);
    const message = useRef();
    const [autoFilteredValueEmpresa, setAutoFilteredValueEmpresa] = useState([]);
    const [autoValueEmpresa, setAutoValueEmpresa] = useState(null);
    const [autoFilteredValueDepartamento, setAutoFilteredValueDepartamento] = useState([]);
    const [autoValueDepartamento, setAutoValueDepartamento] = useState(null);
    const [autoFilteredValueCargo, setAutoFilteredValueCargo] = useState([]);
    const [autoValueCargo, setAutoValueCargo] = useState(null);
    const [colaboradoresAtivos, setColaboradoresAtivos] = useState([]);
    const [colaboradoresInativos, setColaboradoresInativos] = useState([]);

    useEffect(() => {
        const buscaEmpresas = () => {
            api
                .get("/empresas")
                .then(response => {
                    setAutoValueEmpresa(response.data);
                    model.empresa = response.data[0];
                    buscaColaboradores(response.data[0], model.departamento, model.cargo);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaDepartamentos = () => {
            api
                .get("/departamentos")
                .then(response => setAutoValueDepartamento(response.data)
                )
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaCargos = () => {
            api
                .get("/cargos")
                .then(response => setAutoValueCargo(response.data)
                )
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
        buscaEmpresas();
        buscaDepartamentos();
        buscaCargos();
    }, []);

    const onChange = (event) => {
        setColaboradoresInativos(event.source);
        setColaboradoresAtivos(event.target);

        let listaColaboradores = [];
       
        event.source.forEach(element => {
            element.bancodehoras = 0;
            listaColaboradores.push(element);
        });
        event.target.forEach(element => {
            element.bancodehoras = 1;
            listaColaboradores.push(element);
        });

        setListaColaboradores(listaColaboradores);
    }

    const itemTemplatePickList = (item) => {
        return (
            <div className="product-item">
                {/* <div className="image-container">
                    <img src={`showcase/demo/images/product/${item.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={item.name} />
                </div> */}
                <div className="product-list-detail">
                    <h5 className="p-mb-2">{item.nome}</h5>
                    {/* <i className="pi pi-tag product-category-icon"></i> */}
                    <span className="product-category">{item.cargo.descricao}</span>
                </div>
                {/* <div className="product-list-action">
                    <h6 className="p-mb-2">${item.price}</h6>
                    <span className={`product-badge status-${item.inventoryStatus.toLowerCase()}`}>{item.inventoryStatus}</span>
                </div> */}
            </div>
        );
    }

    const buscaColaboradores = (empresa, departamento, cargo) => {
        if (empresa !== null) {
            api
                .get("/colaboradores?empresa=" + empresa.id + "&departamento=" + departamento.id + "&cargo=" + cargo.id)
                .then(response => {
                    setListaColaboradores(response.data);
                    let ativos = [];
                    let inativos = [];
                    response.data.forEach(element => {
                        if (element.bancodehoras === 1) {
                            ativos.push(element);
                        } else {
                            inativos.push(element);
                        }
                    });
                    setColaboradoresAtivos(ativos);
                    setColaboradoresInativos(inativos);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
    };

    const onInputChange = (e, descricao) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
        if (descricao === 'empresa' || descricao === 'departamento' || descricao === 'cargo') {
            setMultiselectValue([]);
            buscaColaboradores(_model.empresa, _model.departamento, _model.cargo);
        }
    }

    const procurarEmpresas = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueEmpresa([...autoValueEmpresa]);
                }
                else {
                    setAutoFilteredValueEmpresa(autoValueEmpresa.filter((em) => {
                        return em.fantasia.toLowerCase().startsWith(event.query.toLowerCase());

                    }));
                }
            } catch (e) {
            }

        }, 250);
    };

    const procurarDepartamentos = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueDepartamento([...autoValueDepartamento]);
                }
                else {
                    setAutoFilteredValueDepartamento(autoValueDepartamento.filter((em) => {
                        return em.descricao.toLowerCase().startsWith(event.query.toLowerCase());

                    }));
                }
            } catch (e) {
            }

        }, 250);
    };

    const procurarCargos = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueCargo([...autoValueCargo]);
                }
                else {
                    setAutoFilteredValueCargo(autoValueCargo.filter((em) => {
                        return em.descricao.toLowerCase().startsWith(event.query.toLowerCase());

                    }));
                }
            } catch (e) {
            }

        }, 250);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <BTGravar setSubmitted={setSubmitted} cadastrar={cadastrar} />
                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const cadastrar = () => {
        api
            .post("/bancoHoras/ativarColaboradores", listaColaboradores)
            .then(response => {
                // setModel(response.data);
                addMessage("success", Mensagens.OPERACAO_SUCESSO);
            })
            .catch(() => {
                addMessage("error", Mensagens.ERRO);
            })
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="empresa" className="col-12 md:col-1 md:mb-0">Empresa*</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="empresa" dropdown value={model.empresa} onChange={(e) => onInputChange(e, 'empresa')} suggestions={autoFilteredValueEmpresa} completeMethod={procurarEmpresas} field="fantasia" required className={classNames({ 'p-invalid': submitted && !model.empresa })} />
                            {submitted && !model.empresa && <small className="p-invalid">Empresa Obrigatória</small>}
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="departamento" className="col-12 md:col-1 md:mb-0">Departamento</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="departamento" dropdown value={model.departamento} onChange={(e) => onInputChange(e, 'departamento')} suggestions={autoFilteredValueDepartamento} completeMethod={procurarDepartamentos} field="descricao" />
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="cargo" className="col-12 md:col-1 md:mb-0">Cargo</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="cargo" dropdown value={model.cargo} onChange={(e) => onInputChange(e, 'cargo')} suggestions={autoFilteredValueCargo} completeMethod={procurarCargos} field="descricao" />
                        </div>
                    </div>

                    {/* <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-12 mb-2">
                            <MultiSelect value={colaboradoresAtivos} onChange={(e) => setMultiselectValue(e.value)} options={listaColaboradores} optionLabel="nome" filter
                                itemTemplate={itemTemplate} selectedItemTemplate={selectedItemTemplate} maxSelectedLabels={5} />
                        </div>
                    </div> */}

                    {/* <div className="picklist-demo"> */}
                    {/* <div className="card"> */}
                        <PickList source={colaboradoresInativos} target={colaboradoresAtivos} itemTemplate={itemTemplatePickList}
                            sourceHeader="Inativos" targetHeader="Ativos"
                            sourceStyle={{ height: '342px' }} targetStyle={{ height: '342px' }}
                            onChange={onChange}></PickList>
                    {/* </div> */}
                    {/* </div> */}

                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(BHAtivarColaboradores, comparisonFn);
