import axios from "axios";
import DadosApi from "../globais/DadosApi";

const apiAutenticacao = axios.create({
  // baseURL: "http://192.168.100.56:8081/api/v1/auth",
  // baseURL: "http://191.96.78.164:8081/api/v1/auth",
 
  //baseURL: "https://www.heup.com.br/api/v1/auth",

  // baseURL: "http://localhost/api/v1/auth",

  baseURL: DadosApi.CAMINHO_APIAUTENTICACAO,


  headers: {
    "Content-type": "application/json"
  }
});

export default apiAutenticacao;