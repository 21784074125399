import React, { useState, useRef, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { useHistory } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import validator from 'email-validator';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import Formatacao from '../utilities/Formatacao';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';

import api from '../service/api';

export const RegistroCliente = () => {

	let emptyModel = {
		id: null,
		nomeCliente: '',
		email: '',
		telefone: '',
		fantasia: '',
		razaoSocial: '',
		cnpj: '',
		usuarioLider: null,
		plano: null,
		quantColaboradores: 10,
	};

	const history = useHistory();
	const [model, setModel] = useState(emptyModel);
	const [submitted, setSubmitted] = useState(false);
	const [emailValido, setEmailValido] = useState(false);
	const [clienteValido, setClienteValido] = useState(false);
	const [cnpjValido, setCnpjValido] = useState(false);
	const [autoFilteredValueUsuario, setAutoFilteredValueUsuario] = useState([]);
	const [planos, setPlanos] = useState([]);
	const [valorEstimado, setValorEstimado] = useState("R$ 49,99/mês");
	const [valorTotal, setValorTotal] = useState(0);

	const toast = useRef(null);

	const message = useRef();

	let formatacao = new Formatacao();

	useEffect(() => {
		const buscarPlanos = () => {
			api
				.get("/planos")
				.then(response => {
					setPlanos(response.data);
					let _model = { ...model };
					_model[`${"plano"}`] = response.data[0];
					setModel(_model);
				})
				.catch(() => {
					addMessage("error", Mensagens.ERRO);
				})
		}
		buscarPlanos();
	}, []);

	const goDashboard = () => {
		history.push('/');
	}

	const validateEmail = (email) => {
		setEmailValido(validator.validate(email));
	}

	const validateCliente = (usuario) => {
		let valido = false;

		const temEspaco = usuario.includes(' ');

		const regex = /^[a-zA-Z0-9]+$/;
		const letrasNumeros = regex.test(usuario);

		let maiuscula = /^(?=.*[A-Z]).*$/;
		const temMaiscula = maiuscula.test(usuario);

		if (temEspaco === false & usuario.length >= 4 & letrasNumeros & temMaiscula === false) {
			valido = true;
		}
		setClienteValido(valido);
	}

	const validateCnpj = (num) => {
		let cpfValido = cpf.isValid(num);
		let cnpjValido = cnpj.isValid(num);
		if (cpfValido || cnpjValido) {
			setCnpjValido(true);
		} else {
			setCnpjValido(false);
		}
	}

	const addMessage = (sv, cn) => {
		message.current.show({ severity: sv, content: cn });
	};

	// const showValorTotalEstimado = (mensagem) => {
	// 		toast.current.show({ severity: 'success', summary: 'Valor Total Estimado', detail: mensagem, life: 100000 });
	// }

	const buscarUsuario = (nomeUsuario) => {
		api
			.get("/usuarios?nome=" + nomeUsuario)
			.then(response => {
				setAutoFilteredValueUsuario(response.data.filter((em) => {
					return em.nomeUsuario.toLowerCase();
				}));
			})
			.catch(() => {
				// addMessage("error", Mensagens.ERRO);
			})
	}

	const procurarUsuario = (e) => {
		setTimeout(() => {
			if (e.query.trim().length >= 4) {
				buscarUsuario(e.query);
			}
		}, 250);
	};

	const cadastrar = () => {
		setSubmitted(true);
		if (model.email.trim() && model.nomeCliente.trim() &&
			model.telefone.trim() && model.cnpj.trim() && model.fantasia.trim() &&
			model.razaoSocial.trim() && clienteValido && emailValido && cnpjValido) {
			api
				.post("/clientes", {
					id: model.id,
					email: model.email,
					nomeCliente: model.nomeCliente,
					telefone: model.telefone,
					fantasia: model.fantasia,
					razaoSocial: model.razaoSocial,
					cnpj: model.cnpj,
					usuarioLider: model.usuarioLider,
					valorTotal: valorTotal,
					plano: model.plano,
					quantColaboradores: model.quantColaboradores,
				})
				.then(response => {
					addMessage("success", response.data.message);
				})
				.catch(erro => {
					if (erro.response) {
						addMessage("error", erro.response.data.message);
					} else {
						addMessage("error", Mensagens.ERRO);
					}
				})
			setSubmitted(false)
		}
	}

	const formarValorPlano = (quantColaboradores, plano) => {

		const valorEstimado = Math.ceil(quantColaboradores / plano.fatorDivisaoColaboradores) * plano.valor * plano.quantMeses;

		setValorTotal(valorEstimado);


		// Formatando o valor para a moeda R$
		const valorFormatado = valorEstimado.toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL'
		});

		let valorMensalEstimado = valorEstimado / plano.quantMeses;

		const valorMensalFormatado = valorMensalEstimado.toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL'
		});

		setValorEstimado(valorFormatado + " (" + valorMensalFormatado + "/mês)");

		// showValorTotalEstimado(valorFormatado + " (" + valorMensalFormatado + "/mês)");
	}

	const onInputChange = (e, descricao) => {
		let val = (e.target && e.target.value) || '';

		if (descricao === 'quantColaboradores') {
			val = val.replace(/\D/g, '');
			if (val <= 0) {
				val = 1;
			}
		}

		let _model = { ...model };
		_model[`${descricao}`] = val;
		setModel(_model);

		if (descricao === 'nomeCliente') {
			validateCliente(_model.nomeCliente);
		}
		if (descricao === 'quantColaboradores' | descricao === 'plano') {
			formarValorPlano(_model.quantColaboradores, _model.plano);
		}
	}

	return (
		<div className="login-body">
			<div className="body-container">
				<div className="grid">
					<Toast ref={toast} />
					<div className="col-12 lg:col-6 left-side">
						<img src="assets/layout/images/logo-white-he.png" alt="apollo-layout" className="logo" />
						<h1>Estamos ansiosos para atendê-lo!</h1>
						<p>
							Entre em contato com nossa equipe comercial e escolha o melhor plano para sua empresa.
						</p>
					</div>
					<div className="col-12 lg:col-6 right-side">
						<div className="login-wrapper">
							<div className="login-container">
								<span className="title">Registre-se</span>

								<div className="grid p-fluid">

									<div className="col-12">
										<div className="p-field">
											<InputText id="faltasia" placeholder="Fantasia*" onChange={(e) => onInputChange(e, 'fantasia')} required className={classNames({ 'p-invalid': submitted && !model.fantasia })} />
											{submitted && !model.fantasia && <small className="p-invalid">faltasia obrigatória.</small>}
										</div>
									</div>

									<div className="col-12">
										<div className="p-field">
											<InputText id="razao" placeholder="Razão social*" onChange={(e) => onInputChange(e, 'razaoSocial')} required className={classNames({ 'p-invalid': submitted && !model.razaoSocial })} />
											{submitted && !model.razaoSocial && <small className="p-invalid">Razão social obrigatória.</small>}
										</div>
									</div>

									<div className="col-6">
										<div className="p-field">
											<InputText id="cnpj" placeholder="CNPJ ou CPF*" value={cnpj.format(model.cnpj)} onBlur={(e) => validateCnpj(e.target.value)} onChange={(e) => onInputChange(e, 'cnpj')} required className={classNames({ 'p-invalid': submitted && !cnpjValido })} />
											{submitted && !cnpjValido && <small className="p-invalid">CNPJ ou CPF inválido.</small>}
										</div>
									</div>

									<div className="col-6">
										<div className="p-field">
											<span className="p-float-label p-input-icon-right">
												<i className="pi pi-mobile" />
												<InputText id="telefone" placeholder="Telefone*" maxLength="15" value={formatacao.formatarTelefone(model.telefone)} onChange={(e) => onInputChange(e, 'telefone')} required className={classNames({ 'p-invalid': submitted && !model.telefone })} />
												{submitted && !model.telefone && <small className="p-invalid">Celular obrigatório.</small>}
											</span>
										</div>
									</div>

									<div className="col-12">
										<div className="p-field">
											<span className="p-float-label p-input-icon-right">
												<i className="pi pi-envelope" />
												<InputText placeholder="Email*" id="email" onBlur={(e) => validateEmail(e.target.value)} onChange={(e) => onInputChange(e, 'email')} required className={classNames({ 'p-invalid': submitted && !emailValido })} />
												{submitted && !emailValido && <small className="p-invalid">Email inválido: exemplo nome@exemplo.com</small>}
											</span>
										</div>
									</div>

									<div className="col-6">
										<div className="p-field">
											<span className="p-float-label p-input-icon-right">
												<i className="pi pi-globe" />
												<InputText id="nomeCliente" placeholder="Apelido da Empresa*" onBlur={(e) => validateCliente(e.target.value)} onChange={(e) => onInputChange(e, 'nomeCliente')} required className={classNames({ 'p-invalid': submitted && !clienteValido })} />
												{submitted && !clienteValido && <small className="p-invalid">Minímo 4 caracteres, somente letras e números, todas minúsculas e sem espaços.</small>}
											</span>
										</div>
									</div>

									<div className="col-6">
										<div className="p-field">
											<span className="p-float-label p-input-icon-right">
												<i className="pi pi-user" />
												<AutoComplete placeholder="Usuário líder" id="usuario" value={model.usuarioLider} onChange={(e) => onInputChange(e, 'usuarioLider')} suggestions={autoFilteredValueUsuario} completeMethod={procurarUsuario} field="nomeUsuario" required className={classNames({ 'p-invalid': submitted && !model.usuarioLider })} />
												{submitted && !model.usuarioLider && <small className="p-invalid">Usuário obrigatório</small>}
											</span>
										</div>
									</div>

									<div className="grid p-fluid col-12">
										<div className="col-5">
											{/* <div className="col-12 md:col-11"> */}
											<Dropdown value={model.plano} options={planos} onChange={(e) => onInputChange(e, 'plano')} optionLabel="nome" placeholder="Selecione um Plano" />
											{submitted && !model.plano && <small className="p-invalid">Plano Obrigatório</small>}
											{/* </div> */}
										</div>

										<div className="col-3">
											<div className="p-field">
												{/* <InputNumber inputId="colaboradores" value={model.quantColaboradores} onValueChange={(e) => onInputChange(e, 'quantColaboradores')} mode="decimal" showButtons min={0} max={100} /> */}
												
													<InputText id="colaboradores" value={model.quantColaboradores} onChange={(e) => onInputChange(e, 'quantColaboradores')} required className={classNames({ 'p-invalid': submitted && !model.quantColaboradores })} />
													<small id="colaboradores-help" className="p-d-block">Colaboradores</small>
													{submitted && !model.quantColaboradores && <small className="p-invalid">Quantidade Obrigatória.</small>}
											</div>
										</div>

										<div className="col-4">
											<label htmlFor="valor" style={{ color: 'green', fontWeight: 'bold' }}>{valorEstimado}</label>
										</div>
									</div>

									<div className="col-6">
										<Button label="Criar" icon="pi pi-check" onClick={cadastrar} />
									</div>
									<div className="col-6">
										<Button label="Dashboard" icon="pi pi-arrow-left" onClick={goDashboard} />
									</div>

									<div className="col-12">
										<Messages ref={message} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
