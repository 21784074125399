import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import BTInativar from '../components/BTInativar';
import BTNovo from '../components/BTNovo';
import BTGravar from '../components/BTGravar';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { AutoComplete } from 'primereact/autocomplete';
import { Checkbox } from 'primereact/checkbox';
import { SelectButton } from 'primereact/selectbutton';
import ConsultaFixaGenerica from '../components/consultas/ConsultaFixaGenerica';

const ConfiguracaoCadastroRep = () => {

    let emptyModel = {
        id: null,
        equipamento: null,
        marca: null,
        modelo: null,
        empresa: null,
        nome: '',
        localizacao: '',
        ip: '',
        porta: 51000,
        numeroserie: '',
        status: 0, //0 é ativo
        importa: 1,
        autenticacao: '**AUTENTICACAO**',
        desativarPing: 1,
        informarIPHost: 1,
        dataS: '',
    };

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [lista, setLista] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [autoFilteredValueEmpresa, setAutoFilteredValueEmpresa] = useState([]);
    const [autoValueEmpresa, setAutoValueEmpresa] = useState(null);
    const [autoFilteredValueEquipamento, setAutoFilteredValueEquipamento] = useState([]);
    const [autoValueEquipamento, setAutoValueEquipamento] = useState(null);
    const optionsComunicacao = ['IP', 'Host'];

    const colunas = [
        { field: 'empresa.fantasia', header: 'Empresa', headerStyle: { minWidth: '15rem' } },
        { field: 'equipamento.identificacaoGeral', header: 'Equipamento', headerStyle: { maxWidth: '5rem' } },
        { field: 'nome', header: 'Nome', headerStyle: { maxWidth: '5rem' } },
        { field: 'ip', header: 'IP/Host', headerStyle: { maxWidth: '5rem' } },
        { field: 'porta', header: 'Porta', headerStyle: { maxWidth: '5rem' } },
        { field: 'statusS', header: 'Status', headerStyle: { maxWidth: '5rem' } },
        { field: 'numeroserie', header: 'Número de Série', headerStyle: { maxWidth: '5rem' } },
    ];

    const message = useRef();

    useEffect(() => {

        const buscaEmpresas = () => {
            api
                .get("/empresas")
                .then(response => {
                    model.empresa = response.data[0];
                    setAutoValueEmpresa(response.data)
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaCadastroRep = () => {
            api
                .get("/regras/configuracoes/cadastrorep")
                .then(response => {
                    setLista(response.data)
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaInfoRep = () => {
            api
                .get("/inforep")
                .then(response => {
                    setAutoValueEquipamento(response.data)
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        buscaEmpresas();
        buscaCadastroRep();
        buscaInfoRep();

    }, []);

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <BTGravar setSubmitted={setSubmitted} cadastrar={cadastrar} />
                    <BTNovo setSubmitted={setSubmitted} setModel={setModel} emptyModel={emptyModel} />
                    <BTInativar inativar={inativar} />
                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    const procurarEmpresas = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValueEmpresa([...autoValueEmpresa]);
            }
            else {
                setAutoFilteredValueEmpresa(autoValueEmpresa.filter((em) => {
                    return em.fantasia.toLowerCase().startsWith(event.query.toLowerCase());
                }));
            }
        }, 250);
    };

    const procurarEquipamentos = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValueEquipamento([...autoValueEquipamento]);
            }
            else {
                setAutoFilteredValueEquipamento(autoValueEquipamento.filter((em) => {
                    return em.identificacaoGeral.toLowerCase().startsWith(event.query.toLowerCase());
                }));
            }
        }, 250);
    };


    const onInputChange = (e, descricao) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        if (descricao === "equipamento") {
            if (e.value.id === 4) {//se for uma BATIDA VIA LINK (REP-P)
                _model['ip'] = "NUVEM";
                _model['nome'] = "BATIDA VIA LINK";
                _model['numeroserie'] = "INPI";
            }
        }
        setModel(_model);
    }

    const onCheckboxChange = (e, descricao) => {
        const val = (e.value) || '';
        let _model = { ...model };
        let valor = val === 1 ? 0 : 1;
        _model[`${descricao}`] = valor;
        setModel(_model);
    };

    const selecao = (e) => {
        setModel(e.value);
    }

    const cadastrar = () => {
        if (model.empresa && model.equipamento
            && model.nome.trim() && model.ip.trim()
            && model.autenticacao.trim() && model.numeroserie.trim()) {
            if (model.id) { //alterar
                api
                    .put("/regras/configuracoes/cadastrorep/" + model.id, {
                        id: model.id,
                        equipamento: model.equipamento,
                        nome: model.nome,
                        localizacao: model.localizacao,
                        ip: model.ip,
                        porta: model.porta,
                        numeroserie: model.numeroserie,
                        status: model.status,
                        importa: model.importa,
                        autenticacao: model.autenticacao,
                        desativarPing: model.desativarPing,
                        informarIPHost: model.informarIPHost,
                        empresa: model.empresa,
                    })
                    .then(response => {
                        setLista(response.data);
                        addMessage("success", Mensagens.CADASTRO_ALTERAR);
                    })
                    .catch(erro => {
                        if (erro.response) {
                            addMessage("error", erro.response.data.message.message);
                        } else {
                            addMessage("error", Mensagens.ERRO);
                        }
                    })
            } else { //cadastrar
                api
                    .post("/regras/configuracoes/cadastrorep", {
                        id: model.id,
                        equipamento: model.equipamento,
                        nome: model.nome,
                        localizacao: model.localizacao,
                        ip: model.ip,
                        porta: model.porta,
                        numeroserie: model.numeroserie,
                        status: model.status,
                        importa: model.importa,
                        autenticacao: model.autenticacao,
                        desativarPing: model.desativarPing,
                        informarIPHost: model.informarIPHost,
                        empresa: model.empresa,
                    })
                    .then(response => {
                        setLista(response.data);
                        addMessage("success", Mensagens.CADASTRO_INCLUIR);
                    })
                    .catch(erro => {
                        if (erro.response) {
                            addMessage("error", erro.response.data.message);
                        } else {
                            addMessage("error", Mensagens.ERRO);
                        }
                    })
            }
        }
    }

    const inativar = () => {
        if (model.id) {
            api
                .delete("/regras/configuracoes/cadastrorep/" + model.id)
                .then(response => {
                    addMessage("success", Mensagens.CADASTRO_INATIVAR);
                    setLista(response.data);
                    setSubmitted(false);
                    setModel(emptyModel);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        } else {
            addMessage("warn", Mensagens.SELECIONE);
        }

    }

    const definirComunicacao = (e, descricao) => {
        const val = (e.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val === "IP" ? 1 : 2;
        setModel(_model);
    }

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="empresa" className="col-12 md:col-1 md:mb-0">Empresa*</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="empresa" dropdown value={model.empresa} onChange={(e) => onInputChange(e, 'empresa')} suggestions={autoFilteredValueEmpresa} completeMethod={procurarEmpresas} field="fantasia" required className={classNames({ 'p-invalid': submitted && !model.empresa })} />
                            {submitted && !model.empresa && <small className="p-invalid">Empresa Obrigatória</small>}
                        </div>

                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="equipamento" className="col-12 md:col-1 md:mb-0">Equipamento*</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="equipamento" dropdown value={model.equipamento} onChange={(e) => onInputChange(e, 'equipamento')} suggestions={autoFilteredValueEquipamento} completeMethod={procurarEquipamentos} field="identificacaoGeral" required className={classNames({ 'p-invalid': submitted && !model.equipamento })} />
                            {submitted && !model.equipamento && <small className="p-invalid">Equipamento Obrigatório</small>}
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="nome" className="col-12 mb-2 md:col-1 md:mb-0">Nome*</label>
                        <div className="col-12 md:col-11">
                            <InputText id="nome" maxLength={45} placeholder="Máximo 45 caracteres" value={model.nome} onChange={(e) => onInputChange(e, 'nome')} required className={classNames({ 'p-invalid': submitted && !model.nome })} />
                            {submitted && !model.nome && <small className="p-invalid">Obrigatório</small>}
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="localizacao" className="col-12 mb-2 md:col-1 md:mb-0">Localização</label>
                        <div className="col-12 md:col-11">
                            <InputText id="localizacao" maxLength={50} placeholder="Máximo 50 caracteres" value={model.localizacao} onChange={(e) => onInputChange(e, 'localizacao')} />
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="ip" className="col-12 md:col-1 md:mt-2">IP*</label>
                        <div className="field col-12 md:col-9 mb-2">
                            <InputText id="ip" maxLength={100} placeholder="Máximo 100 caracteres" value={model.ip} onChange={(e) => onInputChange(e, 'ip')} required className={classNames({ 'p-invalid': submitted && !model.ip })} />
                            {submitted && !model.ip && <small className="p-invalid">Obrigatório</small>}
                        </div>
                        <div className="field col-12 md:col-2 mb-2">
                            <SelectButton value={model.informarIPHost === 1 ? "IP" : "Host"} options={optionsComunicacao} onChange={(e) => definirComunicacao(e, "informarIPHost")} />
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="porta" className="col-12 md:col-1 md:mt-2">Porta*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputText id="porta" value={model.porta} onChange={(e) => onInputChange(e, 'porta')} required className={classNames({ 'p-invalid': submitted && !model.porta })} />
                            {submitted && !model.porta && <small className="p-invalid">Obrigatório</small>}
                        </div>

                        <label htmlFor="autenticacao" className="col-12 md:col-1 md:mt-2">Autenticação*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputText id="autenticacao" maxLength={50} placeholder="Máximo 50 caracteres" value={model.autenticacao} onChange={(e) => onInputChange(e, 'autenticacao')} required className={classNames({ 'p-invalid': submitted && !model.autenticacao })} />
                            {submitted && !model.autenticacao && <small className="p-invalid">Obrigatório</small>}
                        </div>
                        <label htmlFor="nserie" className="col-12 md:col-1 md:mt-2">Número de Série*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputText id="nserie" maxLength={45} placeholder="Máximo 45 caracteres" value={model.numeroserie} onChange={(e) => onInputChange(e, 'numeroserie')} required className={classNames({ 'p-invalid': submitted && !model.numeroserie })} />
                            {submitted && !model.numeroserie && <small className="p-invalid">Obrigatório</small>}
                        </div>
                        <div className="col-3 grid mt-2">
                            <div className="col-12 md:col-3">
                                <div className="field-checkbox">
                                    <Checkbox inputId="checkOption1" name="option" value={model.status} checked={model.status === 0} onChange={(e) => onCheckboxChange(e, "status")} />
                                    <label htmlFor="checkOption1">Ativo</label>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="field-checkbox">
                                    <Checkbox inputId="checkOption2" name="option" value={model.importa} checked={model.importa === 1} onChange={(e) => onCheckboxChange(e, "importa")} />
                                    <label htmlFor="checkOption2">Importar</label>
                                </div>
                            </div>
                            <div className="col-12 md:col-5">
                                <div className="field-checkbox">
                                    <Checkbox inputId="checkOption3" name="option" value={model.desativarPing} checked={model.desativarPing === 1} onChange={(e) => onCheckboxChange(e, "desativarPing")} />
                                    <label htmlFor="checkOption3">Desativar Ping</label>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                    <ConsultaFixaGenerica
                        lista={lista}
                        model={model}
                        selecao={selecao}
                        camposFiltroGlobal={['empresa.fantasia', 'equipamento.identificacaoGeral', 'ip', 'nome', 'numeroserie']}
                        colunas={colunas}
                    />


                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ConfiguracaoCadastroRep, comparisonFn);
