import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Messages } from 'primereact/messages';
import { AutoComplete } from 'primereact/autocomplete';
import { Image } from "primereact/image";
import { FileUpload } from 'primereact/fileupload';
import Mensagens from '../globais/Mensagens';
import BTInativar from '../components/BTInativar';
import BTNovo from '../components/BTNovo';
import BTGravar from '../components/BTGravar';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import ConsultaGenerica from '../components/consultas/ConsultaGenerica';

const Empresas = () => {

    let emptyModel = {
        id: null,
        codigoTerceiros: '',
        razao: '',
        fantasia: '',
        cnpj: '',
        cei: '',
        denominacao: '',
        ramoAtividade: '',
        endereco: '',
        numero: '',
        bairro: '',
        municipio: null,
        fone: '',
        enderecoLogo: '',
    };

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [lista, setLista] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const message = useRef();
    const [autoFilteredValueMunicipio, setAutoFilteredValueMunicipio] = useState([]);
    const [autoValueMunicipio, setAutoValueMunicipio] = useState(null);
    // const [autoValue, setAutoValue] = useState(null);
    const [url, setUrl] = useState('http://18.231.118.113:8080/api/v1/empresas/1');
    const [cnpjValido, setCnpjValido] = useState(false);

    const colunas = [
        { field: 'razao', header: 'Razão', headerStyle: { minWidth: '15rem' } },
        { field: 'fantasia', header: 'Fantasia', headerStyle: { minWidth: '15rem' } },
        { field: 'cnpj', header: 'CNPJ', headerStyle: { minWidth: '15rem' } },
    ];

    useEffect(() => {
        const buscaMunicipios = () => {
            api
                .get("/municipios")
                .then(response => setAutoValueMunicipio(response.data))
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
        buscaMunicipios();
    }, []);


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <BTGravar setSubmitted={setSubmitted} cadastrar={cadastrar} />
                    <BTNovo setSubmitted={setSubmitted} setModel={setModel} emptyModel={emptyModel} />
                    <BTInativar inativar={inativar} />

                    <ConsultaGenerica
                        lista={lista}
                        model={model}
                        setModel={setModel}
                        onConsultar={consultar}
                        toast={toast}
                        colunas={colunas}
                        titulo="Consultar"
                        mensagemSelecao="Empresa Selecionada"
                        camposFiltroGlobal={['razao', 'fantasia', 'cnpj']}
                        onModelSelecionado={onModelSelecionado}
                        life={3000}
                        largura='700px'
                    />

                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const onModelSelecionado = (model) => {
        return `${model.fantasia}`;
    };

    const onInputChange = (e, campo) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...model };
        _model[`${campo}`] = val;
        setModel(_model);
        if (campo === "empresa") {
            setUrl('http://18.231.118.113:8080/api/v1/empresas/' + _model.empresa.id);
        }
    }

    const validateCnpj = (num) => {
        let cpfValido = cpf.isValid(num);
        let cnpjValido = cnpj.isValid(num);
        if (cpfValido || cnpjValido) {
            setCnpjValido(true);
        } else {
            setCnpjValido(false);
        }
    }

    const cadastrar = () => {
        if (model.razao.trim() && model.fantasia.trim() && model.cnpj.trim() &&
            model.bairro.trim() && model.endereco.trim() && model.fone.trim() &&
            model.ramoAtividade.trim() && cnpjValido) {
            if (model.id) {
                api
                    .put("/empresas/" + model.id, {
                        razao: model.razao,
                        fantasia: model.fantasia,
                        cnpj: model.cnpj,
                        municipio: model.municipio,
                        bairro: model.bairro,
                        cei: model.cei,
                        denominacao: model.denominacao,
                        endereco: model.endereco,
                        numero: model.numero,
                        fone: model.fone,
                        ramoAtividade: model.ramoAtividade,
                        codigoTerceiros: model.codigoTerceiros,
                    })
                    .then(response => {
                        setModel(response.data);
                        addMessage("success", Mensagens.CADASTRO_ALTERAR);
                    })
                    .catch(erro => {
                        addMessage("error", Mensagens.ERRO);
                    })
            }
            else {
                api
                    .post("/empresas", {
                        razao: model.razao,
                        fantasia: model.fantasia,
                        cnpj: model.cnpj,
                        municipio: model.municipio,
                        bairro: model.bairro,
                        cei: model.cei,
                        denominacao: model.denominacao,
                        endereco: model.endereco,
                        numero: model.numero,
                        fone: model.fone,
                        ramoAtividade: model.ramoAtividade,
                        codigoTerceiros: model.codigoTerceiros,
                    })
                    .then(response => {
                        setModel(response.data);
                        addMessage("success", Mensagens.CADASTRO_INCLUIR);
                    })
                    .catch(erro => {
                        addMessage("error", Mensagens.ERRO);
                    })
            }
        }
    }

    const inativar = () => {
        if (model.id) {
            api
                .delete("/empresas/" + model.id)
                .then(response => {
                    addMessage("success", Mensagens.CADASTRO_INATIVAR);
                    setSubmitted(false);
                    setModel(emptyModel);
                })
                .catch(erro => {
                    console.log(erro)
                    addMessage("error", Mensagens.ERRO);
                })
        } else {
            addMessage("warn", Mensagens.SELECIONE);
        }

    }

    const consultar = () => {
        api
            .get("/empresas")
            .then(response => setLista(response.data))
            .catch(() => {
                addMessage("error", Mensagens.ERRO);
            })
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    const procurarMunicipios = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValueMunicipio([...autoValueMunicipio]);
            }
            else {
                setAutoFilteredValueMunicipio(autoValueMunicipio.filter((em) => {
                    return em.nome.toLowerCase().startsWith(event.query.toLowerCase());
                }));
            }
        }, 250);
    };

    const onUpload = () => {
        toast.current.show({ severity: 'info', summary: 'Sucesso', detail: 'Enviado para API...falta lógica para salvar.', life: 3000 });
    }



    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="razao" className="col-12 md:col-1 md:mb-0">Razão*</label>
                        <div className="col-12 md:col-11">
                            <InputText id="razao" maxLength={40} placeholder="Máximo 40 caracteres" value={model.razao} onChange={(e) => onInputChange(e, 'razao')} required autoFocus className={classNames({ 'p-invalid': submitted && !model.razao })} />
                            {submitted && !model.razao && <small className="p-invalid">Razão Obrigatória</small>}
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="fantasia" className="col-12 md:col-1 md:mb-0">Fantasia*</label>
                        <div className="col-12 md:col-11">
                            <InputText id="fantasia" maxLength={40} placeholder="Máximo 40 caracteres" value={model.fantasia} onChange={(e) => onInputChange(e, 'fantasia')} required className={classNames({ 'p-invalid': submitted && !model.fantasia })} />
                            {submitted && !model.fantasia && <small className="p-invalid">Fantasia Obrigatória</small>}
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">

                        <label htmlFor="cnpj" className="col-12 md:col-1 md:mt-2">CNPJ/CPF*</label>
                        <div className="field col-12 md:col-5 mb-2">
                            <InputText id="cnpj" maxLength={20} placeholder="Máximo 20 caracteres" value={cnpj.format(model.cnpj)} onBlur={(e) => validateCnpj(e.target.value)} onChange={(e) => onInputChange(e, 'cnpj')} required className={classNames({ 'p-invalid': submitted && !cnpjValido })} />
                            {submitted && !cnpjValido && <small className="p-invalid">CNPJ ou CPF inválido.</small>}
                        </div>

                        {/* <label htmlFor="cnpj" className="col-12 md:col-1 md:mt-2">CNPJ/CPF*</label>
                        <div className="field col-12 md:col-5 mb-2">
                            <InputText id="cnpj" value={model.cnpj} onChange={(e) => onInputChange(e, 'cnpj')} required className={classNames({ 'p-invalid': submitted && !model.cnpj })} />
                            {submitted && !model.cnpj && <small className="p-invalid">CNPJ/CPF Obrigatório</small>}
                        </div> */}

                        <label htmlFor="cei" className="col-12 md:col-1 md:mt-2">CEI</label>
                        <div className="field col-12 md:col-5 mb-2">
                            <InputText id="cei" maxLength={20} placeholder="Máximo 20 caracteres" value={model.cei} onChange={(e) => onInputChange(e, 'cei')} />
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="denominacao" className="col-12 md:col-1 md:mb-0">Denominação</label>
                        <div className="col-12 md:col-11">
                            <InputText id="denominacao" maxLength={40} placeholder="Máximo 40 caracteres" value={model.denominacao} onChange={(e) => onInputChange(e, 'denominacao')} />
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="ramoAtividade" className="col-12 md:col-1 md:mb-0">Ramo Atividade*</label>
                        <div className="col-12 md:col-11">
                            <InputText id="ramoAtividade" maxLength={200} placeholder="Máximo 200 caracteres" value={model.ramoAtividade} onChange={(e) => onInputChange(e, 'ramoAtividade')} required className={classNames({ 'p-invalid': submitted && !model.ramoAtividade })} />
                            {submitted && !model.ramoAtividade && <small className="p-invalid">Ramo Atividade Obrigatório</small>}
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="endereco" className="col-1 md:col-1 md:mt-2">Endereço*</label>
                        <div className="field col-12 md:col-8 mb-2">
                            <InputText id="endereco" maxLength={60} placeholder="Máximo 60 caracteres" value={model.endereco} onChange={(e) => onInputChange(e, 'endereco')} required className={classNames({ 'p-invalid': submitted && !model.endereco })} />
                            {submitted && !model.endereco && <small className="p-invalid">Endereço Obrigatório</small>}
                        </div>

                        {/* <label htmlFor="numero" className="col-1 md:col-1 md:mt-2">Número*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputText id="numero" value={model.numero} onChange={(e) => onInputChange(e, 'numero')} required className={classNames({ 'p-invalid': submitted && !model.numero })} />
                            {submitted && !model.numero && <small className="p-invalid">Número Obrigatório</small>}
                        </div> */}

                        <label htmlFor="bairro" className="col-1 md:col-1 md:mt-2">Bairro*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputText id="bairro" maxLength={30} placeholder="Máximo 30 caracteres" value={model.bairro} onChange={(e) => onInputChange(e, 'bairro')} required className={classNames({ 'p-invalid': submitted && !model.bairro })} />
                            {submitted && !model.bairro && <small className="p-invalid">Bairro Obrigatório</small>}
                        </div>
                    </div>

                    {/* <div className="field grid p-fluid mb-2">
                        <label htmlFor="bairro" className="col-12 md:col-1 md:mb-0">Bairro*</label>
                        <div className="col-12 md:col-11">
                            <InputText id="bairro" value={model.bairro} onChange={(e) => onInputChange(e, 'bairro')} required className={classNames({ 'p-invalid': submitted && !model.bairro })} />
                            {submitted && !model.bairro && <small className="p-invalid">Bairro Obrigatório</small>}
                        </div>
                    </div> */}

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="municipio" className="col-12 md:col-1 md:mb-0">Cidade</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="municipio" dropdown value={model.municipio} onChange={(e) => onInputChange(e, 'municipio')} suggestions={autoFilteredValueMunicipio} completeMethod={procurarMunicipios} field="nome" />
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="fone" className="col-12 md:col-1 md:mt-2">Fone*</label>
                        <div className="field col-12 md:col-5 mb-2">
                            <InputText id="fone" maxLength={15} placeholder="Máximo 15 caracteres" value={model.fone} onChange={(e) => onInputChange(e, 'fone')} required className={classNames({ 'p-invalid': submitted && !model.fone })} />
                            {submitted && !model.fone && <small className="p-invalid">Fone Obrigatório</small>}
                        </div>

                        <label htmlFor="codigoTerceiros" className="col md:col-1 md:mt-0">Código Terceiros</label>
                        <div className="field col-12 md:col-5 mb-2">
                            <InputText id="codigoTerceiros" value={model.codigoTerceiros} onChange={(e) => onInputChange(e, 'codigoTerceiros')} />
                        </div>
                    </div>

                    <div className="flex justify-content-center">
                        <Image src="assets/demo/images/galleria/semimagem.gif" alt="galleria" width={250} preview />
                    </div>

                    <div className="flex justify-content-center">
                        <FileUpload name="files" mode='basic' url={url} onUpload={onUpload} multiple accept="image/*" maxFileSize={1000000} auto chooseLabel="Escolher" />
                    </div>

                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                </div >
            </div >
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Empresas, comparisonFn);
