import React from 'react';
import { Button } from 'primereact/button';
import Botoes from '../globais/Botoes';


export default function BTNovo(props) {

    const limpar = () => {
        props.setSubmitted(false)
        props.setModel(props.emptyModel);
        try {
            props.setCampoDesativado(false);
        } catch (error) {

        }
    }

    return (
        <>
            <Button label={Botoes.NOVO} icon="pi pi-refresh" onClick={limpar} className="p-button-warning mr-2 mt-1 mb-1" />
        </>
    )

}