import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';
import { Fieldset } from 'primereact/fieldset';
import Botoes from '../globais/Botoes';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';

const FechamentoMes = () => {

    let emptyModel = {
        empresa: null,
        mes: null,
        ano: null,
        dataFechamentoS: '',
        usuarioFechamento: null,
        mesFechado: '',
        anoFechado: '',
        mesVigente: null,
        anoVigente: null,
        dataReaberturaS: '',
        usuarioReabertura: null
    };
    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [submitted, setSubmitted] = useState(false);
    const message = useRef();
    const [autoFilteredValueEmpresa, setAutoFilteredValueEmpresa] = useState([]);
    const [autoValueEmpresa, setAutoValueEmpresa] = useState(null);
    const [empresa, setEmpresa] = useState(null);
    const [value, setValue] = useState(0);
    const interval = useRef(null);

    useEffect(() => {
        const buscaEmpresas = () => {
            api
                .get("/empresas")
                .then(response => {
                    setAutoValueEmpresa(response.data);
                    setEmpresa(response.data[0]);
                    buscarMesVigente(response.data[0]);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
        buscaEmpresas();
    }, []);

    useEffect(() => {
        if (submitted) {
            let val = value;
            interval.current = setInterval(() => {
                val += Math.floor(Math.random() * 5) + 1;

                if (val >= 100) {
                    val = 97;
                    clearInterval(interval.current);
                }
                setValue(val);
            }, 6000);

            return () => {
                if (interval.current) {
                    clearInterval(interval.current);
                    interval.current = null;
                }
            }
        }
    }, [value, submitted]);

    const buscarMesVigente = (empresa) => {
        if(empresa !== null){
            api
            .get("/fechamentoMes?empresa=" + empresa.id)
            .then(response => {
                setModel(response.data);
            })
            .catch(() => {
                addMessage("error", Mensagens.ERRO);
            })
        }
    }

    const onChangeEmpresa = (empresa) => {
        buscarMesVigente(empresa);
        setEmpresa(empresa);
    }

    const procurarEmpresas = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueEmpresa([...autoValueEmpresa]);
                }
                else {
                    setAutoFilteredValueEmpresa(autoValueEmpresa.filter((em) => {
                        return em.fantasia.toLowerCase().startsWith(event.query.toLowerCase());

                    }));
                }
            } catch (e) {
            }

        }, 250);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={Botoes.AUTORIZAR_FECHAMENTO} icon="pi pi-plus" onClick={realizarFechamento} className="p-button-success mr-2 mt-1 mb-1" />
                    <Button label={Botoes.REABRIR_ULTIMO} icon="pi pi-minus" onClick={reabrirFechamento} className="p-button-danger mr-2 mt-1 mb-1" />
                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const realizarFechamento = () => {
            setValue(7);
            setSubmitted(true);
            api
                .post("/fechamentoMes", {
                    mes: model.mes,
                    ano: model.ano,
                    dataFechamentoS: model.dataFechamentoS,
                    usuarioFechamento: model.usuarioFechamento,
                    mesVigente: model.mesVigente,
                    anoVigente: model.anoVigente,
                    empresa: empresa
                }
                )
                .then(response => {
                    if (response.data.message) {
                        addMessage("success", response.data.message);
                    } else {
                        setModel(response.data);
                        addMessage("success", Mensagens.OPERACAO_SUCESSO);
                    }
                    setSubmitted(false);
                    setValue(100);
                })
                .catch(error => {
                    if (error.response.data.message) {
                        addMessage("error", error.response.data.message);
                    } else {
                        addMessage("error", Mensagens.ERRO);
                    }
                    setSubmitted(false);
                    setValue(0);
                })
    }

    const reabrirFechamento = () =>{
        setValue(10);
        setSubmitted(true);
        if(model.id){
            api
            .put("/fechamentoMes/"+model.id,{
               mes: model.mes,
               ano: model.ano,
               dataFechamentoS: model.dataFechamentoS,
               usuarioFechamento: model.usuarioFechamento,
               empresa: model.empresa
            })
            .then(response => {
                if (response.data.message) {
                    addMessage("success", response.data.message);
                } else {
                    setModel(response.data);
                    addMessage("success", Mensagens.OPERACAO_SUCESSO);
                }
                setSubmitted(false);
                setValue(100);
            })
            .catch(error => {
                if (error.response.data.message) {
                    addMessage("error", error.response.data.message);
                } else {
                    addMessage("error", Mensagens.ERRO);
                }
                setSubmitted(false);
                setValue(0);
            })
        }else{
            addMessage("error", Mensagens.SEM_FECHAMENTO);
        }

    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn, life: 10000 });
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="empresa" className="col-12 md:col-1 md:mb-0">Empresa*</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="empresa" dropdown value={empresa} onChange={(e) => onChangeEmpresa(e.target.value)} suggestions={autoFilteredValueEmpresa} completeMethod={procurarEmpresas} field="fantasia" required className={classNames({ 'p-invalid': submitted && !empresa })} />
                            {submitted && !model.empresa && <small className="p-invalid">Empresa Obrigatória</small>}
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <Fieldset legend="Mês Vigente" className='col-1 mb-2'>
                            <h1>
                                {
                                    (model.mesVigente != null && model.anoVigente != null)
                                        ? `${model.mesVigente}/${model.anoVigente}`
                                        : 'Sem Fechamento'
                                }
                            </h1>
                        </Fieldset>

                        <Fieldset legend="Informações do Último Fechamento" className='col-10 mb-2'>
                            <div className="col-12 md:col-2 mt-2">
                                <label htmlFor="option1">Mês: {model.mesFechado}</label>
                            </div>
                            <div className="col-12 md:col-2 mt-2">
                                <label htmlFor="option1">Ano: {model.anoFechado}</label>
                            </div>
                            <div className="col-12 md:col-2 mt-2">
                                <label htmlFor="option1">Fechamento: {model.dataFechamentoS}</label>
                            </div>
                            <div className="col-12 md:col-2 mt-2">
                                <label htmlFor="option1">Usuário: {model.usuarioFechamento?.nomeUsuario}</label>
                            </div>
                        </Fieldset>
                    </div>

                    <Fieldset legend="Fechamento do Mês" className='col-12 mb-2'>
                        <p>
                            1) O fechamento resultará na impossibilidade de manipulação do mês.
                        </p>
                        <p>
                            2) Exemplo: Não será possível "incluir batidas".
                        </p>
                        <p>
                            3) O mês vigente será atualizado.
                        </p>
                    </Fieldset>

                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                    <div className="col-12">
                        <div className="col">
                            <ProgressBar value={value} />
                        </div>
                    </div>

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(FechamentoMes, comparisonFn);
