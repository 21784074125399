import React, { useState } from 'react';
import { Button } from 'primereact/button';
import Confirmacao from './Confirmacao';
import Botoes from '../globais/Botoes';


export default function BTAtivar(props) {

    const [displayConfirmation, setDisplayConfirmation] = useState(false);

    const inativarConfirmado = () => {
        props.ativar();
        setDisplayConfirmation(false);
    }

    return (
        <>
            <Button label={Botoes.ATIVAR} icon="pi pi-star" onClick={() => setDisplayConfirmation(true)} className="p-button-success mr-2 mt-1 mb-1" />
            <Confirmacao displayConfirmation = {displayConfirmation} setDisplayConfirmation = {setDisplayConfirmation} inativarConfirmado = {inativarConfirmado}/>
        </>
    )

}
