import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';


export default function Confirmacao(props) {


    const confirmationDialogFooter = (
        <>
            <Button type="button" label="Não" icon="pi pi-times" onClick={() => props.setDisplayConfirmation(false)} className="p-button-text" />
            <Button type="button" label="Sim" icon="pi pi-check" onClick={() => props.inativarConfirmado()} className="p-button-text" autoFocus />
        </>
    )

     
    return (
        <>
            <Dialog header="Confirmação" visible={props.displayConfirmation} onHide={() => props.setDisplayConfirmation(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooter}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    <span>Tem certeza de que deseja continuar?</span>
                </div>
            </Dialog>
        </>
    )

}
