import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';
import BTInativar from '../components/BTInativar';
import { InputText } from 'primereact/inputtext';
import BTGravar from '../components/BTGravar';
import BTNovo from '../components/BTNovo';
import ConsultaGenerica from '../components/consultas/ConsultaGenerica';

const BatidaViaLinkLocalizacao = () => {

    let emptyModel = {
        id: null,
        empresa: { id: 0, fantasia: '' },
        latitude: '',
        longitude: '',
        nome: '',
        descricao: '',
        raio: 1000,
    };

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [submitted, setSubmitted] = useState(false);
    const message = useRef();
    const [autoFilteredValueEmpresa, setAutoFilteredValueEmpresa] = useState([]);
    const [autoValueEmpresa, setAutoValueEmpresa] = useState(null);
    const [lista, setLista] = useState(null);
    const [imageUrl, setImageUrl] = useState("");

    const colunas = [
        { field: 'empresa.fantasia', header: 'Empresa', headerStyle: { minWidth: '15rem' } },
        { field: 'nome', header: 'Nome', headerStyle: { minWidth: '15rem' } },
        { field: 'latitude', header: 'Latitude', headerStyle: { minWidth: '15rem' } },
        { field: 'longitude', header: 'Longitude', headerStyle: { minWidth: '15rem' } },
    ];

    useEffect(() => {
        const buscaEmpresas = () => {
            api
                .get("/empresas")
                .then(response => {
                    setAutoValueEmpresa(response.data)
                    model.empresa = response.data[0];
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
        buscaEmpresas();
    }, []);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                const lat = position.coords.latitude;
                const lon = position.coords.longitude;
                model.latitude = lat;
                model.longitude = lon;
                const zoom = 19;
                const url = `https://www.openstreetmap.org/?mlat=${lat}&mlon=${lon}&zoom=${zoom}`;
                // const url = `https://www.openstreetmap.org/#map=${zoom}/${lat}/${lon}/&layers=N`;
                setImageUrl(url);
            });
        } else {
            console.log("Localizacao nao disponivel no navegador!");
        }
    }, []);


    const onInputChange = (e, descricao) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
    }

    const procurarEmpresas = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueEmpresa([...autoValueEmpresa]);
                }
                else {
                    setAutoFilteredValueEmpresa(autoValueEmpresa.filter((em) => {
                        return em.fantasia.toLowerCase().startsWith(event.query.toLowerCase());

                    }));
                }
            } catch (e) {
            }

        }, 250);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <BTGravar setSubmitted={setSubmitted} cadastrar={cadastrar} />
                    <BTNovo setSubmitted={setSubmitted} setModel={setModel} emptyModel={emptyModel} />
                    <BTInativar inativar={inativar} />

                    <ConsultaGenerica
                        lista={lista}
                        model={model}
                        setModel={setModel}
                        onConsultar={consultar}
                        toast={toast}
                        colunas={colunas}
                        titulo="Consultar"
                        mensagemSelecao="Localização Selecionada"
                        camposFiltroGlobal={['nome','empresa.fantasia', 'latitude', 'longitude']}
                        onModelSelecionado = {onModelSelecionado}
                        life={3000}
                        largura='900px'
                    />

                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const onModelSelecionado = (model) => {
        return `${model.descricao}`;
    };

    const cadastrar = () => {
        if (model.nome.trim()) {
            // if (model.id) {
            //     api
            //         .put("/batidaViaLink/localizacoes/" + model.id, {
            //             empresa: model.empresa,
            //             nome: model.nome,
            //             descricao: model.descricao,
            //             latitude: model.latitude,
            //             longitude: model.longitude
            //         })
            //         .then(response => {
            //             setModel(response.data);
            //             addMessage("success", Mensagens.CADASTRO_ALTERAR);
            //         })
            //         .catch(() => {
            //             addMessage("error", Mensagens.ERRO);
            //         })
            // } else {
            api
                .post("/batidaViaLink/localizacoes", {
                    empresa: model.empresa,
                    nome: model.nome,
                    descricao: model.descricao,
                    latitude: model.latitude,
                    longitude: model.longitude,
                    raio: model.raio
                })
                .then(response => {
                    setModel(response.data);
                    addMessage("success", Mensagens.OPERACAO_SUCESSO);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
        // }
    }

    const inativar = () => {
        if (model.id) {
            api
                .delete("/batidaViaLink/localizacoes/" + model.id)
                .then(() => {
                    addMessage("success", Mensagens.CADASTRO_INATIVAR);
                    setSubmitted(false);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        } else {
            addMessage("warn", Mensagens.SELECIONE);
        }
    }

    const consultar = () => {
        api
            .get("/batidaViaLink/localizacoes")
            .then(response => setLista(response.data))
            .catch(() => {
                addMessage("error", Mensagens.ERRO);
            })
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    const handleMapViewClick = () => {
        const zoom = 19;
        const url = `https://www.openstreetmap.org/?mlat=${model.latitude}&mlon=${model.longitude}&zoom=${zoom}`;
        // const url = `https://www.openstreetmap.org/#map=${zoom}/${model.latitude}/${model.longitude}/&layers=N`;
        setImageUrl(url);

        // // Redirecionar para a página do mapa
        // window.open(url, '_blank');
    }

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="empresa" className="col-12 md:col-1 md:mb-0">Empresa*</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="empresa" dropdown value={model.empresa} onChange={(e) => onInputChange(e, 'empresa')} suggestions={autoFilteredValueEmpresa} completeMethod={procurarEmpresas} field="fantasia" required className={classNames({ 'p-invalid': submitted && !model.empresa })} />
                            {submitted && !model.empresa && <small className="p-invalid">Empresa Obrigatória</small>}
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="nome" className="col-12 md:col-1 md:mt-2">Nome*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputText id="nome" maxLength={45} placeholder="Máximo 45 caracteres" value={model.nome} onChange={(e) => onInputChange(e, 'nome')} required className={classNames({ 'p-invalid': submitted && !model.nome })} />
                            {submitted && !model.nome && <small className="p-invalid">Nome Obrigatório</small>}
                        </div>

                        <label htmlFor="descricao" className="col-12 md:col-1 md:mt-2">Descrição*</label>
                        <div className="field col-12 md:col-8 mb-2">
                            <InputText id="descricao" maxLength={100} placeholder="Máximo 100 caracteres" value={model.descricao} onChange={(e) => onInputChange(e, 'descricao')} />
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="latitude" className="col-12 md:col-1 md:mt-2">Latitude*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputText id="latitude" maxLength={45} placeholder="Máximo 45 caracteres" value={model.latitude} onChange={(e) => onInputChange(e, 'latitude')} required className={classNames({ 'p-invalid': submitted && !model.latitude })} />
                            {submitted && !model.latitude && <small className="p-invalid">Latitude Obrigatória</small>}
                        </div>

                        <label htmlFor="longitude" className="col-12 md:col-1 md:mt-2">Longitude*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputText id="longitude" maxLength={45} placeholder="Máximo 45 caracteres" value={model.longitude} onChange={(e) => onInputChange(e, 'longitude')} required className={classNames({ 'p-invalid': submitted && !model.longitude })} />
                            {submitted && !model.longitude && <small className="p-invalid">Longitude Obrigatória</small>}
                        </div>

                        <label htmlFor="raio" className="col-12 md:col-1 md:mt-2">Raio*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputText id="raio" value={model.raio} onChange={(e) => onInputChange(e, 'raio')} required className={classNames({ 'p-invalid': submitted && !model.raio })} />
                            {submitted && !model.raio && <small className="p-invalid">Raio Obrigatório</small>}
                        </div>
                    </div>

                    <div className="field grid p-fluid">
                        <label htmlFor="localizacao" className="col-12 mb-2 md:col-1 md:mb-0"></label>
                        <div className="col-12 md:col-11 mt-2">
                            <a href={imageUrl} style={{ fontSize: '16px' }} target="_blank" rel="noopener noreferrer" onClick={handleMapViewClick}>
                                Visualizar posição no mapa
                            </a>
                        </div>
                    </div>

                    <div className="col-12 ">
                        <Toast ref={toast} />
                        <Toolbar className="mb-0" left={leftToolbarTemplate}></Toolbar>
                    </div>

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(BatidaViaLinkLocalizacao, comparisonFn);
