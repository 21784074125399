import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import BTInativar from '../components/BTInativar';
import BTNovo from '../components/BTNovo';
import BTGravar from '../components/BTGravar';
import { classNames } from 'primereact/utils';
import { InputMask } from 'primereact/inputmask';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { AutoComplete } from 'primereact/autocomplete';
import ConsultaFixaGenerica from '../components/consultas/ConsultaFixaGenerica';

const EscalasDeTrabalho = () => {

    let emptyModel = {
        id: null,
        dataS: '',
        empresa: null,
        colaborador: null,
        jornada: null,
    };

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [lista, setLista] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [autoFilteredValueEmpresa, setAutoFilteredValueEmpresa] = useState([]);
    const [autoValueEmpresa, setAutoValueEmpresa] = useState(null);
    const [autoFilteredValueColaborador, setAutoFilteredValueColaborador] = useState([]);
    const [autoValueColaborador, setAutoValueColaborador] = useState(null);
    const [autoFilteredValueJornada, setAutoFilteredValueJornada] = useState([]);
    const [autoValueJornada, setAutoValueJornada] = useState(null);

    const colunas = [
        { field: 'dataS', header: 'A partir de', headerStyle: { maxWidth: '5rem' } },
        { field: 'jornada.nome', header: 'Jornada', headerStyle: { minWidth: '15rem' } },
    ];

    const message = useRef();

    useEffect(() => {

        const buscaEmpresas = () => {
            api
                .get("/empresas")
                .then(response => {
                    model.empresa = response.data[0];
                    setAutoValueEmpresa(response.data);
                    buscaColaboradores(response.data[0]);
                    buscaJornadas(response.data[0]);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        buscaEmpresas();

    }, []);

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <BTGravar setSubmitted={setSubmitted} cadastrar={cadastrar} />
                    <BTNovo setSubmitted={setSubmitted} setModel={setModel} emptyModel={emptyModel} />
                    <BTInativar inativar={inativar} />
                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    const procurarEmpresas = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValueEmpresa([...autoValueEmpresa]);
            }
            else {
                setAutoFilteredValueEmpresa(autoValueEmpresa.filter((em) => {
                    return em.fantasia.toLowerCase().startsWith(event.query.toLowerCase());
                }));
            }
        }, 250);
    };

    const procurarColaboradores = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueColaborador([...autoValueColaborador]);
                }
                else {
                    setAutoFilteredValueColaborador(autoValueColaborador.filter((em) => {
                        return em.nome.toLowerCase().startsWith(event.query.toLowerCase());
                    }));
                }
            } catch (e) {
            }
        }, 250);
    };

    const procurarJornadas = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueJornada([...autoValueJornada]);
                }
                else {
                    setAutoFilteredValueJornada(autoValueJornada.filter((em) => {
                        return em.nome.toLowerCase().startsWith(event.query.toLowerCase());
                    }));
                }
            } catch (e) {
            }
        }, 250);
    };

    const buscaColaboradores = (empresa) => {
        try {
            api
                .get("colaboradores/?empresa=" + empresa.id)
                .then(response => {
                    setAutoValueColaborador(response.data);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        } catch (e) {
        }
    }

    const buscaJornadas = (empresa) => {
        try {
            api
                .get("jornadas/?empresa=" + empresa.id)
                .then(response => {
                    setAutoValueJornada(response.data);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        } catch (e) {
        }
    }

    const buscaEscalas = (colaborador, empresa) => {
        console.log('colaborador buscaEscalas', colaborador)
        if(colaborador.id){
            api
            .get("/escalastrabalho?colaborador=" + colaborador.id + "&empresa=" + empresa.id)
            .then(response => {
                setLista(response.data)
            })
            .catch(() => {
                addMessage("error", Mensagens.ERRO);
            })
        }

    }

    const selecao = (e) => {
        setModel(e.value);
    }

    const onInputChange = (e, descricao) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        if (descricao === 'empresa') {
            _model.colaborador = null;
            _model.jornada = null;
            buscaColaboradores(_model.empresa);
            buscaJornadas(_model.empresa);

        }
        if (descricao === 'colaborador') {
            buscaEscalas(_model.colaborador, _model.empresa);
        }
        setModel(_model);

    }

    const verificarFechamento = async (idEmpresa, idColaborador, data) =>{
        try {
            const response = await api
                .get("/verificarFechamento?empresa=" + idEmpresa + "&colaborador=" + idColaborador + "&data=" + data);
                return response.data;
        } catch {
            return false;
        }
    }

    const cadastrar = async () => {
        if (model.empresa && model.colaborador && model.jornada && model.dataS.trim()) {
            const isFechamentoVerificado = await verificarFechamento(model.empresa.id, model.colaborador.id, model.dataS);
            if(isFechamentoVerificado){
            api
                .post("escalastrabalho", {
                    id: model.id,
                    empresa: model.empresa,
                    colaborador: model.colaborador,
                    jornada: model.jornada,
                    dataS: model.dataS,
                })
                .then(response => {
                    setLista(response.data);
                    addMessage("success", Mensagens.CADASTRO_INCLUIR);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
            }else{
                addMessage("error", Mensagens.MENSAGEM_PONTOFECHADOEMBANCODEHORAS);
            }
        }
    }

    const inativar = async () => {
        if (model.id) {
            const isFechamentoVerificado = await verificarFechamento(model.empresa.id, model.colaborador.id, model.dataS);
            if(isFechamentoVerificado){
            api
                .delete("/escalastrabalho/" + model.id)
                .then(response => {
                    addMessage("success", Mensagens.CADASTRO_INATIVAR);
                    setLista(response.data);
                    setSubmitted(false);
                    // setModel(emptyModel);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        } else {
            addMessage("warn", Mensagens.SELECIONE);
        }
    }else{
        addMessage("error", Mensagens.MENSAGEM_PONTOFECHADOEMBANCODEHORAS);
    }
    }

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="empresa" className="col-12 md:col-1 md:mb-0">Empresa*</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="empresa" dropdown value={model.empresa} onChange={(e) => onInputChange(e, 'empresa')} suggestions={autoFilteredValueEmpresa} completeMethod={procurarEmpresas} field="fantasia" required className={classNames({ 'p-invalid': submitted && !model.empresa })} />
                            {submitted && !model.empresa && <small className="p-invalid">Empresa Obrigatória</small>}
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="colaborador" className="col-12 md:col-1 md:mb-0">Colaborador*</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="colaborador" dropdown value={model.colaborador} onChange={(e) => onInputChange(e, 'colaborador')} suggestions={autoFilteredValueColaborador} completeMethod={procurarColaboradores} field="nome" required className={classNames({ 'p-invalid': submitted && !model.colaborador })} />
                            {submitted && !model.colaborador && <small className="p-invalid">colaborador Obrigatório</small>}
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="jornada" className="col-12 md:col-1 md:mb-0">Jornada*</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="jornada" dropdown value={model.jornada} onChange={(e) => onInputChange(e, 'jornada')} suggestions={autoFilteredValueJornada} completeMethod={procurarJornadas} field="nome" required className={classNames({ 'p-invalid': submitted && !model.jornada })} />
                            {submitted && !model.jornada && <small className="p-invalid">Jornada Obrigatória</small>}
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="data" className="col-12 md:col-1 md:mt-2">A partir de*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputMask id='data' mask='99/99/9999' value={model.dataS} onChange={e => onInputChange(e, 'dataS')} required className={classNames({ 'p-invalid': submitted && !model.dataS })} />
                            {submitted && !model.dataS && <small className="p-invalid">Data Obrigatória</small>}
                        </div>
                    </div>

                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                    <ConsultaFixaGenerica
                        lista={lista}
                        model={model}
                        selecao={selecao}
                        camposFiltroGlobal={['dataS', 'jornada.nome']}
                        colunas={colunas}
                    />

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(EscalasDeTrabalho, comparisonFn);
