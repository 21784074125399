import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';
import { Fieldset } from 'primereact/fieldset';
import Botoes from '../globais/Botoes';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';

const BHMesVigente = () => {

    let emptyModel = {
        bancoHoras: null,
        mes: null,
        ano: null,
        dataAberturaS: '',
        dataFechamentoS: '',
        usuarioAbertura: null,
        usuarioFechamento: null,
    };
    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [submitted, setSubmitted] = useState(false);
    const message = useRef();
    const [autoFilteredValueEmpresa, setAutoFilteredValueEmpresa] = useState([]);
    const [autoValueEmpresa, setAutoValueEmpresa] = useState(null);
    const [autoFilteredValueBancoHoras, setAutoFilteredValueBancoHoras] = useState([]);
    const [autoValueBancoHoras, setAutoValueBancoHoras] = useState(null);
    const [empresa, setEmpresa] = useState(null);
    const [value, setValue] = useState(0);
    const interval = useRef(null);

    useEffect(() => {
        const buscaEmpresas = () => {
            api
                .get("/empresas")
                .then(response => {
                    setAutoValueEmpresa(response.data);
                    setEmpresa(response.data[0]);
                    buscarBancoHoras(response.data[0]);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
        buscaEmpresas();
    }, []);

    useEffect(() => {
        if (submitted) {
            let val = value;
            interval.current = setInterval(() => {
                val += Math.floor(Math.random() * 5) + 1;

                if (val >= 100) {
                    val = 97;
                    clearInterval(interval.current);
                }
                setValue(val);
            }, 6000);

            return () => {
                if (interval.current) {
                    clearInterval(interval.current);
                    interval.current = null;
                }
            }
        }
    }, [value, submitted]);

    const buscarMesVigente = (bancoHoras) => {
        if (bancoHoras !== null) {
            api
                .get("/bancoHoras/mesVigente?bancoHoras=" + bancoHoras.id)
                .then(response => {
                    setModel(response.data);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
    }

    const buscarBancoHoras = (empresa) => {
        if (empresa !== null) {
            api
                .get("/bancoHoras/periodoCompetencia?empresa=" + empresa.id)
                .then(response => {
                    setAutoValueBancoHoras(response.data);
                    model.bancoHoras = response.data[0];
                    buscarMesVigente(response.data[0]);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
    };


    const onChangeEmpresa = (empresa) => {
        setEmpresa(empresa);
        buscarBancoHoras(empresa);
    }

    const onInputChange = (e, descricao) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);

        if (descricao === 'bancoHoras') {
            buscarMesVigente(_model.bancoHoras);
        }
    }

    const procurarEmpresas = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueEmpresa([...autoValueEmpresa]);
                }
                else {
                    setAutoFilteredValueEmpresa(autoValueEmpresa.filter((em) => {
                        return em.fantasia.toLowerCase().startsWith(event.query.toLowerCase());

                    }));
                }
            } catch (e) {
            }

        }, 250);
    };

    const procurarBancoHoras = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueBancoHoras([...autoValueBancoHoras]);
                }
                else {
                    setAutoFilteredValueBancoHoras(autoValueBancoHoras.filter((em) => {
                        return em.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                    }));
                }
            } catch (e) {
            }

        }, 250);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={Botoes.AUTORIZAR_FECHAMENTO} icon="pi pi-plus" onClick={realizarFechamento} className="p-button-success mr-2 mt-1 mb-1" />
                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const realizarFechamento = () => {
        if (model.bancoHoras) {
            setValue(2);
            setSubmitted(true);
            api
                .post("/bancoHoras/mesVigente",
                    model.bancoHoras
                )
                .then(response => {
                    if (response.data.message) {
                        addMessage("success", response.data.message);
                    } else {
                        setModel(response.data);
                        addMessage("success", Mensagens.OPERACAO_SUCESSO);
                    }
                    setSubmitted(false);
                    setValue(100);
                })
                .catch(error => {
                    if (error.response.data.message) {
                        addMessage("error", error.response.data.message);
                    } else {
                        addMessage("error", Mensagens.ERRO);
                    }
                    setSubmitted(false);
                    setValue(0);
                })
        } else {
            setSubmitted(false);
            setValue(0);
            addMessage("warn", Mensagens.SELECIONE_BH);
        }
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn, life: 10000 });
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="empresa" className="col-12 md:col-1 md:mb-0">Empresa*</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="empresa" dropdown value={empresa} onChange={(e) => onChangeEmpresa(e.target.value)} suggestions={autoFilteredValueEmpresa} completeMethod={procurarEmpresas} field="fantasia" required className={classNames({ 'p-invalid': submitted && !empresa })} />
                            {submitted && !empresa && <small className="p-invalid">Empresa Obrigatória</small>}
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="bancohoras" className="col-12 md:col-1 md:mb-0">Banco de Horas</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="bancohoras" dropdown value={model.bancoHoras} onChange={(e) => onInputChange(e, 'bancoHoras')} suggestions={autoFilteredValueBancoHoras} completeMethod={procurarBancoHoras} field="descricao" />
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <Fieldset legend="Mês Vigente" className='col-1 mb-2'>
                            <h1>
                                {
                                    (model.mes != null && model.ano != null)
                                        ? `${model.mes}/${model.ano}`
                                        : 'Inicie o BH'
                                }
                            </h1>
                        </Fieldset>

                        <Fieldset legend="Informações do Mês Vigente" className='col-10 mb-2'>
                            <div className="col-12 md:col-2 mt-2">
                                <label htmlFor="option1">Abertura: {model.dataAberturaS}</label>
                            </div>
                            <div className="col-12 md:col-2 mt-2">
                                <label htmlFor="option1">Usuário: {model.usuarioAbertura?.nomeUsuario}</label>
                            </div>
                        </Fieldset>
                    </div>

                    <Fieldset legend="Fechamento do Mês Vigente" className='col-12 mb-2'>
                        <p>
                            1) O fechamento resultará na impossibilidade de manipulação do mês vigente.
                        </p>
                        <p>
                            2) Exemplo: Não será possível "pagar horas".
                        </p>
                        <p>
                            3) Automaticamente será aberto o próximo mês. Neste caso, o mês vigente será atualizado.
                        </p>
                    </Fieldset>

                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                    <div className="col-12">
                        <div className="col">
                            <ProgressBar value={value} />
                        </div>
                    </div>

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(BHMesVigente, comparisonFn);
