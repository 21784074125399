import React, { useState, useRef, useEffect } from "react";
import api from "../service/api";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Messages } from "primereact/messages";
import Mensagens from "../globais/Mensagens";
import { MultiSelect } from "primereact/multiselect";
import Botoes from "../globais/Botoes";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { AutoComplete } from "primereact/autocomplete";
import { classNames } from "primereact/utils";
import { Calendar } from "primereact/calendar";
import { addLocale, locale } from "primereact/api";

const RelatorioEspelhoPontoBH = () => {
    let emptyModel = {
        empresa: { id: 0, fantasia: "" },
        bancoHoras: null,
    };

    addLocale("br", {
        firstDayOfWeek: 1,
        dayNames: ["domingo", "segunda", "terça", "quarta", "quinta", "sexta", "sábado"],
        dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
        dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "SA"],
        monthNames: ["janeiro", "fevereiro", "março", "abril", "´maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"],
        monthNamesShort: ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"],
        today: "Hoje",
        clear: "Limpar",
    });

    locale("br");

    let hoje = new Date();

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [listaColaboradores, setListaColaboradores] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [multiselectValue, setMultiselectValue] = useState([]);
    const message = useRef();
    const [value, setValue] = useState(0);
    const interval = useRef(null);
    const [autoFilteredValueEmpresa, setAutoFilteredValueEmpresa] = useState([]);
    const [autoValueEmpresa, setAutoValueEmpresa] = useState(null);
    const [autoFilteredValueBancoHoras, setAutoFilteredValueBancoHoras] = useState([]);
    const [autoValueBancoHoras, setAutoValueBancoHoras] = useState(null);
    const [data, setData] = useState(hoje);

    useEffect(() => {
        const buscaEmpresas = () => {
            api.get("/empresas")
                .then((response) => {
                    setAutoValueEmpresa(response.data);
                    model.empresa = response.data[0];
                    buscarBancoHoras(response.data[0]);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                });
        };
        buscaEmpresas();
    }, []);

    useEffect(() => {
        if (submitted) {
            let val = value;
            interval.current = setInterval(() => {
                val += Math.floor(Math.random() * 5) + 1;

                if (val >= 100) {
                    val = 97;
                    clearInterval(interval.current);
                }
                setValue(val);
            }, 6000);

            return () => {
                if (interval.current) {
                    clearInterval(interval.current);
                    interval.current = null;
                }
            };
        }
    }, [value, submitted]);

    const itemTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                {/* <span className={`mr-2 flag flag-${option.descricao.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                <span>{option.nome}</span>
            </div>
        );
    };

    const selectedItemTemplate = (option) => {
        if (option) {
            return (
                <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
                    {/* <span className={`mr-2 flag flag-${option.descricao.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
                    <span>{option.nome}</span>
                </div>
            );
        }
        return "Selecione Colaboradores";
    };

    const buscaColaboradores = (empresa, bancoHoras) => {
        if (empresa !== null) {
            api.get("/colaboradores?empresa=" + empresa.id + "&bancoHoras=" + bancoHoras.id)
                .then((response) => {
                    setListaColaboradores(response.data);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                });
        }
    };

    const buscarBancoHoras = (empresa) => {
        if (empresa !== null) {
            api.get("/bancoHoras/periodoCompetencia?empresa=" + empresa.id)
                .then((response) => {
                    setAutoValueBancoHoras(response.data);
                    model.bancoHoras = response.data[0];
                    buscaColaboradores(empresa, response.data[0]);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                });
        }
    };

    const procurarBancoHoras = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueBancoHoras([...autoValueBancoHoras]);
                } else {
                    setAutoFilteredValueBancoHoras(
                        autoValueBancoHoras.filter((em) => {
                            return em.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                        })
                    );
                }
            } catch (e) {}
        }, 250);
    };

    const onInputChange = (e, descricao) => {
        const val = (e.target && e.target.value) || "";
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
        if (descricao === "empresa" || descricao === "bancoHoras") {
            setMultiselectValue([]);
            buscaColaboradores(_model.empresa, _model.bancoHoras);
        }
    };

    const procurarEmpresas = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueEmpresa([...autoValueEmpresa]);
                } else {
                    setAutoFilteredValueEmpresa(
                        autoValueEmpresa.filter((em) => {
                            return em.fantasia.toLowerCase().startsWith(event.query.toLowerCase());
                        })
                    );
                }
            } catch (e) {}
        }, 250);
    };

    const buscarPorDataInicio = (e) => {
        if (model.bancoHoras !== null) {
            setData(e.value);
            let mes = e.value.getMonth() + 1;
            let ano = e.value.getFullYear();
            console.log(mes);
            console.log(ano);
            api.get("/colaboradores/bancoHoras/iniciarSaldo?mes=" + mes + "&ano=" + ano + "&bancoHoras= " + model.bancoHoras.id)
                .then((response) => {
                    setListaColaboradores(response.data);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                });
        }
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={Botoes.VISUALIZAR} icon="pi pi-check" onClick={visualizar} className="p-button-success mr-2" />
                    <Messages ref={message} />
                </div>
            </React.Fragment>
        );
    };

    const visualizar = () => {
        if (multiselectValue.length > 0) {
            setValue(3);
            setSubmitted(true);
            api.post(
                "/bancoHoras/colaboradoresIniciados/relatorio",
                {
                    colaboradores: multiselectValue,
                    empresa: model.empresa,
                    bancoHoras: model.bancoHoras,
                    mes: data.getMonth() + 1,
                    ano: data.getFullYear(),
                    funcionarioDesligado: false,
                },
                { responseType: "arraybuffer" }
            )
                .then((response) => {
                    const file = new Blob([response.data], { type: "application/pdf" });

                    const fileURL = URL.createObjectURL(file);

                    window.open(fileURL);
                    setSubmitted(false);
                    setValue(100);
                })
                .catch((error) => {
                    console.log(error.response);
                    if (error.response.status === 406) {
                        addMessage("error", Mensagens.BH_RELATORIO_SEMFECHAMENTOMES);
                    } else if (error.response.status === 304) {
                        addMessage("error", Mensagens.BH_RELATORIO_SEMFECHAMENTOMES);
                    } else if (error.response.status === 510) {
                        addMessage("error", Mensagens.MENSAGEM_BHBANCOFORAPERIODO);
                    } else {
                        addMessage("error", Mensagens.ERRO);
                    }
                    setSubmitted(false);
                    setValue(0);
                });
        } else {
            setSubmitted(false);
            setValue(0);
            addMessage("warn", Mensagens.SELECIONE);
        }
    };

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn, life: 10000 });
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="empresa" className="col-12 md:col-1 md:mb-0">
                            Empresa*
                        </label>
                        <div className="col-12 md:col-11">
                            <AutoComplete
                                placeholder="Procure"
                                id="empresa"
                                dropdown
                                value={model.empresa}
                                onChange={(e) => onInputChange(e, "empresa")}
                                suggestions={autoFilteredValueEmpresa}
                                completeMethod={procurarEmpresas}
                                field="fantasia"
                                required
                                className={classNames({ "p-invalid": submitted && !model.empresa })}
                            />
                            {submitted && !model.empresa && <small className="p-invalid">Empresa Obrigatória</small>}
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="bancohoras" className="col-12 md:col-1 md:mb-0">
                            Banco de Horas
                        </label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="bancohoras" dropdown value={model.bancoHoras} onChange={(e) => onInputChange(e, "bancoHoras")} suggestions={autoFilteredValueBancoHoras} completeMethod={procurarBancoHoras} field="descricao" />
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="periodo" className="col-12 md:col-1 md:mt-2">
                            Período*
                        </label>
                        <div className="field col-12 md:col-2 mb-2">
                            <Calendar dateFormat="mm/yy" value={data} onChange={(e) => buscarPorDataInicio(e)} view="month" showIcon></Calendar>
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-12 mb-2">
                            <MultiSelect value={multiselectValue} onChange={(e) => setMultiselectValue(e.value)} options={listaColaboradores} optionLabel="nome" filter itemTemplate={itemTemplate} selectedItemTemplate={selectedItemTemplate} maxSelectedLabels={5} />
                        </div>
                    </div>

                    <div className="col-12 ">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                    <div className="col-12">
                        <div className="col">
                            <ProgressBar value={value} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(RelatorioEspelhoPontoBH, comparisonFn);
