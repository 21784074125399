import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';
import BTGravar from '../components/BTGravar';
import { addLocale, locale } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import Formatacao from '../utilities/Formatacao';

const BHIniciarSaldo = () => {

    let emptyModel = {
        empresa: null,
        colaborador: null,
        bancoHoras: null,
        creditoS: '00:00',
        debitoS: '00:00',
    };

    addLocale('br', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'SA'],
        monthNames: ['janeiro', 'fevereiro', 'março', 'abril', '´maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar'
    });

    locale("br");

    let hoje = new Date();
    let formatacao = new Formatacao();

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [submitted, setSubmitted] = useState(false);
    const message = useRef();
    const [autoFilteredValueEmpresa, setAutoFilteredValueEmpresa] = useState([]);
    const [autoValueEmpresa, setAutoValueEmpresa] = useState(null);
    const [autoFilteredValueColaborador, setAutoFilteredValueColaborador] = useState([]);
    const [autoValueColaborador, setAutoValueColaborador] = useState(null);
    const [autoFilteredValueBancoHoras, setAutoFilteredValueBancoHoras] = useState([]);
    const [autoValueBancoHoras, setAutoValueBancoHoras] = useState(null);
    const [data, setData] = useState(hoje);

    useEffect(() => {
        const buscaEmpresas = () => {
            api
                .get("/empresas")
                .then(response => {
                    setAutoValueEmpresa(response.data);
                    model.empresa = response.data[0];
                    buscarColaboradores(response.data[0]);
                    buscarBancoHoras(response.data[0]);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
        buscaEmpresas();
    }, []);

    const buscarColaboradores = (empresa) => {
        if (empresa !== null) {
            api
                .get("/colaboradores?empresa=" + empresa.id)
                .then(response => {
                    setAutoValueColaborador(response.data);
                    model.colaborador = response.data[0];
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
    };

    const buscarDadosIniciados = (colaborador, empresa, bancoHoras) => {
        if (colaborador !== null && empresa !== null && bancoHoras !== null) {
            api
                .get("/bancoHoras/iniciarSaldo?colaborador= " + colaborador.id + "&empresa=" + empresa.id + "&bancoHoras=" + bancoHoras.id)
                .then(response => {
                    if (response.data === true) {
                        addMessage("warn", Mensagens.AVISO_COLABORADOR_INICIADO);
                    }
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
    }

    const buscarBancoHoras = (empresa) => {
        if (empresa !== null) {
            api
                .get("/bancoHoras/periodoCompetencia?empresa=" + empresa.id)
                .then(response => {
                    setAutoValueBancoHoras(response.data);
                    model.bancoHoras = response.data[0];
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
    };

    const onInputChange = (e, descricao) => {
        let val = (e.target && e.target.value) || '';

        if (descricao === 'creditoS' || descricao === 'debitoS') {
            val = formatacao.formatarTempo(e.target.value);
        }

        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
        if (descricao === 'empresa') {
            buscarColaboradores(_model.empresa);
        }
        if (descricao === 'colaborador') {
            buscarDadosIniciados(_model.colaborador, _model.empresa, _model.bancoHoras);
        }
    }

    const procurarEmpresas = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueEmpresa([...autoValueEmpresa]);
                }
                else {
                    setAutoFilteredValueEmpresa(autoValueEmpresa.filter((em) => {
                        return em.fantasia.toLowerCase().startsWith(event.query.toLowerCase());

                    }));
                }
            } catch (e) {
            }

        }, 250);
    };

    const procurarColaboradores = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueColaborador([...autoValueColaborador]);
                }
                else {
                    setAutoFilteredValueColaborador(autoValueColaborador.filter((em) => {
                        return em.nome.toLowerCase().startsWith(event.query.toLowerCase());
                    }));
                }
            } catch (e) {
            }

        }, 250);
    };

    const procurarBancoHoras = (event) => {
        setTimeout(() => {
            try {
                if (!event.query.trim().length) {
                    setAutoFilteredValueBancoHoras([...autoValueBancoHoras]);
                }
                else {
                    setAutoFilteredValueBancoHoras(autoValueBancoHoras.filter((em) => {
                        return em.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                    }));
                }
            } catch (e) {
            }

        }, 250);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <BTGravar setSubmitted={setSubmitted} cadastrar={cadastrar} />
                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const cadastrar = () => {
        if (model.colaborador && model.empresa && model.bancoHoras && data
            && model.creditoS.trim() && model.debitoS.trim()) {
            api
                .post("/bancoHoras/iniciarSaldo", {
                    empresa: model.empresa,
                    colaborador: model.colaborador,
                    bancoHoras: model.bancoHoras,
                    mes: data.getMonth() + 1,
                    ano: data.getFullYear(),
                    creditoS: model.creditoS,
                    debitoS: model.debitoS,
                })
                .then(response => {
                    // setModel(response.data);
                    if (response.data.message) {
                        addMessage("success", response.data.message);
                    } else {
                        addMessage("success", Mensagens.OPERACAO_SUCESSO);
                    }
                })
                .catch(error => {
                    if (error.response.data.message) {
                        addMessage("error", error.response.data.message);
                    } else {
                        addMessage("error", Mensagens.ERRO);
                    }
                })
        }
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn, life: 10000 });
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="empresa" className="col-12 md:col-1 md:mb-0">Empresa*</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="empresa" dropdown value={model.empresa} onChange={(e) => onInputChange(e, 'empresa')} suggestions={autoFilteredValueEmpresa} completeMethod={procurarEmpresas} field="fantasia" required className={classNames({ 'p-invalid': submitted && !model.empresa })} />
                            {submitted && !model.empresa && <small className="p-invalid">Empresa Obrigatória</small>}
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="colaborador" className="col-12 md:col-1 md:mb-0">Colaborador*</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="colaborador" dropdown value={model.colaborador} onChange={(e) => onInputChange(e, 'colaborador')} suggestions={autoFilteredValueColaborador} completeMethod={procurarColaboradores} field="nome" />
                        </div>
                    </div>

                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="bancohoras" className="col-12 md:col-1 md:mb-0">Banco de Horas</label>
                        <div className="col-12 md:col-11">
                            <AutoComplete placeholder="Procure" id="bancohoras" dropdown value={model.bancoHoras} onChange={(e) => onInputChange(e, 'bancoHoras')} suggestions={autoFilteredValueBancoHoras} completeMethod={procurarBancoHoras} field="descricao" />
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="periodo" className="col-12 md:col-1 md:mt-2">Mês Inicial*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <Calendar dateFormat="mm/yy" value={data} onChange={(e) => setData(e.value)} view="month" showIcon disabled></Calendar>
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="credito" className="col-12 md:col-1 md:mt-2">Crédito(+)*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputText id="credito" value={model.creditoS} onChange={(e) => onInputChange(e, 'creditoS')} required className={classNames({ 'p-invalid': submitted && !model.creditoS })} />
                            {submitted && !model.creditoS && <small className="p-invalid">Crédito Obrigatório</small>}
                        </div>

                        <label htmlFor="debito" className="col-12 md:col-1 md:mt-2">Débito(-)*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputText id="debito" value={model.debitoS} onChange={(e) => onInputChange(e, 'debitoS')} required className={classNames({ 'p-invalid': submitted && !model.debitoS })} />
                            {submitted && !model.debitoS && <small className="p-invalid">Débito Obrigatório</small>}
                        </div>
                    </div>

                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(BHIniciarSaldo, comparisonFn);
