import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import BTNovo from '../components/BTNovo';
import BTGravar from '../components/BTGravar';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { SelectButton } from 'primereact/selectbutton';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { AutoComplete } from 'primereact/autocomplete';
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';
import ConsultaFixaGenerica from '../components/consultas/ConsultaFixaGenerica';

const ConfiguracaoEspelhoPonto = () => {

    let emptyModel = {
        id: null,
        dataS: '',
        dia: 1,
        formatoTolerancia: 0,
        empresa: null,
        assinatura: 'Recursos Humanos',
    };

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [lista, setLista] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [tipo, setTipo] = useState('Flutuante');
    const options = ['Flutuante', 'Entrada'];
    const [autoFilteredValueEmpresa, setAutoFilteredValueEmpresa] = useState([]);
    const [autoValueEmpresa, setAutoValueEmpresa] = useState(null);
    const [todasEmpresas, setTodasEmpresas] = useState(false);

    const colunas = [
        { field: 'empresa.fantasia', header: 'Empresa', headerStyle: { minWidth: '15rem' } },
        { field: 'dia', header: 'Dia', headerStyle: { minWidth: '5rem' } },
        { field: 'assinatura', header: 'Descrição', headerStyle: { minWidth: '5rem' } },
        { field: 'formatoToleranciaS', header: 'Tolerância', headerStyle: { minWidth: '5rem' } },
        { field: 'dataS', header: 'Data Histórica', headerStyle: { minWidth: '5rem' } },
    ];

    const message = useRef();

    useEffect(() => {

        const buscaEmpresas = () => {
            api
                .get("/empresas")
                .then(response => {
                    model.empresa = response.data[0];
                    setAutoValueEmpresa(response.data);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaConfiguracaoEspelhoPonto = () => {
            api
                .get("/regras/configuracoes/espelhoponto")
                .then(response => {
                    setLista(response.data)
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        buscaEmpresas();
        buscaConfiguracaoEspelhoPonto();

    }, []);

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <BTGravar setSubmitted={setSubmitted} cadastrar={cadastrar} />
                    <BTNovo setSubmitted={setSubmitted} setModel={setModel} emptyModel={emptyModel} />
                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    const procurarEmpresas = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValueEmpresa([...autoValueEmpresa]);
            }
            else {
                setAutoFilteredValueEmpresa(autoValueEmpresa.filter((em) => {
                    return em.fantasia.toLowerCase().startsWith(event.query.toLowerCase());
                }));
            }
        }, 250);
    };


    const onInputChange = (e, descricao) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
    }

    const onInputChangeNumber = (e, descricao) => {
        const val = (e.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
    }

    const selecao = (e) => {
        if (e.value.formatoToleranciaS === "Flutuante") {
            setTipo('Flutuante');
        } else {
            setTipo('Entrada');
        }

        setModel(e.value);

    }

    const cadastrar = () => {
        if (model.empresa || todasEmpresas) {
            api
                .post("/regras/configuracoes/espelhoponto", {
                    id: model.id,
                    formatoToleranciaS: tipo,
                    dia: model.dia,
                    assinatura: model.assinatura,
                    empresa: model.empresa,
                    todasEmpresas: todasEmpresas,
                })
                .then(response => {
                    setLista(response.data);
                    addMessage("success", Mensagens.CADASTRO_INCLUIR);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
    }

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="empresa" className="col-12 md:col-1 md:mb-2">Empresa*</label>
                        <div className="col-12 md:col-9 mb-2">
                            <AutoComplete placeholder="Procure" id="empresa" dropdown value={model.empresa} onChange={(e) => onInputChange(e, 'empresa')} suggestions={autoFilteredValueEmpresa} completeMethod={procurarEmpresas} field="fantasia" required className={classNames({ 'p-invalid': submitted && !model.empresa && !todasEmpresas })} />
                            {submitted && !model.empresa && !todasEmpresas && <small className="p-invalid">Empresa Obrigatória</small>}
                        </div>
                        <div className="field-checkbox p-fluid mt-2 col-2">
                            <Checkbox inputId="checkOption1" name="todasempresas" value="Todas Empresas" checked={todasEmpresas} onChange={e => setTodasEmpresas(e.checked)} />
                            <label htmlFor="checkOption1">Todas Empresas</label>
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="dia" className="col-12 md:col-1 md:mt-2">Dia Início*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputNumber id="dia" inputId="minmax-buttons" value={model.dia} mode="decimal" showButtons min={1} max={31} onChange={(e) => onInputChangeNumber(e, 'dia')} />
                        </div>

                        <label htmlFor="assinatura" className="col-12 md:col-1 md:mt-2">Assinatura</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputText id="assinatura" maxLength={60} placeholder="Máximo 60 caracteres" value={model.assinatura} onChange={(e) => onInputChange(e, 'assinatura')} />
                        </div>
                        <label htmlFor="tolerancia" className="col-12 md:col-1 md:mt-2">Tolerância*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <SelectButton value={tipo} options={options} onChange={(e) => setTipo(e.value)} />
                        </div>
                    </div>

                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                    <ConsultaFixaGenerica
                        lista={lista}
                        model={model}
                        selecao={selecao}
                        camposFiltroGlobal={['empresa.fantasia', 'dia', 'formatoToleranciaS']}
                        colunas={colunas}
                    />

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ConfiguracaoEspelhoPonto, comparisonFn);
