import React, { useState, useRef, useEffect } from 'react';
import api from "../service/api";
import { Messages } from 'primereact/messages';
import Mensagens from '../globais/Mensagens';
import BTInativar from '../components/BTInativar';
import BTNovo from '../components/BTNovo';
import BTGravar from '../components/BTGravar';
import { classNames } from 'primereact/utils';
import { SelectButton } from 'primereact/selectbutton';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { AutoComplete } from 'primereact/autocomplete';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputMask } from 'primereact/inputmask';
import ConsultaFixaGenerica from '../components/consultas/ConsultaFixaGenerica';

const ConfiguracaoHorasExtrasIntrajornada = () => {

    let emptyModel = {
        id: null,
        jornada: null,
        valor: null,
        tempoS: '',
        validadeDSR: 0,
        validadeFeriado: 0,
        somenteDSR: 0,
        somenteFeriado: 0,
    };

    const toast = useRef(null);
    const [model, setModel] = useState(emptyModel);
    const [lista, setLista] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [incluir, setIncluir] = useState([]);
    const [somente, setSomente] = useState([]);
    const optionsVigencia = ['DSR', 'Feriado'];
    const [autoFilteredValueJornada, setAutoFilteredValueJornada] = useState([]);
    const [autoValueJornada, setAutoValueJornada] = useState(null);
    const [todasJornadas, setTodasJornadas] = useState(false);
    const [horasExtras, setHorasextras] = useState([]);

    const colunas = [
        { field: 'jornada.nome', header: 'Jornada', headerStyle: { minWidth: '15rem' } },
        { field: 'tempoS', header: 'Tempo', headerStyle: { maxWidth: '5rem' } },
        { field: 'valor.valor', header: 'Valor (%)', headerStyle: { maxWidth: '5rem' } },
        { field: 'validadeDSRS', header: 'Incluir DSR', headerStyle: { maxWidth: '5rem' } },
        { field: 'validadeFeriadoS', header: 'Incluir Feriado', headerStyle: { maxWidth: '5rem' } },
        { field: 'somenteDSRS', header: 'Somente DSR', headerStyle: { maxWidth: '5rem' } },
        { field: 'somenteFeriadoS', header: 'Somente Feriado', headerStyle: { maxWidth: '5rem' } },
    ];


    const message = useRef();

    useEffect(() => {

        const buscaJornadas = () => {
            api
                .get("/jornadas")
                .then(response => {
                    setAutoValueJornada(response.data);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaHorasExtrasEspecificas = () => {
            api
                .get("/regras/configuracoes/horasextrasintrajornada")
                .then(response => {
                    setLista(response.data)
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }

        const buscaHorasExtras = () => {
            api
                .get("/regras/configuracoes/horasextras")
                .then(response => {
                    setHorasextras(response.data)
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        }


        buscaJornadas();
        buscaHorasExtrasEspecificas();
        buscaHorasExtras();

    }, []);

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <BTGravar setSubmitted={setSubmitted} cadastrar={cadastrar} />
                    <BTNovo setSubmitted={setSubmitted} setModel={setModel} emptyModel={emptyModel} />
                    <BTInativar inativar={inativar} />
                    <Messages ref={message} />
                </div>
            </React.Fragment>
        )
    }

    const addMessage = (sv, cn) => {
        message.current.show({ severity: sv, content: cn });
    };

    const procurarJornadas = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValueJornada([...autoValueJornada]);
            }
            else {
                setAutoFilteredValueJornada(autoValueJornada.filter((em) => {
                    return em.nome.toLowerCase().startsWith(event.query.toLowerCase());
                }));
            }
        }, 250);
    };


    const onInputChange = (e, descricao) => {
        const val = (e.target && e.target.value) || '';
        let _model = { ...model };
        _model[`${descricao}`] = val;
        setModel(_model);
    }

    const selecao = (e) => {
        let incluir = [];
        let somente = [];

        if (e.value.validadeDSRS === "SIM") {
            incluir.push('DSR')
        }
        if (e.value.validadeFeriadoS === "SIM") {
            incluir.push('Feriado')
        }

        if (e.value.somenteDSRS === "SIM") {
            somente.push('DSR')
        }
        if (e.value.somenteFeriadoS === "SIM") {
            somente.push('Feriado')
        }

        setIncluir(incluir);
        setSomente(somente);
        setModel(e.value);
    }

    const cadastrar = () => {
        if ((model.jornada || todasJornadas) && model.tempoS.trim() && model.valor) {
            let _model = { ...model };
            const val = 1;
            let descricao = "validadeDSR";
            let descricao1 = "validadeFeriado";
            let descricao2 = "somenteDSR";
            let descricao3 = "somenteFeriado";
            incluir.forEach(element => {
                if (element === 'DSR') {
                    _model[`${descricao}`] = val;
                } else if (element === 'Feriado') {
                    _model[`${descricao1}`] = val;
                }
            });

            somente.forEach(element => {
                if (element === 'DSR') {
                    _model[`${descricao2}`] = val;
                } else if (element === 'Feriado') {
                    _model[`${descricao3}`] = val;
                }
            });
            setModel(_model);
            api
                .post("/regras/configuracoes/horasextrasintrajornada", {
                    id: model.id,
                    valor: model.valor,
                    tempoS: model.tempoS,
                    validadeDSR: _model.validadeDSR,
                    validadeFeriado: _model.validadeFeriado,
                    jornada: model.jornada,
                    todasJornadas: todasJornadas,
                    somenteDSR: _model.somenteDSR,
                    somenteFeriado: _model.somenteFeriado,
                })
                .then(response => {
                    setLista(response.data);
                    addMessage("success", Mensagens.CADASTRO_INCLUIR);
                })
                .catch(erro => {
                    addMessage("error", Mensagens.ERRO);
                })
        }
    }

    const inativar = () => {
        if (model.id) {
            api
                .delete("/regras/configuracoes/horasextrasintrajornada/" + model.id)
                .then(response => {
                    addMessage("success", Mensagens.CADASTRO_INATIVAR);
                    setLista(response.data);
                    setSubmitted(false);
                    setModel(emptyModel);
                    setIncluir([]);
                    setSomente([]);
                })
                .catch(() => {
                    addMessage("error", Mensagens.ERRO);
                })
        } else {
            addMessage("warn", Mensagens.SELECIONE);
        }

    }


    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <div className="field grid p-fluid mb-2">
                        <label htmlFor="jornada" className="col-12 md:col-1 md:mb-2">Jornadas*</label>
                        <div className="col-12 md:col-9 mb-2">
                            <AutoComplete placeholder="Procure" id="jornada" dropdown value={model.jornada} onChange={(e) => onInputChange(e, 'jornada')} suggestions={autoFilteredValueJornada} completeMethod={procurarJornadas} field="nome" required className={classNames({ 'p-invalid': submitted && !model.jornada && !todasJornadas })} />
                            {submitted && !model.jornada && !todasJornadas && <small className="p-invalid">Empresa Obrigatória</small>}
                        </div>
                        <div className="field-checkbox p-fluid mt-2 col-2">
                            <Checkbox inputId="checkOption1" name="todasjornadas" value="Todas Jornadas" checked={todasJornadas} onChange={e => setTodasJornadas(e.checked)} />
                            <label htmlFor="checkOption1">Todas Jornadas</label>
                        </div>
                    </div>

                    <div className="p-fluid formgrid grid">
                        <label htmlFor="tempo" className="col-12 md:col-1 md:mt-2">tempo*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <InputMask id="tempo" mask='99:99' value={model.tempoS} onChange={(e) => onInputChange(e, 'tempoS')} required className={classNames({ 'p-invalid': submitted && !model.tempoS })} />
                            {submitted && !model.tempoS && <small className="p-invalid">Tempo Obrigatório</small>}
                        </div>
                        <label htmlFor="valor" className="col-12 md:col-1 md:mt-2">Valor (%)*</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <Dropdown value={model.valor} onChange={(e) => onInputChange(e, 'valor')} options={horasExtras} optionLabel="valor" placeholder="Selecione" emptyMessage="Nada encontrado." required className={classNames({ 'p-invalid': submitted && !model.valor })} />
                            {submitted && !model.valor && <small className="p-invalid">Valor Obrigatório</small>}
                        </div>
                        <label htmlFor="incluir" className="col-12 md:col-1 md:mt-2">Incluir</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <SelectButton multiple value={incluir} options={optionsVigencia} onChange={(e) => setIncluir(e.value)} />
                        </div>
                        <label htmlFor="somente" className="col-12 md:col-1 md:mt-2">Somente</label>
                        <div className="field col-12 md:col-2 mb-2">
                            <SelectButton multiple value={somente} options={optionsVigencia} onChange={(e) => setSomente(e.value)} />
                        </div>
                    </div>

                    <div className="col-12">
                        <Toast ref={toast} />
                        <Toolbar className="mb-2 " left={leftToolbarTemplate}></Toolbar>
                    </div>

                    <ConsultaFixaGenerica
                        lista={lista}
                        model={model}
                        selecao={selecao}
                        camposFiltroGlobal={['jornada.nome', 'valor.valor', 'tempo']}
                        colunas={colunas}
                    />

                </div >
            </div >
        </div >
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ConfiguracaoHorasExtrasIntrajornada, comparisonFn);
